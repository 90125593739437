import { FC } from "react"
import { IosBuild } from "../../../../domain/entities/iosBuild"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { IosBuildListView } from "./IosBuildListView"

interface Props {
	list: IosBuild[]
	isLoading: boolean
	onUpdate: (iosBuild: IosBuild) => void
	onDelete: (iosBuild: IosBuild) => void
	onAddClick: () => void
}

export const IosBuildListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection message="No IOS Builds found." onAddClick={onAddClick} />
		)
	}

	return <IosBuildListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
