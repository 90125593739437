import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { organizationVerifyApi } from "src/domain/api/organization/organizationVerifyApi"
import { District } from "src/domain/entities/district"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { Organization } from "../../../domain/entities/organization"
import {
	useAuth,
	useDistrictListApi,
	useOrganizationListApi,
} from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { OrganizationAddDrawerFormController } from "./OrganizationAddDrawerForm"

import { OrganizationDeleteDialogController } from "./OrganizationDeleteDialogController"
import { OrganizationListController } from "./OrganizationList"
import { OrganizationCommitteeMembersDrawer } from "./OrganizationList/OrganizationCommitteeMembersDrawer"
import { OrganizationUpdateDrawerFormController } from "./OrganizationUpdateDrawerForm"

export const OrganizationsPage: FC = () => {
	const { organizationList, isLoading, fetchOrganizationList } =
		useOrganizationListApi()
	const [isOrganizationDeleteDialogOpen, setIsOrganizationDeleteDialogOpen] =
		useState(false)
	const organizationAddDrawerDisclosure = useDisclosure()
	const organizationUpdateDrawerDisclosure = useDisclosure()
	const organizationCommitteeMembersDrawerDisclosure = useDisclosure()

	const { districtList, fetchDistrictList } = useDistrictListApi()

	const { token } = useAuth()
	const [selectedState, setSelectedState] = useState<District>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchOrganizations = useCallback(async () => {
		return await fetchOrganizationList({
			fetch: {
				village: { taluka: true, district: true, state: true },
				facilities: true,
				committeeMembers: true,
				subCaste: true,
			},
			search: searchText,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchOrganizationList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchOrganizations()
	}, [fetchOrganizations])

	useEffect(() => {
		fetchDistrictList()
	}, [fetchDistrictList])

	const handleOrganizationVerificationChange = useCallback(
		async (val: boolean, organization: Organization) => {
			if (!token) return
			await organizationVerifyApi(
				{
					organizationId: organization.id,
					isVerified: val,
				},
				token,
			)

			await fetchOrganizations()
		},
		[token, fetchOrganizations],
	)

	useEffect(() => {
		fetchOrganizations()
	}, [fetchOrganizations])

	const updateOrganizationRef = useRef<Organization>()
	const selectedOrganizationRef = useRef<Organization>()
	const deleteOrganizationRef = useRef<Organization>()

	const districtOptions: {
		label: string
		value?: District
	}[] = districtList.map((district) => ({
		label: district.name.en,
		value: district,
	}))

	districtOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Organizations
					</Text>
					<Button
						size="sm"
						onClick={organizationAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Organization
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* District */}
					<Box width={{ base: "full", lg: "20%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="District" />
						<ReactSelect
							name="districtId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedState(val?.value)
							}}
							value={districtOptions.find(
								(el) => el.value?.id === selectedState?.id,
							)}
							options={districtOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<OrganizationListController
				list={organizationList}
				isLoading={isLoading}
				onUpdate={(organization: Organization) => {
					updateOrganizationRef.current = organization
					organizationUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(organization: Organization) => {
					deleteOrganizationRef.current = organization
					setIsOrganizationDeleteDialogOpen(true)
				}}
				onMemberButtonClick={(organization: Organization) => {
					selectedOrganizationRef.current = organization
					organizationCommitteeMembersDrawerDisclosure.onOpen()
				}}
				onAddClick={organizationAddDrawerDisclosure.onOpen}
				handleOrganizationVerificationChange={
					handleOrganizationVerificationChange
				}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={organizationList.length === 0}
			/>
			{organizationAddDrawerDisclosure.isOpen ? (
				<OrganizationAddDrawerFormController
					{...organizationAddDrawerDisclosure}
					onSuccess={() => fetchOrganizations()}
				/>
			) : null}
			{updateOrganizationRef.current &&
			organizationUpdateDrawerDisclosure.isOpen ? (
				<OrganizationUpdateDrawerFormController
					{...organizationUpdateDrawerDisclosure}
					organization={updateOrganizationRef.current}
					onSuccess={() => fetchOrganizations()}
				/>
			) : null}
			{selectedOrganizationRef.current &&
			organizationCommitteeMembersDrawerDisclosure.isOpen ? (
				<OrganizationCommitteeMembersDrawer
					{...organizationCommitteeMembersDrawerDisclosure}
					organization={selectedOrganizationRef.current}
				/>
			) : null}
			{deleteOrganizationRef.current && isOrganizationDeleteDialogOpen ? (
				<OrganizationDeleteDialogController
					isOpen={isOrganizationDeleteDialogOpen}
					setIsOpen={setIsOrganizationDeleteDialogOpen}
					organization={deleteOrganizationRef.current}
					onSuccess={() => fetchOrganizations()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
