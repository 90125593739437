import { Box, Flex } from "@chakra-ui/layout"
import { FC, useCallback, useState } from "react"
import { BsList } from "react-icons/bs"
import { AccordionMenuItem, MenuItem, NavItem, SideBarTitle } from "./SideBar"

export const Header: FC<{ items: NavItem[] }> = ({ items }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleMenuHamburgerClick = useCallback(() => {
		setIsMenuOpen(!isMenuOpen)
	}, [isMenuOpen])

	return (
		<Box
			display={{ base: "block", lg: "none" }}
			p={"2"}
			backgroundColor={"gray.800"}
			maxHeight={"80vh"}
			overflow={"auto"}
			position={"fixed"}
			w={"full"}
			zIndex={1}
		>
			<Flex justify={"space-between"} align={"center"}>
				<SideBarTitle items={items} />
				<Box
					border={"1px"}
					borderColor={"white"}
					h={"fit-content"}
					borderRadius={"lg"}
					p={"2"}
					onClick={handleMenuHamburgerClick}
				>
					<BsList size={"24"} color="white" />
				</Box>
			</Flex>
			{isMenuOpen ? (
				<Box>
					{items.map((item, i) =>
						item.subItems?.length ? (
							<AccordionMenuItem item={item} key={i} />
						) : (
							<MenuItem item={item} key={i} />
						),
					)}
				</Box>
			) : null}
		</Box>
	)
}
