import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { SubCaste } from "src/domain/entities/subCaste"
import { ISurnameAddFormFields } from "."
import { fetchGujaratiSuggestions } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<ISurnameAddFormFields>
	subCasteList: SubCaste[]
	initialValues?: ISurnameAddFormFields
}

export const SurnameAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	subCasteList,
	initialValues,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<ISurnameAddFormFields>
			initialValues={
				initialValues ?? {
					name: {
						en: "",
						gu: "",
					},
					code: "",
					subCasteId: "",
				}
			}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const subCasteOptions = subCasteList.map((subCaste) => ({
					value: subCaste.id,
					label: subCaste.name.en + ` (${subCaste.name.gu})`,
				}))

				function getSurnameCode() {
					return (
						<FormControl flex={1}>
							<InputLabel label="Code" />
							<Input
								name="code"
								placeholder="A"
								maxLength={3}
								value={values.code}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="code" />
						</FormControl>
					)
				}

				function getSubcast() {
					return (
						<FormControl flex={2}>
							<InputLabel label="SubCaste" />
							<ReactSelect
								name="subCasteId"
								onChange={(newValue) => {
									setFieldValue(
										"subCasteId",
										(newValue as SelectOption).value,
									)
								}}
								value={subCasteOptions.find(
									(el) => el.value === values.subCasteId,
								)}
								options={subCasteOptions}
								isSearchable
							/>
						</FormControl>
					)
				}

				function getName() {
					return (
						<>
							<FormControl>
								<InputLabel label="Name" />
								<Input
									name="name.en"
									placeholder="Name"
									maxLength={50}
									required
									autoFocus
									value={values.name.en}
									onChange={handleNameChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="name.en"
								/>
							</FormControl>
							<Box>
								{nameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("name.gu", el)
											setNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
						</>
					)
				}

				function getNameGu() {
					return (
						<FormControl flex={5}>
							<InputLabel label="Name Gujarati" />
							<Input
								name="name.gu"
								placeholder="Name Gujarati"
								maxLength={50}
								required
								value={values.name.gu}
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="name.gu" />
						</FormControl>
					)
				}

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Surname"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								{/* Code */}
								{getSurnameCode()}

								{/* SubCaste */}
								{getSubcast()}
							</Flex>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									{getName()}
								</Box>
								{/* Name Gu */}
								{getNameGu()}
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
