import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { businessAddApi } from "src/domain/api/business/businessAddApi"
import { File } from "src/domain/entities/file"
import { Business } from "../../../../domain/entities/business"
import {
	useAuth,
	useOccupationCategoryListApi,
	useVillageListApi,
} from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { BusinessAddDrawerFormView } from "./BusinessAddDrawerFormView"
import { IBusinessAddFormFields } from "./IBusinessAddFormFields"

interface Props
	extends Omit<
		ComponentProps<typeof BusinessAddDrawerFormView>,
		| "handleSubmit"
		| "villageList"
		| "setVillageSearchText"
		| "productCategoryList"
		| "businessCategoryList"
		| "handleUploadImage"
		| "selectedImage"
		| "setSelectedImage"
	> {
	onSuccess: (business: Business) => void
}

export const BusinessAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IBusinessAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const business = await businessAddApi(values, token)
				onSuccess(business)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)

			return response as File
		},
		[token],
	)

	const { fetchVillageList, villageList } = useVillageListApi()
	const [villageSearchText, setVillageSearchText] = useState("")
	useEffect(() => {
		fetchVillageList({
			search: villageSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {
				talukaDistrict: true,
			},
		})
	}, [fetchVillageList, villageSearchText])

	const { fetchOccupationCategoryList, occupationCategoryList } =
		useOccupationCategoryListApi()

	useEffect(() => {
		fetchOccupationCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
			},
		})
	}, [fetchOccupationCategoryList])

	return (
		<BusinessAddDrawerFormView
			handleSubmit={handleSubmit}
			villageList={villageList}
			setVillageSearchText={setVillageSearchText}
			businessCategoryList={occupationCategoryList}
			selectedImage={selectedImage}
			setSelectedImage={setSelectedImage}
			handleUploadImage={handleUploadImage}
			{...rest}
		/>
	)
}
