import { useCallback, useState } from "react"
import { surnameListApi } from "../api/surname/surnameListApi"
import { Surname } from "../entities/surname"
import { useAuth } from "./useAuth"

export function useSurnameListApi(): {
	surnameList: Surname[]
	isLoading: boolean
	error: null | Error
	fetchSurnameList: (
		filter?: Parameters<typeof surnameListApi>[0],
	) => Promise<Surname[]>
} {
	const { token } = useAuth()
	const [surnameList, setSurnameList] = useState<Surname[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchSurnameList = useCallback(
		async (filter: Parameters<typeof surnameListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await surnameListApi(filter, token)
				setSurnameList(data)
				return data
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
			return []
		},
		[token],
	)

	return { surnameList, isLoading, error, fetchSurnameList }
}
