import { MultiLangText } from "src/utils/types"
import { Village } from "../../entities/village"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	talukaId: string
	name: MultiLangText
	sameAsParent?: boolean
}

export async function villageAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/village/add/v1"
	const requester = new ApiRequester<RequestDataShape, Village>(endpoint)
	return await requester.sendRequest(payload, token)
}
