import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import { createSearchParams, useSearchParams } from "react-router-dom"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { Registration } from "src/domain/entities/registration"
import { SubCaste } from "src/domain/entities/subCaste"
import { User } from "src/domain/entities/user"
import { Gender, ReviewStatus } from "src/utils/enums"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import {
	useRegistrationListApi,
	useSubCasteListApi,
	useUserListApi,
} from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { RegistrationDeleteDialogController } from "./RegistrationDeleteDialogController"
import { AssignReviewerModalController } from "./RegistrationDetails/AssignReviewerModalController"
import { RegistrationListController } from "./RegistrationList"

export const RegistrationsPage: FC = () => {
	const [isRegistrationDeleteDialogOpen, setIsRegistrationDeleteDialogOpen] =
		useState(false)
	const { registrationList, isLoading, fetchRegistrationList } =
		useRegistrationListApi()
	const deleteRegistrationeRef = useRef<Registration>()
	const [params, setParams] = useSearchParams()

	const { subCasteList, fetchSubCasteList } = useSubCasteListApi()
	const { userList: reviewerList, fetchUserList: fetchReviewerList } = useUserListApi()

	const [selectedSubCaste, setSelectedSubCaste] = useState<SubCaste>()
	const [selectedGender, setSelectedGender] = useState<Gender>()
	const [selectedReviewer, setSelectedReviewer] = useState<User | null>()

	const queryReviewStatus = params.get("reviewStatus")
	const [selectedReviewStatus, setSelectedReviewStatus] = useState<
		ReviewStatus | undefined
	>(queryReviewStatus ? (queryReviewStatus as ReviewStatus) : undefined)

	const queryPage = params.get("page")
	const queryLimit = params.get("limit")
	const pagination = usePagination({
		initialState: { currentPage: +(queryPage ?? 1), pageSize: +(queryLimit ?? 10) },
	})

	const querySearch = params.get("search")
	const [searchText, setSearchText] = useState(querySearch ?? "")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const [selectedRegistrations, setSelectedRegistrations] = useState<Registration[]>([])
	const handleSelectRegistration = (registration: Registration) => {
		if (selectedRegistrations.find((e) => e.id === registration.id)) {
			setSelectedRegistrations(
				selectedRegistrations.filter((e) => e.id !== registration.id),
			)
		} else {
			setSelectedRegistrations([...selectedRegistrations, registration])
		}
	}

	const handleSelectAllRegistrations = () => {
		setSelectedRegistrations([])
		if (registrationList.length === selectedRegistrations.length) {
			return
		}

		setSelectedRegistrations([...registrationList])
	}

	const [isAssignReviewerModalOpen, setIsAssignReviewerModalOpen] = useState(false)

	const handleAssignReviewerButtonClick = () => {
		setIsAssignReviewerModalOpen(true)
	}

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchRegistrations = useCallback(async () => {
		var updatedParams = createSearchParams({
			page: pagination.currentPage.toString(),
			limit: pagination.pageSize.toString(),
		})
		if (searchText) updatedParams.set("search", searchText)
		if (selectedReviewStatus) updatedParams.set("reviewStatus", selectedReviewStatus)
		setParams(updatedParams)

		return await fetchRegistrationList({
			fetch: {
				surname: true,
				reviewer: true,
				user: true,
				reviewerDetails: true,
				reviews: true,
				nativeVillage: true,
			},
			reviewStatus: selectedReviewStatus,
			search: searchText,
			gender: selectedGender,
			subCasteId: selectedSubCaste?.id,
			reviewerId: selectedReviewer ? selectedReviewer?.id : selectedReviewer,
			pagination: {
				page: pagination.currentPage,
				limit: pagination.pageSize,
			},
		})
	}, [
		fetchRegistrationList,
		pagination.currentPage,
		pagination.pageSize,
		selectedSubCaste?.id,
		selectedGender,
		selectedReviewer,
		searchText,
		selectedReviewStatus,
		setParams,
	])

	useEffect(() => {
		fetchRegistrations()
	}, [fetchRegistrations])

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	useEffect(() => {
		fetchReviewerList({
			isReviewer: true,
			fetch: { profile: true, registration: true, registrationDetails: true },
		})
	}, [fetchReviewerList])

	const subCasteOptions: {
		label: string
		value?: SubCaste
	}[] = subCasteList.map((subCaste) => ({
		label: subCaste.name.en,
		value: subCaste,
	}))

	subCasteOptions.unshift({
		label: "All",
		value: undefined,
	})

	const reviewerOptions: {
		label: string
		value?: User | null
	}[] = reviewerList
		.filter((u) => u.registration || u.profile)
		.map((user) => ({
			label:
				(user.profile?.firstName.en ??
					user.registration?.basic?.firstName.en ??
					"") +
					" " +
					(user.profile?.surname?.name.en ??
						user.registration?.basic?.surname?.name.en) ?? "-",
			value: user,
		}))

	reviewerOptions.unshift({
		label: "No Reviewer",
		value: null,
	})
	reviewerOptions.unshift({
		label: "All",
		value: undefined,
	})

	const reviewStatusOptions: {
		label: string
		value?: ReviewStatus
	}[] = Object.values(ReviewStatus).map((status) => ({
		label: status.toUpperCase(),
		value: status,
	}))

	reviewStatusOptions.unshift({
		label: "All",
		value: undefined,
	})

	const genderOptions: {
		label: string
		value?: Gender
	}[] = Object.values(Gender).map((gender) => ({
		label: gender.toUpperCase(),
		value: gender,
	}))

	genderOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2} display={{ base: "none", lg: "block" }}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Registrations
					</Text>
				</Flex>
			</Box>
			<Box px={2} mb={4} mt={{ base: "2", lg: "0" }}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "100%", lg: "fit-content" }} maxWidth={"15%"}>
						<InputGroup width={{ base: "full", lg: "fit-content" }}>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Sub Caste */}
					<Box width={{ base: "100%", lg: "10%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Sub Caste" />
						<ReactSelect
							name="subCasteId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedSubCaste(val?.value)
							}}
							value={subCasteOptions.find(
								(el) => el.value?.id === selectedSubCaste?.id,
							)}
							options={subCasteOptions}
						/>
					</Box>
					{/* Gender */}
					<Box width={{ base: "100%", lg: "10%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Gender" />
						<ReactSelect
							name="gender"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedGender(val?.value)
							}}
							value={genderOptions.find(
								(el) => el.value === selectedGender,
							)}
							options={genderOptions}
						/>
					</Box>
					{/* Reviewer */}
					<Box width={{ base: "100%", lg: "15%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Reviewer" />
						<ReactSelect
							name="reviewerId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedReviewer(val?.value)
							}}
							value={reviewerOptions.find((el) =>
								el.value
									? el.value?.id === selectedReviewer?.id
									: el.value === selectedReviewer,
							)}
							options={reviewerOptions}
						/>
					</Box>
					{/* Status */}
					<Box width={{ base: "100%", lg: "12%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Status" />
						<ReactSelect
							name="reviewStatus"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedReviewStatus(val?.value)
							}}
							value={reviewStatusOptions.find(
								(el) => el.value === selectedReviewStatus,
							)}
							options={reviewStatusOptions}
						/>
					</Box>
					{/* Assign reviewer */}
					<Button
						mt={{ base: "2", lg: "0" }}
						width={{ base: "fit-content", lg: "fit-content" }}
						colorScheme="blue"
						onClick={handleAssignReviewerButtonClick}
						isDisabled={selectedRegistrations.length === 0}
						mx={{ base: "auto", lg: "0" }}
					>
						Assign Reviewer
					</Button>
				</Flex>
			</Box>
			<RegistrationListController
				list={registrationList}
				selectedRegistrations={selectedRegistrations}
				handleSelectRegistration={handleSelectRegistration}
				handleSelectAllRegistrations={handleSelectAllRegistrations}
				isLoading={isLoading}
				onDelete={(registration) => {
					deleteRegistrationeRef.current = registration
					setIsRegistrationDeleteDialogOpen(true)
				}}
			/>

			<CustomPagination
				pagination={pagination}
				isNextDisabled={registrationList.length === 0}
			/>
			{isAssignReviewerModalOpen ? (
				<AssignReviewerModalController
					isOpen={isAssignReviewerModalOpen}
					setIsOpen={setIsAssignReviewerModalOpen}
					onSuccess={fetchRegistrations}
					registrations={selectedRegistrations}
				/>
			) : null}
			{deleteRegistrationeRef.current && isRegistrationDeleteDialogOpen ? (
				<RegistrationDeleteDialogController
					isOpen={isRegistrationDeleteDialogOpen}
					setIsOpen={setIsRegistrationDeleteDialogOpen}
					registration={deleteRegistrationeRef.current}
					onSuccess={() => fetchRegistrations()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
