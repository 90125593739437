import {
	Box,
	Button,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
} from "@chakra-ui/react"
import moment from "moment"
import { FC, useCallback, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { BsPerson, BsX } from "react-icons/bs"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"
import { profileFamilyGetApi } from "src/domain/api/profileFamily/profileFamilyGetApi"
import { Profile } from "src/domain/entities/profile"
import { ProfileFamily } from "src/domain/entities/profileFamily"
import { useAuth, useMatrimonyProfileListApi, useUserListApi } from "src/domain/hooks"
import {
	Gender,
	getBloodGroupType,
	getMatrimonyProfilePropertyType,
} from "src/utils/enums"
import { getFullName, getVillageName } from "src/utils/helpers"

interface Props {
	matrimonyProfileId: string
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	handleProfileClick: (profileId: string) => void
}

export const MatrimonyProfileDetailsModal: FC<Props> = ({
	isOpen,
	setIsOpen,
	matrimonyProfileId,
	handleProfileClick,
}) => {
	const { fetchMatrimonyProfileList, isLoading, matrimonyProfileList } =
		useMatrimonyProfileListApi()
	const { token } = useAuth()

	const [profileFamilyLoading, setProfileFamilyLoading] = useState(false)
	const [profileFamily, setProfileFamily] = useState<ProfileFamily | null>(null)
	const [maternalFamilyLoading, setMaternalFamilyLoading] = useState(false)
	const [maternalFamily, setMaternalFamily] = useState<ProfileFamily | null>(null)

	const [selectedViewImageUrl, setSelectedViewImageUrl] = useState<string | null>(null)

	const fetchMatrimonyProfileDetails = useCallback(async () => {
		try {
			await fetchMatrimonyProfileList({
				id: matrimonyProfileId,
				fetch: {
					profile: {
						surname: {
							subCaste: true,
						},
						nativeVillage: {
							district: true,
							taluka: true,
						},
						currentVillage: {
							country: true,
							state: true,
							district: true,
							taluka: true,
						},
						mobile: true,
					},
					maintainers: true,
					featuredImage: true,
					property: true,
					allImages: true,
					identityProofImage: true,
				},
			})
		} catch (err) {
			console.error(err)
		}
	}, [fetchMatrimonyProfileList, matrimonyProfileId])

	const [matrimonyProfileDetails] = matrimonyProfileList

	const { userList, fetchUserList } = useUserListApi()

	useEffect(() => {
		if (!matrimonyProfileDetails?.maintainers?.[0]?.id) return

		fetchUserList({
			id: matrimonyProfileDetails?.maintainers?.[0]?.id,
			fetch: {
				profile: true,
				profileDetails: true,
			},
		})
	}, [matrimonyProfileDetails?.maintainers, fetchUserList])

	const [maintainerUser] = userList

	const fetchProfileFamilyDetails = useCallback(async () => {
		try {
			setProfileFamilyLoading(true)
			if (!matrimonyProfileDetails?.profile) {
				return
			}
			var response = await profileFamilyGetApi(
				{
					profileId: matrimonyProfileDetails.profile.id,
					father: true,
					mother: true,
					spouse: true,
					children: true,
					siblings: true,
					fetch: {
						surname: {},
					},
				},
				token,
			)

			setProfileFamily(response)
		} catch (err) {
			console.error(err)
		} finally {
			setProfileFamilyLoading(false)
		}
	}, [matrimonyProfileDetails, token])

	const fetchMaternalFamilyDetails = useCallback(async () => {
		try {
			setMaternalFamilyLoading(true)
			if (!profileFamily?.mother) {
				return
			}
			var response = await profileFamilyGetApi(
				{
					profileId: profileFamily?.mother?.id,
					father: true,
					mother: true,
					spouse: true,
					children: true,
					siblings: true,
					fetch: {
						surname: {},
					},
				},
				token,
			)

			setMaternalFamily(response)
		} catch (err) {
			console.error(err)
		} finally {
			setMaternalFamilyLoading(false)
		}
	}, [profileFamily?.mother, token])

	useEffect(() => {
		fetchMatrimonyProfileDetails()
	}, [fetchMatrimonyProfileDetails])

	useEffect(() => {
		fetchProfileFamilyDetails()
	}, [fetchProfileFamilyDetails])

	useEffect(() => {
		fetchMaternalFamilyDetails()
	}, [fetchMaternalFamilyDetails])

	let maternalFamilyMemberCount = 0
	if (maternalFamily) {
		maternalFamilyMemberCount += maternalFamily.children?.length ?? 0
		maternalFamilyMemberCount += maternalFamily.siblings?.length ?? 0
		maternalFamilyMemberCount += maternalFamily.father ? 1 : 0
		maternalFamilyMemberCount += maternalFamily.mother ? 1 : 0
		maternalFamilyMemberCount += maternalFamily.spouse ? 1 : 0
	}

	let profileFamilyMemberCount = 0
	if (profileFamily) {
		profileFamilyMemberCount += profileFamily.children?.length ?? 0
		profileFamilyMemberCount += profileFamily.siblings?.length ?? 0
		profileFamilyMemberCount += profileFamily.father ? 1 : 0
		profileFamilyMemberCount += profileFamily.mother ? 1 : 0
		profileFamilyMemberCount += profileFamily.spouse ? 1 : 0
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<ModalOverlay />
				<ModalContent rounded={"2xl"} overflow="hidden">
					<ModalBody p="0" bgColor={"#FCEFEF"}>
						<Box backgroundColor={"#FCEFEF"} p={{ base: "4", lg: "8" }}>
							<Flex>
								<Flex
									borderRadius={"full"}
									overflow="hidden"
									w="100px"
									h="100px"
									flex={"none"}
									bgColor="gray.100"
									align={"center"}
									justify={"center"}
									border={"5px"}
									borderStyle={"solid"}
									borderColor={"#FDA4AF"}
								>
									{isLoading ? (
										<AiOutlineLoading3Quarters />
									) : matrimonyProfileDetails?.featuredImageUrl ? (
										<Image
											src={
												matrimonyProfileDetails?.featuredImageUrl
											}
											alt="Profile Picture"
											objectFit="cover"
											onClick={() =>
												setSelectedViewImageUrl(
													matrimonyProfileDetails.featuredImageUrl!,
												)
											}
										/>
									) : (
										<BsPerson size={20} />
									)}
								</Flex>
								{isLoading ? null : (
									<Box ml="6" flex={1}>
										<Box
											color={"#9F1239"}
											fontSize="2xl"
											lineHeight={"1.3"}
											fontWeight="bold"
										>
											{
												matrimonyProfileDetails?.profile
													?.firstName.en
											}{" "}
											{
												matrimonyProfileDetails?.profile
													?.middleName?.en
											}{" "}
											{
												matrimonyProfileDetails?.profile?.surname
													?.name.en
											}
										</Box>
										<Flex align={"center"} columnGap="2" mt="2">
											<Box
												color={"#FB7185"}
												fontSize="sm"
												textTransform={"capitalize"}
											>
												{Math.floor(
													(new Date().getTime() -
														new Date(
															matrimonyProfileDetails
																?.profile?.dateOfBirth ??
																0,
														).getTime()) /
														(1000 * 3600 * 24 * 365),
												)}{" "}
												Years ,
											</Box>
											<Box
												color={"#FB7185"}
												fontSize="sm"
												textTransform={"capitalize"}
											>
												{matrimonyProfileDetails?.profile?.gender}{" "}
												,
											</Box>
											<Box flex={1}>
												<Box
													color={"#FB7185"}
													textTransform={"capitalize"}
													fontSize="sm"
												>
													{
														matrimonyProfileDetails?.profile
															?.maritalStatus
													}{" "}
												</Box>
											</Box>
										</Flex>
									</Box>
								)}
								<Box display={{ base: "block", lg: "none" }}>
									<Button
										bgColor={"transparent"}
										_hover={{
											bgColor: "red.100",
										}}
										color={"red.300"}
										padding={2}
										onClick={() => setIsOpen(false)}
									>
										<BsX size={30} />
									</Button>
								</Box>
							</Flex>
						</Box>
						<Box
							display={"flex"}
							flexWrap="wrap"
							alignItems={"center"}
							justifyContent={"center"}
						>
							{matrimonyProfileDetails?.imageUrls?.map((e, i) => (
								<Box
									key={i}
									width={"120px"}
									height={"190px"}
									backgroundImage={`url(${e})`}
									backgroundSize={"cover"}
									borderRadius={"lg"}
									m={"5px"}
									onClick={() => setSelectedViewImageUrl(e)}
								></Box>
							))}
						</Box>

						<Flex
							m="4"
							gap={{ base: "2", lg: "6" }}
							direction={"column"}
							bgColor={"#FCEFEF"}
						>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									ABOUT
								</Box>
								<Box
									color={"#475569"}
									textTransform={"capitalize"}
									fontSize={"sm"}
								>
									{matrimonyProfileDetails?.aboutMe}
								</Box>
							</Box>
							<Flex direction={"column"}>
								<Box color={"#FB7185"} fontWeight="semibold">
									BASIC
								</Box>
								<Flex gap="2" direction={"column"}>
									<Flex>
										<Box flex={1}>
											<Box color={"#475569"} fontSize="sm">
												Date of Birth
											</Box>
											<Box display={"flex"}>
												<Box
													color={"#1E293B"}
													textTransform={"capitalize"}
													fontWeight={"semibold"}
												>
													{moment(
														matrimonyProfileDetails?.profile
															?.dateOfBirth,
													).format("DD MMM YYYY")}
												</Box>
												<Box
													ml={"1.5"}
													mt={"0.6"}
													fontSize={"sm"}
													color={"#1E293B"}
													fontWeight={"semibold"}
												>
													(
													{Math.floor(
														(new Date().getTime() -
															new Date(
																matrimonyProfileDetails
																	?.profile
																	?.dateOfBirth ?? 0,
															).getTime()) /
															(1000 * 3600 * 24 * 365),
													)}{" "}
													yrs)
												</Box>
											</Box>
										</Box>
										<Box flex={1}>
											<Box color={"#475569"} fontSize="sm">
												Weight
											</Box>
											<Box
												color={"#1E293B"}
												textTransform={"capitalize"}
												fontWeight={"semibold"}
											>
												{matrimonyProfileDetails?.weight} KGs
											</Box>
										</Box>
									</Flex>
									<Flex>
										<Box flex={1}>
											<Box color={"#475569"} fontSize="sm">
												Blood group
											</Box>
											<Box
												color={"#1E293B"}
												textTransform={"capitalize"}
												fontWeight={"semibold"}
											>
												{getBloodGroupType(
													matrimonyProfileDetails?.bloodGroup,
												)}
											</Box>
										</Box>
										<Box flex={1}>
											<Box color={"#475569"} fontSize="sm">
												Height
											</Box>
											<Box
												color={"#1E293B"}
												textTransform={"capitalize"}
												fontWeight={"semibold"}
											>
												{parseHeight(
													matrimonyProfileDetails?.height,
												)}
											</Box>
										</Box>
									</Flex>
									<Box flex={1}>
										<Box color={"#475569"} fontSize="sm">
											Marital Status
										</Box>
										<Box
											color={"#1E293B"}
											textTransform={"capitalize"}
											fontWeight={"semibold"}
										>
											{
												matrimonyProfileDetails?.profile
													?.maritalStatus
											}
										</Box>
									</Box>
								</Flex>
							</Flex>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									HOBBIES
								</Box>
								<Box display={"flex"}>
									{matrimonyProfileDetails?.hobbies.map((e, i) => (
										<Box
											color={"#FB7185"}
											textTransform={"capitalize"}
											fontSize={"sm"}
											mr={"10px"}
											bgColor={"#FFE4E6"}
											border={"2px"}
											borderStyle={"solid"}
											borderColor={"#FECDD3"}
											p={"3px 8px"}
											borderRadius={"5px"}
											key={i}
										>
											{e}
										</Box>
									))}
								</Box>
							</Box>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									FAMILY
								</Box>

								{profileFamilyLoading ? (
									<AiOutlineLoading3Quarters />
								) : !profileFamily || !profileFamilyMemberCount ? (
									<Text fontSize={"sm"}>No Family Member Added.</Text>
								) : (
									<Flex direction={"column"} fontWeight={"semibold"}>
										<ProfileFamilyListItem
											profile={profileFamily.mother}
											relation="Mother"
											handleProfileClick={handleProfileClick}
										/>
										<ProfileFamilyListItem
											profile={profileFamily.father}
											relation="Father"
											handleProfileClick={handleProfileClick}
										/>
										<ProfileFamilyListItem
											profile={profileFamily.spouse?.profile}
											relation="Spouse"
											handleProfileClick={handleProfileClick}
										/>
										{profileFamily.children
											?.sort(
												(a, b) =>
													a.gender.length - b.gender.length,
											)
											.map((child, i) => (
												<ProfileFamilyListItem
													profile={child}
													relation="Child"
													handleProfileClick={
														handleProfileClick
													}
													key={i}
												/>
											))}
										{profileFamily.siblings
											?.sort(
												(a, b) =>
													a.gender.length - b.gender.length,
											)
											.map((sibling, i) => (
												<ProfileFamilyListItem
													profile={sibling}
													relation="Sibling"
													handleProfileClick={
														handleProfileClick
													}
													key={i}
												/>
											))}
									</Flex>
								)}
							</Box>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									MATERNAL FAMILY
								</Box>

								{maternalFamilyLoading ? (
									<AiOutlineLoading3Quarters />
								) : !maternalFamily || !maternalFamilyMemberCount ? (
									<Text fontSize={"sm"}>No Maternal Family Added.</Text>
								) : (
									<Flex direction={"column"} fontWeight={"semibold"}>
										{maternalFamily.siblings
											?.filter((e) => e.gender === Gender.MALE)
											.map((sibling, i) => (
												<ProfileFamilyListItem
													profile={sibling}
													relation="Maternal Uncle"
													handleProfileClick={
														handleProfileClick
													}
													key={i}
												/>
											))}
									</Flex>
								)}
							</Box>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									LOCATION
								</Box>
								<Box
									bgColor={"#FFE4E6"}
									border={"2px"}
									borderStyle={"solid"}
									borderColor={"#FECDD3"}
									p={"3px 8px"}
									borderRadius={"5px"}
									mt={"2"}
								>
									<Box
										color={"gray.700"}
										fontSize="sm"
										fontWeight={"semibold"}
									>
										Native village
									</Box>
									<Box color={"gray.900"} textTransform={"capitalize"}>
										{getVillageName(
											matrimonyProfileDetails?.profile
												?.nativeVillage,
										)}
									</Box>
								</Box>
								<Box
									bgColor={"#FFE4E6"}
									border={"2px"}
									borderStyle={"solid"}
									borderColor={"#FECDD3"}
									p={"3px 8px"}
									borderRadius={"5px"}
									mt={"2"}
								>
									<Box
										color={"gray.700"}
										fontSize="sm"
										fontWeight={"semibold"}
									>
										Current city/village
									</Box>
									<Box color={"gray.900"} textTransform={"capitalize"}>
										{getVillageName(
											matrimonyProfileDetails?.profile
												?.currentVillage,
										)}
									</Box>
								</Box>
							</Box>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									PROPERTIES
								</Box>
								<Box>
									{matrimonyProfileDetails?.properties?.map((e, i) => (
										<Box
											key={i}
											color={"#FB7185"}
											textTransform={"capitalize"}
											fontSize={"sm"}
											mr={"10px"}
											bgColor={"#FFE4E6"}
											border={"2px"}
											borderStyle={"solid"}
											borderColor={"#FECDD3"}
											p={"3px 8px"}
											borderRadius={"5px"}
											mt={"2px"}
											mb={"7px"}
										>
											<Box display={"flex"}>
												<Box
													color={"#1E293B"}
													fontWeight="semibold"
												>
													{`${
														i + 1
													}. ${getMatrimonyProfilePropertyType(
														e.type,
													)}`}
												</Box>
											</Box>
											<Box color={"#475569"}>{e.description}</Box>
										</Box>
									))}
								</Box>
							</Box>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									RESIDENTIAL ADDRESS
								</Box>
								<Box
									color={"#FB7185"}
									textTransform={"capitalize"}
									fontSize={"sm"}
									mr={"10px"}
									bgColor={"#FFE4E6"}
									border={"2px"}
									borderStyle={"solid"}
									borderColor={"#FECDD3"}
									p={"3px 8px"}
									borderRadius={"5px"}
									mt={"2px"}
									mb={"7px"}
								>
									{matrimonyProfileDetails?.residenceAddress ?? ""}
								</Box>
							</Box>
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									CONTACT DETAILS
								</Box>

								<Flex
									color={"#FB7185"}
									textTransform={"capitalize"}
									fontSize={"sm"}
									mr={"10px"}
									bgColor={"#FFE4E6"}
									border={"2px"}
									borderStyle={"solid"}
									borderColor={"#FECDD3"}
									p={"3px 8px"}
									borderRadius={"5px"}
									mt={"2px"}
									mb={"7px"}
									direction={"column"}
								>
									<Flex>
										<Box mr={3}>User :</Box>
										<Box>
											{matrimonyProfileDetails?.contactDetails ??
												""}
										</Box>
									</Flex>
									{maintainerUser?.profile?.mobile?.mobileNo ? (
										<Flex>
											<Box>Creator No:</Box>
											<Box>
												{maintainerUser.profile?.mobile?.mobileNo}
											</Box>
										</Flex>
									) : null}
								</Flex>
							</Box>

							{matrimonyProfileDetails?.identityProofImageUrl ? (
								<Box>
									<Box
										color={"#FB7185"}
										fontSize="md"
										fontWeight={"semibold"}
										mb={"2"}
									>
										IDENTITY PROOF
									</Box>
									<Image
										src={
											matrimonyProfileDetails?.identityProofImageUrl
										}
										alt="Profile Picture"
										objectFit="cover"
										w={"300px"}
										h={"150px"}
										onClick={() =>
											setSelectedViewImageUrl(
												matrimonyProfileDetails.identityProofImageUrl!,
											)
										}
									/>
								</Box>
							) : null}
							<Box>
								<Box
									color={"#FB7185"}
									fontSize="md"
									fontWeight={"semibold"}
								>
									MAINTAINERS (
									{matrimonyProfileDetails?.maintainers?.length})
								</Box>
								{maintainerUser?.profile ? (
									<ProfileFamilyListItem
										relation="Maintainer"
										profile={maintainerUser?.profile}
										handleProfileClick={() =>
											handleProfileClick(maintainerUser.profile!.id)
										}
									/>
								) : null}
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
			{selectedViewImageUrl != null ? (
				<ImageViewModalController
					isOpen={selectedViewImageUrl != null}
					setIsOpen={() => setSelectedViewImageUrl(null)}
					image={
						<Image
							src={selectedViewImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
		</>
	)
}

function parseHeight(height: number): string {
	const feet = Math.floor(height / 30)
	const inch = Math.round((height % 30) / 2.5)
	const centimeter = height
	return `${feet}' ${inch}'' / ${centimeter}cm`
}

const ProfileFamilyListItem: React.FC<{
	profile?: Profile
	relation:
		| "Mother"
		| "Father"
		| "Sibling"
		| "Spouse"
		| "Child"
		| `Maternal Uncle`
		| "Maintainer"
	handleProfileClick?: (profileId: string) => void
}> = ({ profile, relation, handleProfileClick }) => {
	if (!profile) return null

	return (
		<Flex
			_hover={{
				backgroundColor: "red.100",
				rounded: "10",
			}}
			cursor="pointer"
			p="2"
			onClick={() => handleProfileClick?.(profile.id)}
		>
			<Box>
				{profile?.profileImageUrl ? (
					<Image
						style={{ aspectRatio: "1" }}
						src={profile?.profileImageUrl}
						height="30px"
						rounded={"md"}
					/>
				) : (
					<Flex
						height={30}
						width={30}
						bgColor="#FFE4E6"
						rounded={"lg"}
						justify="center"
						align={"center"}
						color="#FB7185"
						border={"1px"}
						borderColor={"#FECDD3"}
					>
						<BsPerson size={20} />
					</Flex>
				)}
			</Box>
			<Box flex={1} ml="2">
				<Box lineHeight={1}>{getFullName(profile)}</Box>
				<Box fontSize={"xs"} color="gray.500">
					{relation}
				</Box>
			</Box>
		</Flex>
	)
}
