import { FC, useCallback, useEffect, useState } from "react"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { Profile } from "../../../../domain/entities/profile"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { ProfileDetailsModal } from "../ProfileDetailsModal"
import { ProfileListView } from "./ProfileListView"

interface Props {
	list: Profile[]
	isLoading: boolean
	handleEditButtonClick: (
		profile: Profile,
		type: "basic" | "image" | "location",
	) => Promise<void>
	onDelete: (profile: Profile) => void
}

export const ProfileListController: FC<Props> = ({
	isLoading,
	list,
	handleEditButtonClick,
	onDelete,
}) => {
	const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)

	const [params, setParams] = useSearchParams()

	const handleProfileClick = useCallback(
		(profileId: string) => {
			var updatedParams = createSearchParams({
				id: profileId,
			})
			setParams(updatedParams)
			setSelectedProfileId(profileId)
		},
		[setParams],
	)

	useEffect(() => {
		setTimeout(() => {
			const profileId = params.get("id")

			if (profileId) {
				handleProfileClick(profileId)
			}
		}, 0)
	}, [handleProfileClick, params])

	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Profiles found." />
	}

	return (
		<>
			<ProfileListView
				list={list}
				handleEditButtonClick={handleEditButtonClick}
				onProfileClick={handleProfileClick}
				onDelete={onDelete}
			/>
			{selectedProfileId ? (
				<ProfileDetailsModal
					isOpen={!!selectedProfileId}
					setIsOpen={(val) => {
						if (!val) {
							setParams(createSearchParams())
							setSelectedProfileId(null)
						}
					}}
					profileId={selectedProfileId}
					handleProfileClick={handleProfileClick}
				/>
			) : null}
		</>
	)
}
