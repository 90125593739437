import { usePagination } from "@ajna/pagination"
import { Box, Flex, Text } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { userUpdateApi } from "src/domain/api/user/userUpdateApi"
import { userSessionDeleteApi } from "src/domain/api/userSession/userSessionDeleteApi"
import { userSessionListApi } from "src/domain/api/userSession/userSessionListApi"
import { Registration } from "src/domain/entities/registration"
import { User } from "src/domain/entities/user"
import { AuthRole } from "src/utils/enums"

import { useAuth, useUserListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ProfileDetailsModal } from "../ProfilesPage/ProfileDetailsModal"
import { UserListController } from "./UserList"

export const UsersPage: FC = () => {
	const { userList, isLoading, fetchUserList } = useUserListApi()
	const { token } = useAuth()
	const navigate = useNavigate()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const fetchUsers = useCallback(async () => {
		return await fetchUserList({
			fetch: {
				registration: true,
				profile: true,
				registrationDetails: true,
				profileDetails: true,
			},
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchUserList, pagination.currentPage])

	const handleUserReviewerChange = useCallback(
		async (val: boolean, user: User) => {
			if (!token) return
			await userUpdateApi(
				{
					id: user.id,
					update: { isReviewer: val },
				},
				token,
			)

			await fetchUsers()
		},
		[token, fetchUsers],
	)

	const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)

	const onUserClick = (user: User) => {
		if (user.profile) {
			handleProfileClick(user.profile.id)
		} else if (user.registration) {
			handleRegistrationClick(user.registration)
		}
	}

	const handleLogoutUser = async (user: User, role: AuthRole | null) => {
		const sessions = await userSessionListApi(
			{
				userIds: [user.id],
			},
			token,
		)

		const userSessions =
			role != null ? sessions.filter((e) => e.role === role) : sessions

		for (const e of userSessions) {
			await userSessionDeleteApi(
				{
					id: e.id,
				},
				token,
			)
		}
	}

	const handleProfileClick = (profileId: string) => {
		setSelectedProfileId(profileId)
	}

	const handleRegistrationClick = (registration: Registration) => {
		navigate(`/registrations/${registration.id}`)
	}

	useEffect(() => {
		fetchUsers()
	}, [fetchUsers])

	return (
		<DashboardWrapper>
			<Box padding={2} display={{ base: "none", lg: "block" }}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Users
					</Text>
				</Flex>
			</Box>
			<UserListController
				list={userList}
				isLoading={isLoading}
				handleUserReviewerChange={handleUserReviewerChange}
				handleLogoutButtonClick={handleLogoutUser}
				onUserClick={onUserClick}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={userList.length === 0}
			/>
			{selectedProfileId ? (
				<ProfileDetailsModal
					isOpen={!!selectedProfileId}
					setIsOpen={(val) => {
						if (!val) setSelectedProfileId(null)
					}}
					profileId={selectedProfileId}
					handleProfileClick={handleProfileClick}
				/>
			) : null}
		</DashboardWrapper>
	)
}
