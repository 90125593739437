import { ApiRequester } from "../../ApiRequester"

interface RequestDataShape {
	organizationId: string
	profileId: string
}

export async function organizationCommitteeMemberDeleteApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/organization/committee/member/delete/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
