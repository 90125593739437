import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { useAuth, useBusinessListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { BusinessListController } from "./BusinessList"

import { debounce } from "lodash"
import { businessVerifyApi } from "src/domain/api/business/businessVerifyApi"
import { Business } from "src/domain/entities/business"
import { BusinessDeleteDialogController } from "./BusinessDeleteDialogController"
import { BusinessUpdateDrawerFormController } from "./BusinessUpdateDrawerForm/BusinessUpdateDrawerFormController"
import { BusinessAddDrawerFormController } from "./BusinessAddDrawerForm"

export const BusinessesPage: FC = () => {
	const [isBusinessDeleteDialogOpen, setIsBusinessDeleteDialogOpen] = useState(false)
	const deleteBusinessRef = useRef<Business>()
	const { businessList, isLoading, fetchBusinessList } = useBusinessListApi()
	const { token } = useAuth()

	const [selectedIsVerifiedFilter, setSelectedIsVerifiedFilter] = useState<boolean>()

	const businessAddDrawerDisclosure = useDisclosure()
	const businessUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const searchText = useRef("")
	const [searchInputText, setSearchInputText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchBusinesses = useCallback(async () => {
		return await fetchBusinessList({
			fetch: {
				logoImage: true,
				currentVillage: {
					taluka: true,
					talukaDistrict: true,
					talukaDistrictState: true,
					talukaDistrictStateCountry: true,
				},
				identityProofImage: true,
			},
			search: searchText.current ? searchText.current : undefined,
			isVerified: selectedIsVerifiedFilter,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchBusinessList, pagination.currentPage, searchText, selectedIsVerifiedFilter])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchBusinesses = useCallback(
		debounce(async () => {
			await fetchBusinesses()
		}, 500),
		[fetchBusinesses],
	)

	const handleBusinessVerificationChange = useCallback(
		async (val: boolean, business: Business) => {
			if (!token) return
			await businessVerifyApi(
				{
					businessId: business.id,
					isVerified: val,
				},
				token,
			)

			await fetchBusinesses()
		},
		[token, fetchBusinesses],
	)

	useEffect(() => {
		fetchBusinesses()
	}, [fetchBusinesses])

	const isVerifiedOptions: {
		label: string
		value?: boolean
	}[] = [
		{
			label: "All",
			value: undefined,
		},
		{
			label: "Verified",
			value: true,
		},
		{
			label: "Un Verified",
			value: false,
		},
	]

	const updateBusinessRef = useRef<Business>()

	return (
		<DashboardWrapper>
			<Box padding={2} display={{ base: "none", lg: "contents" }}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Businesses
					</Text>
					<Button
						size="sm"
						onClick={businessAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Business
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4} mt={{ base: "2", lg: "0" }}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchInputText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									searchText.current = e.target.value
									setSearchInputText(e.target.value)
									debouncedFetchBusinesses()
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									searchText.current = el
									setSearchInputText(el)
									debouncedFetchBusinesses()
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>

					<Box width={{ base: "100%", lg: "15%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Verification" />
						<ReactSelect
							name="gender"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedIsVerifiedFilter(val?.value)
							}}
							value={isVerifiedOptions.find(
								(el) => el.value === selectedIsVerifiedFilter,
							)}
							options={isVerifiedOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<BusinessListController
				list={businessList}
				handleBusinessVerificationChange={handleBusinessVerificationChange}
				isLoading={isLoading}
				onDelete={(business) => {
					deleteBusinessRef.current = business
					setIsBusinessDeleteDialogOpen(true)
				}}
				onUpdate={(business: Business) => {
					updateBusinessRef.current = business
					businessUpdateDrawerDisclosure.onOpen()
				}}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={businessList.length === 0}
			/>
			{businessAddDrawerDisclosure.isOpen ? (
				<BusinessAddDrawerFormController
					{...businessAddDrawerDisclosure}
					onSuccess={() => fetchBusinesses()}
				/>
			) : null}
			{updateBusinessRef.current && businessUpdateDrawerDisclosure.isOpen ? (
				<BusinessUpdateDrawerFormController
					{...businessUpdateDrawerDisclosure}
					business={updateBusinessRef.current}
					onSuccess={() => fetchBusinesses()}
				/>
			) : null}
			{deleteBusinessRef.current && isBusinessDeleteDialogOpen ? (
				<BusinessDeleteDialogController
					isOpen={isBusinessDeleteDialogOpen}
					setIsOpen={setIsBusinessDeleteDialogOpen}
					business={deleteBusinessRef.current}
					onSuccess={() => fetchBusinesses()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
