import { RequestPagination } from "src/utils/types"
import { OccupationCategory } from "../../entities/occupationCategory"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	parentCategoryId?: string | null
	fetch?: {
		childCategories?: boolean
		parentCategory?: boolean
	}
	pagination?: RequestPagination
}

export async function occupationCategoryListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/occupationCategory/list/v1"
	const requester = new ApiRequester<RequestDataShape, OccupationCategory[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
