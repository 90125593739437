import { MultiLangText } from "src/utils/types"
import { Facility } from "../../entities/facility"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: MultiLangText
		icon?: string
		color?: string
	}
}

export async function facilityUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/facility/update/v1"
	const requester = new ApiRequester<RequestDataShape, Facility>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
