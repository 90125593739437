import { Box, Card, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import moment from "moment"
import { FC } from "react"
import BuildStatusTag from "src/components/shared/BuildStatusTag"
import { AndroidBuild } from "../../../../domain/entities/androidBuild"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const AndroidBuildListView: FC<{
	list: AndroidBuild[]
	onUpdate: (androidBuild: AndroidBuild) => void
	onDelete: (androidBuild: AndroidBuild) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		<Table size={"sm"} display={{ base: "none", lg: "table" }}>
			<Thead>
				<Tr>
					<Th>Build No.</Th>
					<Th>Version</Th>
					<Th>Status</Th>
					<Th>Publish date</Th>
					<Th isNumeric>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{list.map((androidBuild, i) => (
					<Tr key={i}>
						<Td>{androidBuild.buildNo}</Td>
						<Td>{androidBuild.version}</Td>
						<Td>
							<BuildStatusTag status={androidBuild.status} />
						</Td>
						<Td>{moment(androidBuild.publishDate).format("DD/MM/YYYY")}</Td>
						<Td isNumeric>
							<EditIconButton onClick={() => onUpdate(androidBuild)} />
							<DeleteIconButton onClick={() => onDelete(androidBuild)} />
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>

		{list.map((androidBuild, i) => {
			return (
				<Card
					key={i}
					display={{ base: "flex", lg: "none" }}
					shadow={"md"}
					m={"2"}
				>
					<Box display={"flex"} justifyContent={"space-between"} m={"2"}>
						<Box alignItems={"center"}>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"}>Build No:</Box>
								<Box ml={"2"} fontSize={"sm"}>
									{androidBuild.buildNo}
								</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"}>Version:</Box>
								<Box ml={"2"} fontSize={"sm"}>
									{androidBuild.version}
								</Box>
							</Flex>
							<Flex align={"center"} my={"1"}>
								<Box fontWeight={"semibold"}>Status:</Box>
								<Box ml={"2"}>
									<BuildStatusTag status={androidBuild.status} />
								</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"}>Publish Date:</Box>
								<Box ml={"2"} fontSize={"sm"}>
									{moment(androidBuild.publishDate).format(
										"DD/MM/YYYY",
									)}
								</Box>
							</Flex>
						</Box>
						<Box>
							<EditIconButton onClick={() => onUpdate(androidBuild)} />
							<DeleteIconButton onClick={() => onDelete(androidBuild)} />
						</Box>
					</Box>
				</Card>
			)
		})}
	</>
)
