import {
	Box,
	Button,
	Card,
	Flex,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import {
	BsChevronDown,
	BsImage,
	BsPatchCheckFill,
	BsPerson,
	BsPinMap,
} from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { getUserFullName } from "src/domain/entities/user"
import { Language } from "src/utils/enums"
import { formatDate, getFullName, getVillageName } from "src/utils/helpers"
import { Profile } from "../../../../domain/entities/profile"

function getAvatar(profile: Profile) {
	return profile.profileImageUrl ? (
		<Image
			style={{ aspectRatio: "1" }}
			src={profile.profileImageUrl}
			height="50px"
			rounded={"lg"}
		/>
	) : (
		<Flex
			height={50}
			width={50}
			bgColor="gray.100"
			rounded={"lg"}
			justify="center"
			align={"center"}
			color="gray.500"
		>
			<BsPerson size={20} />
		</Flex>
	)
}

function getName(profile: Profile) {
	return (
		<>
			<Text fontWeight={500}>
				<Flex align={"center"}>
					<Text>{getFullName(profile)}</Text>
					{profile.isVerified ? (
						<Text ml={2} color="blue.400">
							<BsPatchCheckFill size={16} />
						</Text>
					) : null}
				</Flex>
			</Text>
			<Text>
				{profile.firstName?.gu} {profile.middleName?.gu}{" "}
				{profile.surname ? profile.surname?.name.gu : null}
			</Text>
		</>
	)
}

function getNativeVillage(profile: Profile) {
	return (
		<>
			<Text>
				{getVillageName(profile.nativeVillage, {
					depth: 3,
				})}
			</Text>
			<Text>
				{getVillageName(profile.nativeVillage, {
					lang: Language.GUJARATI,
					depth: 3,
				})}
			</Text>
		</>
	)
}

function getCurrentVillage(profile: Profile) {
	return (
		<>
			<Text>
				{getVillageName(profile.currentVillage, {
					depth: 3,
				})}
			</Text>
			<Text>
				{getVillageName(profile.currentVillage, {
					lang: Language.GUJARATI,
					depth: 3,
				})}
			</Text>
		</>
	)
}

function getReviewerName(profile: Profile) {
	return (
		<>
			<Flex>
				<Box>
					{getUserFullName(profile.reviewedBy) ?? (
						<Text fontSize={"sm"} color="gray.400">
							No reviewer
						</Text>
					)}
				</Box>
				<Box ml={"2"}>{getUserFullName(profile.reviewedBy, "gu")}</Box>
			</Flex>
		</>
	)
}

export const ProfileListView: FC<{
	list: Profile[]
	onProfileClick: (profileId: string) => void
	handleEditButtonClick: (
		profile: Profile,
		type: "basic" | "image" | "location",
	) => Promise<void>
	onDelete: (profile: Profile) => void
}> = ({ list, onProfileClick, handleEditButtonClick, onDelete }) => (
	<>
		<Table size={"sm"} display={{ base: "none", lg: "table" }}>
			<Thead>
				<Tr>
					<Th>Avatar</Th>
					<Th>Name</Th>
					<Th>Native Village</Th>
					<Th>Current Village / City</Th>
					<Th>Reviewer</Th>
					<Th>Verified At</Th>
					<Th>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{list.map((profile, i) => (
					<Tr
						key={i}
						style={{ cursor: "pointer" }}
						_hover={{ bg: "gray.100" }}
						onClick={() => onProfileClick(profile.id)}
					>
						<Td>{getAvatar(profile)}</Td>
						<Td>{getName(profile)}</Td>
						<Td>{getNativeVillage(profile)} </Td>
						<Td>{getCurrentVillage(profile)}</Td>
						<Td>{getReviewerName(profile)}</Td>
						<Td>
							{formatDate(profile.verifiedAt, {
								includeTime: true,
							})}
						</Td>

						<Td isNumeric>
							<Menu>
								<MenuButton
									as={Button}
									aria-label="edit"
									variant="ghost"
									size={"sm"}
									mx="1"
									colorScheme={"yellow"}
									onClick={(e) => {
										e.stopPropagation()
									}}
									rightIcon={<BsChevronDown />}
								>
									Edit
								</MenuButton>
								<MenuList>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation()
											handleEditButtonClick(profile, "basic")
										}}
										icon={<BsPerson />}
									>
										Basic details
									</MenuItem>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation()
											handleEditButtonClick(profile, "image")
										}}
										icon={<BsImage />}
									>
										Profile Image
									</MenuItem>
									<MenuItem
										onClick={(e) => {
											e.stopPropagation()
											handleEditButtonClick(profile, "location")
										}}
										icon={<BsPinMap />}
									>
										Location details
									</MenuItem>
								</MenuList>
							</Menu>
							<DeleteIconButton onClick={() => onDelete(profile)} />
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>

		{list.map((profile, i) => (
			<Card
				key={i}
				display={{ base: "flex", lg: "none" }}
				m={"3"}
				shadow={"md"}
				onClick={() => onProfileClick(profile.id)}
			>
				<Flex direction={"column"}>
					<Flex m={"2"} align={"center"}>
						<Box>
							{profile.profileImageUrl ? (
								<Image
									style={{ aspectRatio: "1" }}
									src={profile.profileImageUrl}
									height="50px"
									rounded={"lg"}
								/>
							) : (
								<Flex
									height={50}
									width={50}
									bgColor="gray.100"
									rounded={"lg"}
									justify="center"
									align={"center"}
									color="gray.500"
								>
									<BsPerson size={20} />
								</Flex>
							)}
						</Box>
						<Box ml={"2"} fontSize={"sm"}>
							{getName(profile)}
						</Box>
					</Flex>
					<hr style={{ width: "90%", margin: "6px auto" }} />
					<Flex direction={"column"} ml={"2"} fontSize={"sm"}>
						<Box fontWeight={"semibold"}>Native Village:</Box>
						<Box>{getNativeVillage(profile)}</Box>
					</Flex>
					<hr style={{ width: "90%", margin: "6px auto" }} />
					<Flex direction={"column"} ml={"2"} fontSize={"sm"}>
						<Box fontWeight={"semibold"}>Current Village:</Box>
						<Box>{getCurrentVillage(profile)}</Box>
					</Flex>
					<hr style={{ width: "90%", margin: "6px auto" }} />
					<Flex ml={"2"} fontSize={"sm"} mb={"2"}>
						<Box fontWeight={"semibold"}>Reviewer:</Box>
						<Box ml={"2"}>{getReviewerName(profile)}</Box>
					</Flex>
				</Flex>
			</Card>
		))}
	</>
)
