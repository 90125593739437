import { ComponentProps, FC, useCallback, useEffect } from "react"
import { occupationCategoryUpdateApi } from "src/domain/api/occupationCategory/occupationCategoryUpdateApi"
import { OccupationCategory } from "../../../../domain/entities/occupationCategory"
import { useAuth, useOccupationCategoryListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IOccupationCategoryUpdateFormFields } from "./IOccupationCategoryUpdateFormFields"
import { OccupationCategoryUpdateDrawerFormView } from "./OccupationCategoryUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof OccupationCategoryUpdateDrawerFormView>,
	"handleSubmit" | "parentOccupationCategoryList"
> & {
	onSuccess?: (occupationCategory: OccupationCategory) => void
}

export const OccupationCategoryUpdateDrawerFormController: FC<Props> = ({
	occupationCategory,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IOccupationCategoryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedOccupationCategory = await occupationCategoryUpdateApi(
					{
						id: occupationCategory.id,
						// parentCategoryId: occupationCategory.parentCategory?.id,
						update: values,
					},
					token,
				)
				onSuccess && onSuccess(updatedOccupationCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[
			onSuccess,
			rest,
			token,
			occupationCategory.id,
			// occupationCategory.parentCategory?.id,
		],
	)

	const { fetchOccupationCategoryList, occupationCategoryList } =
		useOccupationCategoryListApi()

	useEffect(() => {
		fetchOccupationCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
			},
		})
	}, [fetchOccupationCategoryList])

	return (
		<OccupationCategoryUpdateDrawerFormView
			occupationCategory={occupationCategory}
			parentOccupationCategoryList={occupationCategoryList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
