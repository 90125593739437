import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
}

export async function discussionRecipientsNotifyApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/discussion/recipients/notify/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	await requester.sendRequest(payload, token)
}
