import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Image,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useState } from "react"
import { BsPerson, BsPlusCircleFill } from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"
import { ProductCategory } from "src/domain/entities/productCategory"
import { useProductCategoryListApi } from "src/domain/hooks/useProductCategoryListApi"
import { formatDate } from "src/utils/helpers"
import { ProductCategoryAddDrawerFormController } from "../ProductCategoryAddDrawerForm"

export const ProductCategoryListView: FC<{
	list: ProductCategory[]
	onUpdate: (productCategory: ProductCategory) => void
	onDelete: (productCategory: ProductCategory) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		{list.map((productCategory) => (
			<ProductCategoryAccordionItem
				key={productCategory.id}
				productCategory={productCategory}
				onUpdate={onUpdate}
				onDelete={onDelete}
			/>
		))}
	</>
)

const ProductCategoryAccordionItem = ({
	productCategory,
	onUpdate,
	onDelete,
}: // refetch,
{
	productCategory: ProductCategory
	onUpdate: (productCategory: ProductCategory) => void
	onDelete: (productCategory: ProductCategory) => void
	// refetch: () => void
}) => {
	const { productCategoryList: childCategories, fetchProductCategoryList } =
		useProductCategoryListApi()

	const [selectedViewImageUrl, setSelectedViewImageUrl] = useState<string | null>(null)

	const fetchChildCategories = async () => {
		await fetchProductCategoryList({
			parentCategoryId: productCategory.id,
			fetch: {
				childCategories: true,
				parentCategory: true,
				image: true,
			},
		})
	}

	return (
		<Accordion allowMultiple>
			<AccordionItem border={"none"}>
				<AccordionButton
					_focus={{
						outline: "none",
					}}
					textAlign={"left"}
					justifyContent={"space-between"}
					onClick={() => {
						fetchChildCategories()
					}}
				>
					<Flex>
						{productCategory.imageUrl ? (
							<Image
								style={{ aspectRatio: "1" }}
								src={productCategory.imageUrl}
								onClick={(e) => {
									e.stopPropagation()
									setSelectedViewImageUrl(productCategory.imageUrl!)
								}}
								height="50px"
								rounded={"lg"}
								mr={4}
							/>
						) : (
							<Flex
								height={50}
								width={50}
								mr={4}
								bgColor="gray.100"
								rounded={"lg"}
								justify="center"
								align={"center"}
								color="gray.500"
							>
								<BsPerson size={20} />
							</Flex>
						)}
						<Flex align={"center"} justify="space-between" w="full">
							<Box>
								{productCategory.name.en +
									" (" +
									productCategory.name.gu +
									")"}
							</Box>
							<Flex ml={"2"} align="center">
								<Text fontSize={"sm"} mr={4}>
									{formatDate(productCategory.updatedAt)}
								</Text>
								<EditIconButton
									onClick={() => {
										onUpdate(productCategory)
									}}
								/>
								<DeleteIconButton
									onClick={() => {
										onDelete(productCategory)
									}}
								/>
							</Flex>
						</Flex>
					</Flex>

					<AccordionIcon />
				</AccordionButton>

				{selectedViewImageUrl != null ? (
					<ImageViewModalController
						isOpen={selectedViewImageUrl != null}
						setIsOpen={() => setSelectedViewImageUrl(null)}
						image={
							<Image
								src={selectedViewImageUrl}
								alt="profile"
								objectFit="contain"
								maxH="90vh"
								maxW="90vw"
							/>
						}
					/>
				) : null}
				<AccordionPanel>
					<Box>
						{childCategories?.length ? (
							<>
								<Box ml={6}>
									<AddProductCategoryButton
										parentCategory={productCategory}
										refetch={fetchChildCategories}
									/>
								</Box>
								{childCategories?.map((cC) => (
									<ProductCategoryAccordionItem
										key={cC.id}
										productCategory={cC}
										onUpdate={onUpdate}
										onDelete={onDelete}
									/>
								))}
							</>
						) : (
							<Box ml={6}>
								<AddProductCategoryButton
									parentCategory={productCategory}
									refetch={fetchChildCategories}
								/>
							</Box>
						)}
					</Box>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

const AddProductCategoryButton = ({
	parentCategory,
	refetch,
}: {
	parentCategory: ProductCategory
	refetch?: () => void
}) => {
	const addProductCategoryDisclosure = useDisclosure()

	return (
		<>
			<Button
				leftIcon={<BsPlusCircleFill />}
				colorScheme="blue"
				variant="link"
				onClick={addProductCategoryDisclosure.onOpen}
			>
				Add Child Category
			</Button>
			{addProductCategoryDisclosure.isOpen ? (
				<ProductCategoryAddDrawerFormController
					initialValues={{
						name: {
							en: "",
							gu: "",
						},
						featuredImageId: "",
						parentCategoryId: parentCategory?.id,
					}}
					onClose={addProductCategoryDisclosure.onClose}
					isOpen={addProductCategoryDisclosure.isOpen}
					onSuccess={() => refetch?.()}
				/>
			) : null}
		</>
	)
}
