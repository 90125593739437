import { MultiLangText } from "src/utils/types"
import { SubCaste } from "../../entities/subCaste"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		code?: string
		name?: MultiLangText
	}
}

export async function subCasteUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/subCaste/update/v1"
	const requester = new ApiRequester<RequestDataShape, SubCaste>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
