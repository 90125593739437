import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { businessUpdateApi } from "src/domain/api/business/businessUpdateApi"
import { Business } from "src/domain/entities/business"

import { useAuth, useVillageListApi } from "../../../../domain/hooks"

import { FormikOnSubmit } from "src/utils/types"
import { IBusinessUpdateFormFields } from "./IBusinessUpdateFormFields"

import { BusinessUpdateDrawerFormView } from "./BusinessUpdateDrawerFormView"
import { File } from "src/domain/entities/file"

type Props = Omit<
	ComponentProps<typeof BusinessUpdateDrawerFormView>,
	| "handleSubmit"
	| "villageList"
	| "setVillageSearchText"
	| "handleUploadImage"
	| "selectedImage"
	| "setSelectedImage"
> & {
	onSuccess?: (business: Business) => void
}

export const BusinessUpdateDrawerFormController: FC<Props> = ({
	business,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IBusinessUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedBusiness = await businessUpdateApi(
					{
						id: business.id,
						update: {
							...values,
							logoImageId: selectedImage?.id ?? values.logoImageId,
						},
					},
					token,
				)
				onSuccess && onSuccess(updatedBusiness)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, business.id],
	)

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)

			return response as File
		},
		[token],
	)

	const { fetchVillageList, villageList } = useVillageListApi()
	const [villageSearchText, setVillageSearchText] = useState(
		business.currentVillage?.name.en ?? "",
	)
	useEffect(() => {
		fetchVillageList({
			search: villageSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {
				talukaDistrict: true,
			},
		})
	}, [fetchVillageList, villageSearchText])

	return (
		<BusinessUpdateDrawerFormView
			business={business}
			handleSubmit={handleSubmit}
			villageList={villageList}
			setVillageSearchText={setVillageSearchText}
			selectedImage={selectedImage}
			setSelectedImage={setSelectedImage}
			handleUploadImage={handleUploadImage}
			{...rest}
		/>
	)
}
