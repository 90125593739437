import { MobileAppBuildStatus } from "src/utils/enums"
import { RequestPagination } from "src/utils/types"
import { AndroidBuild } from "../../entities/androidBuild"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	buildNo?: number
	version?: string
	publishDate?: number
	status?: MobileAppBuildStatus
	pagination?: RequestPagination
}

export async function androidBuildListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/android/build/list/v1"
	const requester = new ApiRequester<RequestDataShape, AndroidBuild[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
