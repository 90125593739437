import { FormControl, Stack } from "@chakra-ui/react"
import { Formik } from "formik"
import { ComponentProps, FC } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { DrawerForm } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { Profile } from "src/domain/entities/profile"
import { Village } from "src/domain/entities/village"
import { getVillageName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IProfileLocationUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	profile: Profile
	handleSubmit: FormikOnSubmit<IProfileLocationUpdateFormFields>
	nativeVillageList: Village[]
	nativeVillageSearchText: string
	setNativeVillageSearchText: (value: string) => void
	currentVillageList: Village[]
	currentVillageSearchText: string
	setCurrentVillageSearchText: (value: string) => void
}

export const ProfileLocationUpdateDrawerFormView: FC<Props> = ({
	profile,
	handleSubmit,
	nativeVillageList,
	setNativeVillageSearchText,
	currentVillageList,
	setCurrentVillageSearchText,
	...rest
}) => {
	if (!profile) return null

	return (
		<Formik<IProfileLocationUpdateFormFields>
			initialValues={{
				nativeVillageId: profile.nativeVillage?.id,
				currentVillageId: profile.currentVillage?.id,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, setFieldValue }) => {
				const nativeVillageOptions = nativeVillageList.map((nativeVillage) => ({
					value: nativeVillage.id,
					label: getVillageName(nativeVillage),
				}))

				const currentVillageOptions = currentVillageList.map(
					(currentVillage) => ({
						value: currentVillage.id,
						label: getVillageName(currentVillage),
					}),
				)

				return (
					<DrawerForm
						size="sm"
						headerLabel="Location Details Update"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Native village */}
							<FormControl flex={2}>
								<InputLabel label="Native village" />
								<ReactSelect
									name="nativeVillageId"
									onChange={(newValue) => {
										setFieldValue(
											"nativeVillageId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(newValue) => {
										setNativeVillageSearchText(newValue)
									}}
									value={nativeVillageOptions.find(
										(el) => el.value === values.nativeVillageId,
									)}
									options={nativeVillageOptions}
									isSearchable
								/>
							</FormControl>
							{/* Current village */}
							<FormControl flex={2}>
								<InputLabel label="Current village" />
								<ReactSelect
									name="currentVillageId"
									onChange={(newValue) => {
										setFieldValue(
											"currentVillageId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(newValue) => {
										setCurrentVillageSearchText(newValue)
									}}
									value={currentVillageOptions.find(
										(el) => el.value === values.currentVillageId,
									)}
									options={currentVillageOptions}
									isSearchable
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
