import { useCallback, useState } from "react"
import { androidBuildListApi } from "../api/androidBuild/androidBuildListApi"
import { AndroidBuild } from "../entities/androidBuild"
import { useAuth } from "./useAuth"

export function useAndroidBuildListApi(): {
	androidBuildList: AndroidBuild[]
	isLoading: boolean
	error: null | Error
	fetchAndroidBuildList: (
		filter?: Parameters<typeof androidBuildListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [androidBuildList, setAndroidBuildList] = useState<AndroidBuild[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchAndroidBuildList = useCallback(
		async (filter: Parameters<typeof androidBuildListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await androidBuildListApi(filter, token)
				setAndroidBuildList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { androidBuildList, isLoading, error, fetchAndroidBuildList }
}
