import { DiscussionReply } from "src/domain/entities/discussionReply"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	createdById?: string
	update: {
		text: string
	}
}

export async function discussionReplyUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/discussion/reply/update/v1"
	const requester = new ApiRequester<RequestDataShape, DiscussionReply>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
