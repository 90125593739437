import { useCallback, useState } from "react"
import { productCategoryListApi } from "../api/productCategory/productCategoryListApi"
import { ProductCategory } from "../entities/productCategory"
import { useAuth } from "./useAuth"

export function useProductCategoryListApi(): {
	productCategoryList: ProductCategory[]
	isLoading: boolean
	fetchProductCategoryList: (
		filter?: Parameters<typeof productCategoryListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [productCategoryList, setProductCategoryList] = useState<ProductCategory[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchProductCategoryList = useCallback(
		async (filter: Parameters<typeof productCategoryListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await productCategoryListApi(filter, token)
			setProductCategoryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { productCategoryList, isLoading, fetchProductCategoryList }
}
