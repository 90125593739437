import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	registrationIds: string[]
}

export async function reviewerRegistrationsUnlinkApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/reviewer/registrations/unlink/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
