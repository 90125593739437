import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { Unit } from "src/domain/entities/unit"
import { formatDate } from "src/utils/helpers"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const UnitListView: FC<{
	list: Unit[]
	onUpdate: (unit: Unit) => void
	onDelete: (unit: Unit) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Position</Th>
				<Th>Name</Th>
				<Th>Name Gujarati</Th>
				<Th>Created At</Th>
				<Th>Updated At</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((unit, i) => (
				<Tr key={i}>
					<Td>{unit.position}</Td>
					<Td>{unit.name.en}</Td>
					<Td>{unit.name.gu}</Td>
					<Td>{formatDate(unit.createdAt)}</Td>
					<Td>{formatDate(unit.updatedAt)}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(unit)} />
						<DeleteIconButton onClick={() => onDelete(unit)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
