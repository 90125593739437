import { Box, Button, Flex, FormControl, IconButton, Text } from "@chakra-ui/react"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { BsTrash2 } from "react-icons/bs"
import { ReactSelect } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { surnameRelateApi } from "src/domain/api/surname/surnameRelateApi"
import { useAuth, useSurnameListApi } from "src/domain/hooks"
import { Surname } from "../../../../domain/entities/surname"
import { DrawerBasic, DrawerForm } from "src/components/ui"
import { surnameUnrelateApi } from "src/domain/api/surname/surnameUnrelateApi"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	surname: Surname
}

export const SurnameRelatedSurnamesDrawer: FC<Props> = ({
	surname: { id: surnameId },
	...rest
}) => {
	const { token } = useAuth()

	const { fetchSurnameList: fetchSurnameListForDetails } = useSurnameListApi()

	const [surname, setSurname] = useState<Surname>()

	const fetchSurnameDetails = useCallback(async () => {
		const surnameList = await fetchSurnameListForDetails({
			fetch: {
				related: true,
				subCaste: true,
			},
			id: surnameId,
		})

		setSurname(surnameList[0])
	}, [fetchSurnameListForDetails, surnameId])

	useEffect(() => {
		fetchSurnameDetails()
	}, [fetchSurnameDetails])

	const { fetchSurnameList, surnameList } = useSurnameListApi()
	const [surnameSearchText, setSurnameSearchText] = useState("")
	useEffect(() => {
		fetchSurnameList({
			search: surnameSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {},
		})
	}, [fetchSurnameList, surnameSearchText])

	const surnameOptions = surnameList.map((surname) => ({
		value: surname.id,
		label: `${surname.name.en} (${surname.name.gu})`,
	}))

	const [selectedSurnameId, setSelectedSurnameId] = useState<string>()

	const handleAddRelatedSurname = useCallback(async () => {
		if (!selectedSurnameId || !surname) return alert("Please select a surname")

		await surnameRelateApi(
			{
				surnameId: surnameId,
				relatedSurnameId: selectedSurnameId,
			},
			token,
		)

		fetchSurnameDetails()
		setSelectedSurnameId(undefined)
	}, [fetchSurnameDetails, selectedSurnameId, surnameId, token, surname])

	const handleDeleteRelatedSurname = useCallback(
		async (surname: Surname) => {
			await surnameUnrelateApi(
				{
					surnameId: surname.id,
				},
				token,
			)

			fetchSurnameDetails()
		},
		[fetchSurnameDetails, token],
	)

	if (!surname) return null

	return (
		<DrawerBasic size={"md"} headerLabel={"Related Surnames "} {...rest}>
			<Flex
				fontSize={"18px"}
				mb={4}
				fontWeight={"medium"}
				backgroundColor={"gray.100"}
				justify={"center"}
				padding={2}
				rounded={"lg"}
			>
				<Text mr={2}>{surname.name.en}</Text> /
				<Text mx={2}>{surname.name.gu}</Text> /
				<Text ml={2}> {surname.subCaste?.name.en}</Text>
			</Flex>
			{surname?.relatedSurnames?.length ? (
				<>
					<Flex gridGap={4} direction={"column"}>
						{surname?.relatedSurnames?.map((relSurname, i) => (
							<Flex key={i} justify="space-between" align={"center"}>
								<Box flex={1} mx={2}>
									<Text fontWeight={"semibold"}>
										{relSurname.name.en} ({relSurname.name.gu})
									</Text>
								</Box>

								<IconButton
									size={"sm"}
									aria-label="Delete"
									colorScheme={"red"}
									icon={<BsTrash2 />}
									onClick={() => handleDeleteRelatedSurname(relSurname)}
								/>
							</Flex>
						))}
					</Flex>
					<hr style={{ marginTop: "16px", marginBottom: "16px" }} />
				</>
			) : null}
			<Flex gridGap={2} align={"flex-end"}>
				<FormControl>
					<InputLabel label="Member surname" />
					<ReactSelect
						onChange={(newValue) => {
							setSelectedSurnameId((newValue as any).value)
						}}
						onInputChange={(text) => {
							setSurnameSearchText(text)
						}}
						value={surnameOptions.find(
							(option) => option.value === selectedSurnameId,
						)}
						options={surnameOptions}
						isSearchable
					/>
				</FormControl>
				<Button ml={4} onClick={handleAddRelatedSurname} colorScheme={"blue"}>
					Add
				</Button>
			</Flex>
		</DrawerBasic>
	)
}
