import { Business } from "src/domain/entities/business"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: MultiLangText
		logoImageId?: string
		description?: string
		currentVillageId?: string
		servingLocationIds?: string[]
		locationCoordinate?: {
			longitude?: number
			latitude?: number
		}
		type?: "product" | "service" | "both"
		categoryId?: string
		registrationType?:
			| "unregistered"
			| "one_person_company"
			| "sole_proprietorship_firm"
			| "partnership_firm"
			| "limited_liability_partnership_firm"
			| "private_limited_company"
			| "public_limited_company"
		mobileNumber1?: string
		mobileNumber2?: string
		email?: string
		website?: string
		address?: string
		isVerified?: boolean
	}
}

export async function businessUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/business/update/v1"
	const requester = new ApiRequester<RequestDataShape, Business>(endpoint)

	// if (!payload.update.email) delete payload.update.email
	// if (!payload.update.website) delete payload.update.website
	// if (!payload.update.mobileNumber1) delete payload.update.mobileNumber1
	// if (!payload.update.mobileNumber2) delete payload.update.mobileNumber2
	// if (!payload.update.description) delete payload.update.description
	// if (!payload.update.logoImageId) delete payload.update.logoImageId
	// if (!payload.update.registrationType) delete payload.update.registrationType
	const response = await requester.sendRequest(payload, token)
	return response
}
