import { ProductCategory } from "src/domain/entities/productCategory"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	parentCategoryId?: string | null
	fetch?: {
		childCategories?: boolean
		parentCategory?: boolean
		image?: boolean
	}
	pagination?: RequestPagination
}

export async function productCategoryListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/productCategory/list/v1"
	const requester = new ApiRequester<RequestDataShape, ProductCategory[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
