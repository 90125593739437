import { ComponentProps, FC, useCallback, useEffect } from "react"
import { stateUpdateApi } from "src/domain/api/state/stateUpdateApi"
import { State } from "../../../../domain/entities/state"
import { useAuth, useCountryListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IStateUpdateFormFields } from "./IStateUpdateFormFields"
import { StateUpdateDrawerFormView } from "./StateUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof StateUpdateDrawerFormView>,
	"handleSubmit" | "countryList"
> & {
	onSuccess?: (state: State) => void
}

export const StateUpdateDrawerFormController: FC<Props> = ({
	state,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IStateUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (!state.country?.id) return
				const updatedState = await stateUpdateApi(
					{ id: state.id, countryId: state.country.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedState)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, state.id, state.country?.id],
	)
	const { fetchCountryList, countryList } = useCountryListApi()

	useEffect(() => {
		fetchCountryList()
	}, [fetchCountryList])

	return (
		<StateUpdateDrawerFormView
			state={state}
			countryList={countryList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
