import { useCallback, useState } from "react"
import { registrationListApi } from "../api/registration/registrationListApi"
import { Registration } from "../entities/registration"
import { useAuth } from "./useAuth"

export function useRegistrationListApi(): {
	registrationList: Registration[]
	isLoading: boolean
	error: null | Error
	fetchRegistrationList: (
		filter?: Parameters<typeof registrationListApi>[0],
	) => Promise<Registration[]>
} {
	const { token } = useAuth()
	const [registrationList, setRegistrationList] = useState<Registration[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchRegistrationList = useCallback(
		async (filter: Parameters<typeof registrationListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await registrationListApi(filter, token)
				setRegistrationList(data)
				return data
			} catch (error) {
				setError(error as Error)
				return []
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { registrationList, isLoading, error, fetchRegistrationList }
}
