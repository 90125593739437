import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { Facility } from "../../../../domain/entities/facility"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const FacilityListView: FC<{
	list: Facility[]
	onUpdate: (facility: Facility) => void
	onDelete: (facility: Facility) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Name Gujarati</Th>
				<Th>Color</Th>
				<Th>Icon</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((facility, i) => (
				<Tr key={i}>
					<Td>{facility.name.en}</Td>
					<Td>{facility.name.gu}</Td>
					<Td>
						<Flex align={"center"} gap={1}>
							<Box h={4} w={4} backgroundColor={facility.color}></Box>
							<Box>{facility.color}</Box>
						</Flex>
					</Td>
					<Td className="text-red">
						<img
							src={`data:image/svg+xml;utf8,${facility.icon}`}
							alt="icon"
						/>
					</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(facility)} />
						<DeleteIconButton onClick={() => onDelete(facility)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
