import { FC } from "react"
import { Organization } from "../../../../domain/entities/organization"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { OrganizationListView } from "./OrganizationListView"

interface Props {
	list: Organization[]
	isLoading: boolean
	onMemberButtonClick: (organization: Organization) => void
	handleOrganizationVerificationChange: (
		val: boolean,
		organization: Organization,
	) => Promise<void>
	onUpdate: (organization: Organization) => void
	onDelete: (organization: Organization) => void
	onAddClick: () => void
}

export const OrganizationListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
	onMemberButtonClick,
	handleOrganizationVerificationChange,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No Organizations found."
				onAddClick={onAddClick}
			/>
		)
	}

	return (
		<OrganizationListView
			list={list}
			onUpdate={onUpdate}
			onDelete={onDelete}
			onMemberButtonClick={onMemberButtonClick}
			handleOrganizationVerificationChange={handleOrganizationVerificationChange}
		/>
	)
}
