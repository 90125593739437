import { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import { adminListApi } from "src/domain/api/admin/adminListApi"
import { systemStatsGetApi } from "src/domain/api/system/systemStatsGetApi"
import { Admin, SystemStats } from "src/domain/entities/admin"
import { useAuthContext } from "./auth"

interface ICoreState {
	admin: Admin | null
	setAdmin: Function
	systemStats: SystemStats | null
	setSystemStats: Function
}

const CoreContext = createContext<ICoreState>({
	admin: null,
	setAdmin: () => {},
	systemStats: null,
	setSystemStats: () => {},
})

export const CoreContextProvider: FC<{ children?: JSX.Element }> = ({
	children,
	...rest
}) => {
	const { token } = useAuthContext()
	const [admin, setStateAdmin] = useState<ICoreState["admin"]>(null)

	const fetchAdminDetails = useCallback(async () => {
		if (!token) return

		const adminList = await adminListApi({}, token)

		if (!adminList?.length) return

		setStateAdmin(adminList[0])
	}, [token, setStateAdmin])

	useEffect(() => {
		fetchAdminDetails()
	}, [fetchAdminDetails])

	const setAdmin = (value: Admin | null) => {
		setStateAdmin(value)
	}

	const [systemStats, setStateSystemStats] = useState<ICoreState["systemStats"]>(null)

	const fetchSystemStats = useCallback(async () => {
		if (!token) return

		const systemStats = await systemStatsGetApi(token)

		setStateSystemStats(systemStats)
	}, [token, setStateSystemStats])

	useEffect(() => {
		fetchSystemStats()
	}, [fetchSystemStats])

	const setSystemStats = (value: SystemStats | null) => {
		setStateSystemStats(value)
	}

	return (
		<CoreContext.Provider
			value={{ admin, setAdmin, systemStats, setSystemStats }}
			{...rest}
		>
			{children}
		</CoreContext.Provider>
	)
}

export function useCoreContext() {
	return useContext(CoreContext)
}
