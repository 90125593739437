import { ComponentProps, FC, useCallback } from "react"
import { unitAddApi } from "src/domain/api/unit/unitAddApi"
import { Unit } from "src/domain/entities/unit"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IUnitAddFormFields } from "./IUnitAddFormFields"
import { UnitAddDrawerFormView } from "./UnitAddDrawerFormView"

interface Props
	extends Omit<ComponentProps<typeof UnitAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (unit: Unit) => void
}

export const UnitAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IUnitAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const unit = await unitAddApi(
					{
						...values,
						position: +values.position,
					},
					token,
				)
				onSuccess(unit)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	return <UnitAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
