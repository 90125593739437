import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"

import { DiscussionCategory } from "../../../domain/entities/discussionCategory"
import { useDiscussionCategoryListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { DiscussionCategoryAddDrawerFormController } from "./DiscussionCategoryAddDrawerForm"
import { DiscussionCategoryDeleteDialogController } from "./DiscussionCategoryDeleteDialogController"
import { DiscussionCategoryListController } from "./DiscussionCategoryList"
import { DiscussionCategoryUpdateDrawerFormController } from "./DiscussionCategoryUpdateDrawerForm"

export const DiscussionCategoriesPage: FC = () => {
	const { discussionCategoryList, isLoading, fetchDiscussionCategoryList } =
		useDiscussionCategoryListApi()
	const [
		isDiscussionCategoryDeleteDialogOpen,
		setIsDiscussionCategoryDeleteDialogOpen,
	] = useState(false)
	const discussionCategoryAddDrawerDisclosure = useDisclosure()
	const discussionCategoryUpdateDrawerDisclosure = useDisclosure()

	const fetchDiscussionCategories = useCallback(async () => {
		return await fetchDiscussionCategoryList({})
	}, [fetchDiscussionCategoryList])

	useEffect(() => {
		fetchDiscussionCategories()
	}, [fetchDiscussionCategories])

	const updateDiscussionCategoryRef = useRef<DiscussionCategory>()
	const deleteDiscussionCategoryRef = useRef<DiscussionCategory>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Discussion Categories
					</Text>
					<Button
						size="sm"
						onClick={discussionCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Discussion Category
					</Button>
				</Flex>
			</Box>
			<DiscussionCategoryListController
				list={discussionCategoryList}
				isLoading={isLoading}
				onUpdate={(discussionCategory: DiscussionCategory) => {
					updateDiscussionCategoryRef.current = discussionCategory
					discussionCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(discussionCategory: DiscussionCategory) => {
					deleteDiscussionCategoryRef.current = discussionCategory
					setIsDiscussionCategoryDeleteDialogOpen(true)
				}}
				onAddClick={discussionCategoryAddDrawerDisclosure.onOpen}
			/>
			{discussionCategoryAddDrawerDisclosure.isOpen ? (
				<DiscussionCategoryAddDrawerFormController
					{...discussionCategoryAddDrawerDisclosure}
					onSuccess={() => fetchDiscussionCategoryList()}
				/>
			) : null}
			{updateDiscussionCategoryRef.current &&
			discussionCategoryUpdateDrawerDisclosure.isOpen ? (
				<DiscussionCategoryUpdateDrawerFormController
					{...discussionCategoryUpdateDrawerDisclosure}
					discussionCategory={updateDiscussionCategoryRef.current}
					onSuccess={() => fetchDiscussionCategoryList()}
				/>
			) : null}
			{deleteDiscussionCategoryRef.current &&
			isDiscussionCategoryDeleteDialogOpen ? (
				<DiscussionCategoryDeleteDialogController
					isOpen={isDiscussionCategoryDeleteDialogOpen}
					setIsOpen={setIsDiscussionCategoryDeleteDialogOpen}
					discussionCategory={deleteDiscussionCategoryRef.current}
					onSuccess={() => fetchDiscussionCategoryList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
