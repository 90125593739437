import { Gender, MaritalStatus } from "src/utils/enums"
import { MultiLangText } from "src/utils/types"
import { Profile } from "../../entities/profile"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	firstName: MultiLangText
	surnameId?: string
	customSurname?: {
		subCasteId: string
		name: MultiLangText
	}
	isAlive: boolean
	dateOfBirth?: number | null
	dateOfDeath?: number | null
	gender: Gender
	maritalStatus: MaritalStatus
	nativeVillageId: string
	currentVillageId?: string
	isBornNonPatidar: boolean
	isMarriedToNonPatidar: boolean
	profileImageId?: string
}

export async function profileAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/profile/add/v1"
	const requester = new ApiRequester<RequestDataShape, Profile>(endpoint)
	return await requester.sendRequest(payload, token)
}
