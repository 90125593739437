import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { State } from "src/domain/entities/state"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { District } from "../../../domain/entities/district"
import { useDistrictListApi, useStateListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { DistrictAddDrawerFormController } from "./DistrictAddDrawerForm"
import { DistrictDeleteDialogController } from "./DistrictDeleteDialogController"
import { DistrictListController } from "./DistrictList"
import { DistrictUpdateDrawerFormController } from "./DistrictUpdateDrawerForm"

export const DistrictsPage: FC = () => {
	const { districtList, isLoading, fetchDistrictList } = useDistrictListApi()
	const [isDistrictDeleteDialogOpen, setIsDistrictDeleteDialogOpen] = useState(false)
	const districtAddDrawerDisclosure = useDisclosure()
	const districtUpdateDrawerDisclosure = useDisclosure()

	const { stateList, fetchStateList } = useStateListApi()

	const [selectedState, setSelectedState] = useState<State>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchDistricts = useCallback(async () => {
		return await fetchDistrictList({
			fetch: { stateCountry: true },
			search: searchText,
			stateId: selectedState?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchDistrictList, pagination.currentPage, selectedState?.id, searchText])

	useEffect(() => {
		fetchDistricts()
		fetchStateList()
	}, [fetchDistricts, fetchStateList])

	const updateDistrictRef = useRef<District>()
	const deleteDistrictRef = useRef<District>()

	const stateOptions: {
		label: string
		value?: State
	}[] = stateList.map((state) => ({
		label: state.name.en,
		value: state,
	}))

	stateOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Districts
					</Text>
					<Button
						size="sm"
						onClick={districtAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add District
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* State */}
					<Box width={{ base: "full", lg: "20%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="State" />
						<ReactSelect
							name="stateId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedState(val?.value)
							}}
							value={stateOptions.find(
								(el) => el.value?.id === selectedState?.id,
							)}
							options={stateOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<DistrictListController
				list={districtList}
				isLoading={isLoading}
				onUpdate={(district: District) => {
					updateDistrictRef.current = district
					districtUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(district: District) => {
					deleteDistrictRef.current = district
					setIsDistrictDeleteDialogOpen(true)
				}}
				onAddClick={districtAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={districtList.length === 0}
			/>
			{districtAddDrawerDisclosure.isOpen ? (
				<DistrictAddDrawerFormController
					{...districtAddDrawerDisclosure}
					onSuccess={() => fetchDistricts()}
				/>
			) : null}
			{updateDistrictRef.current && districtUpdateDrawerDisclosure.isOpen ? (
				<DistrictUpdateDrawerFormController
					{...districtUpdateDrawerDisclosure}
					district={updateDistrictRef.current}
					onSuccess={() => fetchDistricts()}
				/>
			) : null}
			{deleteDistrictRef.current && isDistrictDeleteDialogOpen ? (
				<DistrictDeleteDialogController
					isOpen={isDistrictDeleteDialogOpen}
					setIsOpen={setIsDistrictDeleteDialogOpen}
					district={deleteDistrictRef.current}
					onSuccess={() => fetchDistricts()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
