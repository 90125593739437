import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { productCategoryUpdateApi } from "src/domain/api/productCategory/productCategoryUpdateApi"
import { File } from "src/domain/entities/file"
import { ProductCategory } from "src/domain/entities/productCategory"
import { useProductCategoryListApi } from "src/domain/hooks/useProductCategoryListApi"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IProductCategoryUpdateFormFields } from "./IProductCategoryUpdateFormFields"
import { ProductCategoryUpdateDrawerFormView } from "./ProductCategoryUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof ProductCategoryUpdateDrawerFormView>,
	"handleSubmit" | "parentProductCategoryList" | "handleSubmit" | "handleUploadImage"
> & {
	onSuccess?: (productCategory: ProductCategory) => void
}

export const ProductCategoryUpdateDrawerFormController: FC<Props> = ({
	productCategory,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleSubmit = useCallback<FormikOnSubmit<IProductCategoryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedProductCategory = await productCategoryUpdateApi(
					{
						id: productCategory.id,
						update: { ...values, imageId: selectedImage?.id },
					},
					token,
				)
				onSuccess && onSuccess(updatedProductCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, productCategory.id],
	)

	const { fetchProductCategoryList, productCategoryList } = useProductCategoryListApi()

	useEffect(() => {
		fetchProductCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
				image: true,
			},
		})
	}, [fetchProductCategoryList])

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)
		},
		[token],
	)

	return (
		<ProductCategoryUpdateDrawerFormView
			productCategory={productCategory}
			parentProductCategoryList={productCategoryList}
			handleSubmit={handleSubmit}
			handleUploadImage={handleUploadImage}
			selectedImage={selectedImage}
			{...rest}
		/>
	)
}
