import { useToast } from "@chakra-ui/react"
import moment from "moment"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { registrationBasicUpdateApi } from "src/domain/api/registration/registrationBasicUpdateApi"
import { Registration } from "src/domain/entities/registration"
import { DomainError } from "src/domain/errors"
import { useAuth, useSurnameListApi } from "src/domain/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IRegistrationBasicUpdateFormFields } from "./IRegistrationBasicUpdateFormFields"
import { RegistrationBasicUpdateDrawerFormView } from "./RegistrationBasicUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof RegistrationBasicUpdateDrawerFormView>,
	"handleSubmit" | "surnameList" | "surnameSearchText" | "setSurnameSearchText"
> & {
	onSuccess?: (registrationBasic: Registration) => void
}

export const RegistrationBasicUpdateDrawerFormController: FC<Props> = ({
	registration,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()
	const toast = useToast()

	const handleSubmit = useCallback<FormikOnSubmit<IRegistrationBasicUpdateFormFields>>(
		async (values) => {
			try {
				if (!registration) return

				if (values.dateOfBirth) {
					values.dateOfBirth = moment(values.dateOfBirth).unix() * 1000
				}

				const updatedState = await registrationBasicUpdateApi(
					{ id: registration.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedState)
				rest.onClose()
			} catch (err) {
				if (err instanceof DomainError) {
					toast({
						title: err.name,
						description: err.message,
						status: "error",
						position: "bottom-right",
						isClosable: true,
						duration: 10000,
					})
				}
			}
		},
		[onSuccess, rest, token, registration, toast],
	)
	const { fetchSurnameList, surnameList } = useSurnameListApi()

	const [surnameSearchText, setSurnameSearchText] = useState<string>("")

	useEffect(() => {
		fetchSurnameList({
			search: surnameSearchText,
			pagination: {
				page: 1,
				limit: 20,
			},
			subCasteId:
				registration?.basic?.surname?.subCaste?.id ??
				registration?.basic?.customSurname?.subCaste?.id,
		})
	}, [fetchSurnameList, surnameSearchText, registration])

	return (
		<RegistrationBasicUpdateDrawerFormView
			registration={registration}
			surnameList={surnameList}
			handleSubmit={handleSubmit}
			surnameSearchText={surnameSearchText}
			setSurnameSearchText={setSurnameSearchText}
			{...rest}
		/>
	)
}
