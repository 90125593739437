import { ComponentProps, FC, useCallback, useEffect } from "react"
import { discussionUpdateApi } from "src/domain/api/discussion/discussionUpdateApi"
import { Discussion } from "src/domain/entities/discussion"
import { useAuth, useDiscussionCategoryListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { DiscussionsUpdateDrawerFormView } from "./DiscussionsUpdateDrawerFormView"
import { IDiscussionsUpdateFormFields } from "./IDiscussionsUpdateFormFields"

type Props = Omit<
	ComponentProps<typeof DiscussionsUpdateDrawerFormView>,
	"handleSubmit" | "discussionCategoryList"
> & {
	discussion: Discussion
	onSuccess?: (discussion: Discussion) => void
}

export const DiscussionsUpdateDrawerFormController: FC<Props> = ({
	discussion,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IDiscussionsUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedDiscussion = await discussionUpdateApi(
					{
						id: discussion.id,
						update: {
							categoryId: values.categoryId,
							text: values.text,
						},
					},
					token,
				)
				onSuccess && onSuccess(updatedDiscussion)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ text: message })
			}
		},
		[onSuccess, rest, token, discussion.id],
	)

	const { fetchDiscussionCategoryList, discussionCategoryList } =
		useDiscussionCategoryListApi()

	useEffect(() => {
		fetchDiscussionCategoryList()
	}, [fetchDiscussionCategoryList])

	return (
		<DiscussionsUpdateDrawerFormView
			discussion={discussion}
			discussionCategoryList={discussionCategoryList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
