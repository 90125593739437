import { ComponentProps, FC, useCallback, useEffect } from "react"
import { occupationCategoryAddApi } from "src/domain/api/occupationCategory/occupationCategoryAddApi"
import { OccupationCategory } from "../../../../domain/entities/occupationCategory"
import { useAuth, useOccupationCategoryListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IOccupationCategoryAddFormFields } from "./IOccupationCategoryAddFormFields"
import { OccupationCategoryAddDrawerFormView } from "./OccupationCategoryAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof OccupationCategoryAddDrawerFormView>,
		"handleSubmit" | "parentOccupationCategoryList"
	> {
	onSuccess: (occupationCategory: OccupationCategory) => void
}

export const OccupationCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IOccupationCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const occupationCategory = await occupationCategoryAddApi(values, token)
				onSuccess(occupationCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchOccupationCategoryList, occupationCategoryList } =
		useOccupationCategoryListApi()

	useEffect(() => {
		fetchOccupationCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
			},
		})
	}, [fetchOccupationCategoryList])

	return (
		<OccupationCategoryAddDrawerFormView
			handleSubmit={handleSubmit}
			parentOccupationCategoryList={occupationCategoryList}
			{...rest}
		/>
	)
}
