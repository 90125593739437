import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsPlusCircleFill } from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { useEducationCategoryListApi } from "src/domain/hooks"
import { formatDate } from "src/utils/helpers"
import { EducationCategory } from "../../../../domain/entities/educationCategory"
import { EducationCategoryAddDrawerFormController } from "../EducationCategoryAddDrawerForm"

export const EducationCategoryListView: FC<{
	list: EducationCategory[]
	onUpdate: (educationCategory: EducationCategory) => void
	onDelete: (educationCategory: EducationCategory) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		{list.map((educationCategory) => (
			<EducationCategoryAccordionItem
				key={educationCategory.id}
				educationCategory={educationCategory}
				onUpdate={onUpdate}
				onDelete={onDelete}
			/>
		))}
	</>
)

const EducationCategoryAccordionItem = ({
	educationCategory,
	onUpdate,
	onDelete,
}: // refetch,
{
	educationCategory: EducationCategory
	onUpdate: (educationCategory: EducationCategory) => void
	onDelete: (educationCategory: EducationCategory) => void
	// refetch: () => void
}) => {
	const { educationCategoryList: childCategories, fetchEducationCategoryList } =
		useEducationCategoryListApi()

	const fetchChildCategories = async () => {
		await fetchEducationCategoryList({
			parentCategoryId: educationCategory.id,
			fetch: { childCategories: true, parentCategory: true },
		})
	}

	return (
		<Accordion allowMultiple>
			<AccordionItem border={"none"}>
				<AccordionButton
					_focus={{
						outline: "none",
					}}
					textAlign={"left"}
					justifyContent={"space-between"}
					onClick={() => {
						fetchChildCategories()
					}}
				>
					<Flex align={"center"} justify="space-between" w="full">
						<Flex>
							{educationCategory.name.en +
								" (" +
								educationCategory.name.gu +
								")"}
						</Flex>
						<Flex ml={"2"} align="center">
							<Text fontSize={"sm"} mr={4}>
								{formatDate(educationCategory.updatedAt)}
							</Text>
							<EditIconButton
								onClick={() => {
									onUpdate(educationCategory)
								}}
							/>
							<DeleteIconButton
								onClick={() => {
									onDelete(educationCategory)
								}}
							/>
						</Flex>
					</Flex>

					<AccordionIcon />
				</AccordionButton>
				<hr />
				<AccordionPanel>
					<Box>
						{childCategories?.length ? (
							<>
								<Box ml={6}>
									<AddEducationCategoryButton
										parentCategory={educationCategory}
										refetch={fetchChildCategories}
									/>
								</Box>
								{childCategories?.map((cC) => (
									<EducationCategoryAccordionItem
										key={cC.id}
										educationCategory={cC}
										onUpdate={onUpdate}
										onDelete={onDelete}
									/>
								))}
							</>
						) : (
							<Box ml={6}>
								<AddEducationCategoryButton
									parentCategory={educationCategory}
									refetch={fetchChildCategories}
								/>
							</Box>
						)}
					</Box>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

const AddEducationCategoryButton = ({
	parentCategory,
	refetch,
}: {
	parentCategory: EducationCategory
	refetch?: () => void
}) => {
	const addEducationCategoryDisclosure = useDisclosure()

	return (
		<>
			<Button
				leftIcon={<BsPlusCircleFill />}
				colorScheme="blue"
				variant="link"
				onClick={addEducationCategoryDisclosure.onOpen}
			>
				Add Child Category
			</Button>
			{addEducationCategoryDisclosure.isOpen ? (
				<EducationCategoryAddDrawerFormController
					initialValues={{
						name: {
							en: "",
							gu: "",
						},
						parentCategoryId: parentCategory?.id,
					}}
					onClose={addEducationCategoryDisclosure.onClose}
					isOpen={addEducationCategoryDisclosure.isOpen}
					onSuccess={() => refetch?.()}
				/>
			) : null}
		</>
	)
}
