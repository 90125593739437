import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsPlusCircleFill } from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { useOccupationCategoryListApi } from "src/domain/hooks"
import { formatDate } from "src/utils/helpers"
import { OccupationCategory } from "../../../../domain/entities/occupationCategory"
import { OccupationCategoryAddDrawerFormController } from "../OccupationCategoryAddDrawerForm"

export const OccupationCategoryListView: FC<{
	list: OccupationCategory[]
	onUpdate: (occupationCategory: OccupationCategory) => void
	onDelete: (occupationCategory: OccupationCategory) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		{list.map((occupationCategory) => (
			<OccupationCategoryAccordionItem
				key={occupationCategory.id}
				occupationCategory={occupationCategory}
				onUpdate={onUpdate}
				onDelete={onDelete}
			/>
		))}
	</>
)

const OccupationCategoryAccordionItem = ({
	occupationCategory,
	onUpdate,
	onDelete,
}: // refetch,
{
	occupationCategory: OccupationCategory
	onUpdate: (occupationCategory: OccupationCategory) => void
	onDelete: (occupationCategory: OccupationCategory) => void
	// refetch: () => void
}) => {
	const { occupationCategoryList: childCategories, fetchOccupationCategoryList } =
		useOccupationCategoryListApi()

	const fetchChildCategories = async () => {
		await fetchOccupationCategoryList({
			parentCategoryId: occupationCategory.id,
			fetch: { childCategories: true, parentCategory: true },
		})
	}

	return (
		<Accordion allowMultiple>
			<AccordionItem border={"none"}>
				<AccordionButton
					_focus={{
						outline: "none",
					}}
					textAlign={"left"}
					justifyContent={"space-between"}
					onClick={() => {
						fetchChildCategories()
					}}
				>
					<Flex align={"center"} justify="space-between" w="full">
						<Box>
							{occupationCategory.name.en +
								" (" +
								occupationCategory.name.gu +
								")"}
						</Box>
						<Flex ml={"2"} align="center">
							<Text fontSize={"sm"} mr={4}>
								{formatDate(occupationCategory.updatedAt)}
							</Text>
							<EditIconButton
								onClick={() => {
									onUpdate(occupationCategory)
								}}
							/>
							<DeleteIconButton
								onClick={() => {
									onDelete(occupationCategory)
								}}
							/>
						</Flex>
					</Flex>

					<AccordionIcon />
				</AccordionButton>
				<AccordionPanel>
					<Box>
						{childCategories?.length ? (
							<>
								<Box ml={6}>
									<AddOccupationCategoryButton
										parentCategory={occupationCategory}
										refetch={fetchChildCategories}
									/>
								</Box>
								{childCategories?.map((cC) => (
									<OccupationCategoryAccordionItem
										key={cC.id}
										occupationCategory={cC}
										onUpdate={onUpdate}
										onDelete={onDelete}
									/>
								))}
							</>
						) : (
							<Box ml={6}>
								<AddOccupationCategoryButton
									parentCategory={occupationCategory}
									refetch={fetchChildCategories}
								/>
							</Box>
						)}
					</Box>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

const AddOccupationCategoryButton = ({
	parentCategory,
	refetch,
}: {
	parentCategory: OccupationCategory
	refetch?: () => void
}) => {
	const addOccupationCategoryDisclosure = useDisclosure()

	return (
		<>
			<Button
				leftIcon={<BsPlusCircleFill />}
				colorScheme="blue"
				variant="link"
				onClick={addOccupationCategoryDisclosure.onOpen}
			>
				Add Child Category
			</Button>
			{addOccupationCategoryDisclosure.isOpen ? (
				<OccupationCategoryAddDrawerFormController
					initialValues={{
						name: {
							en: "",
							gu: "",
						},
						parentCategoryId: parentCategory?.id,
					}}
					onClose={addOccupationCategoryDisclosure.onClose}
					isOpen={addOccupationCategoryDisclosure.isOpen}
					onSuccess={() => refetch?.()}
				/>
			) : null}
		</>
	)
}
