import { MultiLangText } from "src/utils/types"
import { OccupationCategory } from "../../entities/occupationCategory"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	parentCategoryId?: string
	update: {
		parentCategoryId?: string
		name?: MultiLangText
	}
}

export async function occupationCategoryUpdateApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/occupationCategory/update/v1"
	const requester = new ApiRequester<RequestDataShape, OccupationCategory>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
