import { RequestPagination } from "src/utils/types"
import { Country } from "../../entities/country"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	isApproved?: boolean
	search?: string
	pagination?: RequestPagination
}

export async function countryListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/country/list/v1"
	const requester = new ApiRequester<RequestDataShape, Country[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
