import {
	Box,
	Button,
	Flex,
	IconButton,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import moment from "moment"
import React, { FC, useCallback, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import {
	BsChevronDown,
	BsHeartFill,
	BsImage,
	BsPatchCheckFill,
	BsPerson,
	BsPinMap,
	BsX,
} from "react-icons/bs"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"
import { profileFamilyGetApi } from "src/domain/api/profileFamily/profileFamilyGetApi"
import { Profile } from "src/domain/entities/profile"
import { ProfileFamily } from "src/domain/entities/profileFamily"
import { useAuth, useMatrimonyProfileListApi, useProfileListApi } from "src/domain/hooks"
import { getMaritalStatusName, Language } from "src/utils/enums"
import { formatDate, getFullName, getVillageName } from "src/utils/helpers"
import { MatrimonyProfileDetailsModal } from "../MatrimonyProfilesPage/MatrimonyProfileDetailsModal"
import { ProfileBasicUpdateDrawerFormController } from "./ProfileBasicUpdateDrawerForm"
import { ProfileImageUpdateDrawerFormController } from "./ProfileImageUpdateDrawerForm"
import { ProfileLocationUpdateDrawerFormController } from "./ProfileLocationUpdateDrawerForm"

interface Props {
	profileId: string
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	handleProfileClick: (profileId: string) => void
}

export const ProfileDetailsModal: FC<Props> = ({
	isOpen,
	setIsOpen,
	profileId,
	handleProfileClick,
}) => {
	const { fetchProfileList, isLoading, profileList } = useProfileListApi()
	const { fetchMatrimonyProfileList, matrimonyProfileList } =
		useMatrimonyProfileListApi()
	const { token } = useAuth()

	const profileBasicUpdateDrawerDisclosure = useDisclosure()
	const profileImageUpdateDrawerDisclosure = useDisclosure()
	const profileLocationUpdateDrawerDisclosure = useDisclosure()
	const [selectedViewImageUrl, setSelectedViewImageUrl] = useState<string | null>(null)

	const [isMatrimonyProfileDetailsModalOpen, setIsMatrimonyProfileDetailsModalOpen] =
		useState(false)

	const handleEditButtonClick = async (type: "basic" | "image" | "location") => {
		if (type === "basic") {
			profileBasicUpdateDrawerDisclosure.onOpen()
		} else if (type === "image") {
			profileImageUpdateDrawerDisclosure.onOpen()
		} else if (type === "location") {
			profileLocationUpdateDrawerDisclosure.onOpen()
		}
	}

	const [profileFamilyLoading, setProfileFamilyLoading] = useState(false)
	const [profileFamily, setProfileFamily] = useState<ProfileFamily | null>(null)

	const fetchProfileDetails = useCallback(async () => {
		try {
			await fetchProfileList({
				id: profileId,
				fetch: {
					surname: {
						subCaste: true,
					},
					nativeVillage: {
						district: true,
						taluka: true,
					},
					currentVillage: {
						country: true,
						state: true,
						district: true,
						taluka: true,
					},
					mobile: true,
					acquiredBy: {},
				},
			})
		} catch (err) {
			console.error(err)
		}
	}, [fetchProfileList, profileId])

	const fetchProfileFamilyDetails = useCallback(async () => {
		try {
			setProfileFamilyLoading(true)
			var response = await profileFamilyGetApi(
				{
					profileId,
					father: true,
					mother: true,
					spouse: true,
					children: true,
					siblings: true,
					fetch: {
						surname: {},
					},
				},
				token,
			)

			setProfileFamily(response)
		} catch (err) {
			console.error(err)
		} finally {
			setProfileFamilyLoading(false)
		}
	}, [profileId, token])

	useEffect(() => {
		fetchProfileDetails()
	}, [fetchProfileDetails])

	useEffect(() => {
		fetchProfileFamilyDetails()
	}, [fetchProfileFamilyDetails])

	useEffect(() => {
		fetchMatrimonyProfileList({ profileId })
	}, [fetchMatrimonyProfileList, profileId])

	const handleHeartButtonClick = () => {
		setIsMatrimonyProfileDetailsModalOpen(true)
	}

	const profileDetails = profileList[0]

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<ModalOverlay />
				<ModalContent rounded={"2xl"} overflow="hidden">
					<ModalBody p="0">
						<Box
							backgroundColor={"gray.800"}
							p={{ base: "3", lg: "8" }}
							pb={{ base: "0", lg: "2" }}
						>
							<Flex>
								<Box position={"relative"}>
									<Flex
										borderRadius={"full"}
										overflow="hidden"
										w="100px"
										h="100px"
										flex={"none"}
										bgColor="gray.100"
										align={"center"}
										justify={"center"}
									>
										{isLoading ? (
											<AiOutlineLoading3Quarters />
										) : profileDetails?.profileImageUrl ? (
											<Image
												src={profileDetails?.profileImageUrl}
												alt="Profile Picture"
												objectFit="cover"
												onClick={() =>
													setSelectedViewImageUrl(
														profileDetails.profileImageUrl!,
													)
												}
											/>
										) : (
											<BsPerson size={20} />
										)}
									</Flex>
									{matrimonyProfileList.length ? (
										<Box
											position={"absolute"}
											color="red.500"
											top={0}
											right={0}
										>
											<IconButton
												aria-label="heart"
												icon={<BsHeartFill size={14} />}
												rounded={"full"}
												backgroundColor="red.100"
												_hover={{ backgroundColor: "red.200" }}
												size={"sm"}
												onClick={handleHeartButtonClick}
											/>
										</Box>
									) : null}
								</Box>
								<Box flex={1} ml="6">
									<Box
										color={"white"}
										fontSize="2xl"
										lineHeight={"1.3"}
										fontWeight="bold"
									>
										<Text as={"span"}>
											{getFullName(profileDetails)}
										</Text>
										{profileDetails?.isVerified ? (
											<Text
												display={"inline-block"}
												ml={2}
												color="blue.400"
											>
												<BsPatchCheckFill size={16} />
											</Text>
										) : null}
									</Box>
									<Flex align={"center"} columnGap="2" mt="2">
										<Box
											color={"gray.300"}
											fontSize="sm"
											textTransform={"capitalize"}
										>
											{profileDetails?.gender}
										</Box>
										{profileDetails?.dateOfBirth ? (
											<>
												<Box
													backgroundColor={"gray.300"}
													w="5px"
													h="5px"
													borderRadius={"full"}
												></Box>
												<Box
													color={"gray.300"}
													fontSize="sm"
													textTransform={"capitalize"}
												>
													{moment(
														profileDetails?.dateOfBirth,
													).format("DD MMM YYYY")}
												</Box>
											</>
										) : null}
										{profileDetails?.mobile?.mobileNo ? (
											<>
												<Box
													backgroundColor={"gray.300"}
													w="5px"
													h="5px"
													borderRadius={"full"}
												></Box>
												<Box color={"gray.300"} fontSize="sm">
													{profileDetails?.mobile?.mobileNo}
												</Box>
											</>
										) : null}
									</Flex>
									<Flex align={"center"} columnGap="2" mt="2">
										<Box
											color={"gray.300"}
											fontSize="sm"
											textTransform={"capitalize"}
										>
											{profileDetails?.code}
										</Box>
										{profileDetails?.acquiredBy?.lang ? (
											<>
												<Box
													backgroundColor={"gray.300"}
													w="5px"
													h="5px"
													borderRadius={"full"}
												></Box>
												<Box
													color={
														profileDetails?.acquiredBy
															?.lang === Language.GUJARATI
															? "orange.300"
															: "blue.300"
													}
													fontSize="sm"
													textTransform={"uppercase"}
													fontWeight="semibold"
												>
													{profileDetails?.acquiredBy?.lang}
												</Box>
											</>
										) : null}
									</Flex>
								</Box>
								<Box display={{ base: "block", lg: "none" }}>
									<Button
										bgColor={"transparent"}
										_hover={{
											bgColor: "gray.700",
										}}
										padding={2}
										onClick={() => setIsOpen(false)}
									>
										<BsX size={30} color={"white"} />
									</Button>
								</Box>
							</Flex>
							{/* <Flex justify={"flex-end"}>
							<Box color={"white"}>Edit</Box>
						</Flex> */}
						</Box>
						<Flex justify={"space-between"}>
							<Flex
								m={{ base: "3", lg: "4" }}
								gap={{ base: "4", lg: "6" }}
								direction={"column"}
							>
								<Flex direction={"column"}>
									<Box color={"gray.400"} fontWeight="semibold">
										INFO
									</Box>
									<Flex gap="2" direction={"column"}>
										<Box>
											<Box color={"gray.700"} fontSize="sm">
												ID
											</Box>
											<Box
												color={"gray.900"}
												textTransform={"capitalize"}
											>
												{profileDetails?.id}
											</Box>
										</Box>
										<Box>
											<Box color={"gray.700"} fontSize="sm">
												Code
											</Box>
											<Box
												color={"gray.900"}
												textTransform={"capitalize"}
											>
												{profileDetails?.code}
											</Box>
										</Box>
										<Box>
											<Box color={"gray.700"} fontSize="sm">
												Created At
											</Box>
											<Box>
												{formatDate(profileDetails?.createdAt, {
													includeTime: true,
												})}
											</Box>
										</Box>
									</Flex>
								</Flex>
								<Flex direction={"column"}>
									<Box color={"gray.400"} fontWeight="semibold">
										BASIC
									</Box>
									<Flex gap="2" direction={"column"}>
										<Flex>
											<Box flex={1}>
												<Box color={"gray.700"} fontSize="sm">
													Date of Birth
												</Box>
												<Box
													color={"gray.900"}
													textTransform={"capitalize"}
												>
													{profileDetails?.dateOfBirth
														? moment(
																profileDetails?.dateOfBirth,
														  ).format("DD MMM YYYY")
														: "-"}
												</Box>
											</Box>
											<Box flex={1}>
												<Box color={"gray.700"} fontSize="sm">
													Gender
												</Box>
												<Box
													color={"gray.900"}
													textTransform={"capitalize"}
												>
													{profileDetails?.gender}
												</Box>
											</Box>
										</Flex>
										<Box>
											<Box color={"gray.700"} fontSize="sm">
												Marital Status
											</Box>
											<Box
												color={"gray.900"}
												textTransform={"capitalize"}
											>
												{getMaritalStatusName(
													profileDetails?.maritalStatus,
												)}
											</Box>
										</Box>
									</Flex>
								</Flex>
								<Flex direction={"column"}>
									<Box color={"gray.400"} fontWeight="semibold">
										LOCATION
									</Box>
									<Flex gap="2" direction={"column"}>
										<Box>
											<Box color={"gray.700"} fontSize="sm">
												Native village
											</Box>
											<Box
												color={"gray.900"}
												textTransform={"capitalize"}
											>
												{getVillageName(
													profileDetails?.nativeVillage,
												)}
											</Box>
										</Box>
										<Box>
											<Box color={"gray.700"} fontSize="sm">
												Current city/village
											</Box>
											<Box
												color={"gray.900"}
												textTransform={"capitalize"}
											>
												{getVillageName(
													profileDetails?.currentVillage,
												)}
											</Box>
										</Box>
									</Flex>
								</Flex>
								{profileFamily ? (
									<Flex direction={"column"}>
										<Box color={"gray.400"} fontWeight="semibold">
											FAMILY
										</Box>
										{profileFamilyLoading ? (
											<AiOutlineLoading3Quarters />
										) : (
											<Flex direction={"column"}>
												<ProfileFamilyListItem
													profile={profileFamily.mother}
													relation="Mother"
													handleProfileClick={
														handleProfileClick
													}
												/>
												<ProfileFamilyListItem
													profile={profileFamily.father}
													relation="Father"
													handleProfileClick={
														handleProfileClick
													}
												/>
												<ProfileFamilyListItem
													profile={
														profileFamily.spouse?.profile
													}
													relation="Spouse"
													handleProfileClick={
														handleProfileClick
													}
												/>
												{profileFamily.children
													?.sort(
														(a, b) =>
															a.gender.length -
															b.gender.length,
													)
													.map((child) => (
														<ProfileFamilyListItem
															profile={child}
															relation="Child"
															handleProfileClick={
																handleProfileClick
															}
														/>
													))}
												{profileFamily.siblings
													?.sort(
														(a, b) =>
															a.gender.length -
															b.gender.length,
													)
													.map((sibling) => (
														<ProfileFamilyListItem
															profile={sibling}
															relation="Sibling"
															handleProfileClick={
																handleProfileClick
															}
														/>
													))}
											</Flex>
										)}
									</Flex>
								) : null}
							</Flex>
							<Box mt={"3"}>
								<Menu>
									<MenuButton
										as={Button}
										aria-label="edit"
										variant="ghost"
										size={"sm"}
										mx="1"
										colorScheme={"yellow"}
										onClick={(e) => {
											e.stopPropagation()
										}}
										rightIcon={<BsChevronDown />}
									>
										Edit
									</MenuButton>
									<MenuList>
										<MenuItem
											onClick={(e) => {
												e.stopPropagation()
												handleEditButtonClick("basic")
											}}
											icon={<BsPerson />}
										>
											Basic details
										</MenuItem>
										<MenuItem
											onClick={(e) => {
												e.stopPropagation()
												handleEditButtonClick("image")
											}}
											icon={<BsImage />}
										>
											Profile Image
										</MenuItem>
										<MenuItem
											onClick={(e) => {
												e.stopPropagation()
												handleEditButtonClick("location")
											}}
											icon={<BsPinMap />}
										>
											Location details
										</MenuItem>
									</MenuList>
								</Menu>
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
				{profileBasicUpdateDrawerDisclosure.isOpen ? (
					<ProfileBasicUpdateDrawerFormController
						{...profileBasicUpdateDrawerDisclosure}
						profile={profileDetails}
						onSuccess={() => fetchProfileDetails()}
					/>
				) : null}
				{profileImageUpdateDrawerDisclosure.isOpen ? (
					<ProfileImageUpdateDrawerFormController
						{...profileImageUpdateDrawerDisclosure}
						profile={profileDetails}
						onSuccess={() => fetchProfileDetails()}
					/>
				) : null}
				{profileLocationUpdateDrawerDisclosure.isOpen ? (
					<ProfileLocationUpdateDrawerFormController
						{...profileLocationUpdateDrawerDisclosure}
						profile={profileDetails}
						onSuccess={() => fetchProfileDetails()}
					/>
				) : null}
				{isMatrimonyProfileDetailsModalOpen && matrimonyProfileList.length ? (
					<MatrimonyProfileDetailsModal
						matrimonyProfileId={matrimonyProfileList[0].id}
						handleProfileClick={handleProfileClick}
						isOpen={isMatrimonyProfileDetailsModalOpen}
						setIsOpen={setIsMatrimonyProfileDetailsModalOpen}
					/>
				) : null}
			</Modal>
			{selectedViewImageUrl != null ? (
				<ImageViewModalController
					isOpen={selectedViewImageUrl != null}
					setIsOpen={() => setSelectedViewImageUrl(null)}
					image={
						<Image
							src={selectedViewImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
		</>
	)
}

const ProfileFamilyListItem: React.FC<{
	profile?: Profile
	relation: "Mother" | "Father" | "Sibling" | "Spouse" | "Child"
	handleProfileClick?: (profileId: string) => void
}> = ({ profile, relation, handleProfileClick }) => {
	if (!profile) return null

	return (
		<>
			<Flex
				_hover={{
					backgroundColor: "gray.100",
				}}
				cursor="pointer"
				p="2"
				onClick={() => handleProfileClick?.(profile.id)}
			>
				<Box>
					{profile?.profileImageUrl ? (
						<Image
							style={{ aspectRatio: "1" }}
							src={profile?.profileImageUrl}
							height="30px"
							rounded={"md"}
						/>
					) : (
						<Flex
							height={30}
							width={30}
							bgColor="gray.100"
							rounded={"lg"}
							justify="center"
							align={"center"}
							color="gray.500"
						>
							<BsPerson size={20} />
						</Flex>
					)}
				</Box>
				<Box flex={1} ml="2">
					<Box lineHeight={1}>
						<Text as={"span"}>{getFullName(profile)}</Text>
						{profile.isVerified ? (
							<Text display={"inline-block"} ml={2} color="blue.400">
								<BsPatchCheckFill size={16} />
							</Text>
						) : null}
					</Box>
					<Box fontSize={"xs"} color="gray.500">
						{relation}
					</Box>
				</Box>
			</Flex>
		</>
	)
}
