import { Discussion } from "src/domain/entities/discussion"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		text?: string
		categoryId?: string
	}
}

export async function discussionUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/discussion/update/v1"
	const requester = new ApiRequester<RequestDataShape, Discussion>(endpoint)

	const response = await requester.sendRequest(payload, token)
	return response
}
