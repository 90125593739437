import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { organizationAddApi } from "src/domain/api/organization/organizationAddApi"
import { File } from "src/domain/entities/file"
import { Organization } from "../../../../domain/entities/organization"
import {
	useAuth,
	useFacilityListApi,
	useSubCasteListApi,
	useVillageListApi,
} from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IOrganizationAddFormFields } from "./IOrganizationAddFormFields"
import { OrganizationAddDrawerFormView } from "./OrganizationAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof OrganizationAddDrawerFormView>,
		| "handleSubmit"
		| "villageList"
		| "setVillageSearchText"
		| "handleUploadImage"
		| "selectedImage"
		| "setSelectedImage"
		| "subCasteList"
		| "facilityList"
	> {
	onSuccess: (organization: Organization) => void
}

export const OrganizationAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IOrganizationAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const organization = await organizationAddApi(values, token)
				onSuccess(organization)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)

			return response as File
		},
		[token],
	)

	const { fetchSubCasteList, subCasteList } = useSubCasteListApi()

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const { fetchFacilityList, facilityList } = useFacilityListApi()

	useEffect(() => {
		fetchFacilityList()
	}, [fetchFacilityList])

	const { fetchVillageList, villageList } = useVillageListApi()
	const [villageSearchText, setVillageSearchText] = useState("")
	useEffect(() => {
		fetchVillageList({
			search: villageSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {
				talukaDistrict: true,
			},
		})
	}, [fetchVillageList, villageSearchText])

	return (
		<OrganizationAddDrawerFormView
			handleSubmit={handleSubmit}
			villageList={villageList}
			setVillageSearchText={setVillageSearchText}
			selectedImage={selectedImage}
			setSelectedImage={setSelectedImage}
			handleUploadImage={handleUploadImage}
			subCasteList={subCasteList}
			facilityList={facilityList}
			{...rest}
		/>
	)
}
