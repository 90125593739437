import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import ReactSelect from "react-select"
import { reviewerRegistrationsLinkApi } from "src/domain/api/reviewer/reviewerRegistrationsLinkApi"
import { Registration } from "src/domain/entities/registration"
import { User } from "src/domain/entities/user"
import { useAuth, useUserListApi } from "src/domain/hooks"
import { getErrorMessage } from "src/utils/helpers"

interface Props {
	registrations: Registration[]
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const AssignReviewerModalController: FC<Props> = ({
	isOpen,
	setIsOpen,
	registrations,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [selectedReviewer, setSelectedReviewer] = useState<User | null>(null)

	const { userList, fetchUserList, isLoading: isUserListLoading } = useUserListApi()

	const handleAssignReviewer = useCallback(async () => {
		try {
			setIsLoading(true)
			if (!selectedReviewer) return

			await reviewerRegistrationsLinkApi(
				{
					registrationIds: registrations.map((el) => el.id),
					reviewerId: selectedReviewer.id,
				},
				token,
			)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [registrations, setIsOpen, token, onSuccess, selectedReviewer])

	useEffect(() => {
		fetchUserList({
			isReviewer: true,
			fetch: { profile: true, registration: true, registrationDetails: true },
		})
	}, [fetchUserList])

	const userListOptions = userList
		.filter((u) => u.registration || u.profile)
		.map((user) => ({
			label:
				(user.profile?.firstName.en ??
					user.registration?.basic?.firstName.en ??
					"") +
					" " +
					(user.profile?.surname?.name.en ??
						user.registration?.basic?.surname?.name.en) ?? "-",
			value: user,
		}))

	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Assign Reviewer</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<ReactSelect
						options={userListOptions}
						onChange={(option) => {
							if (!option?.value) return
							setSelectedReviewer(option?.value)
						}}
						isLoading={isUserListLoading}
					/>

					{errorMessage}
				</ModalBody>
				<ModalFooter>
					<Flex gridColumnGap={2}>
						<Button onClick={() => setIsOpen(false)}>Close</Button>

						<Button
							colorScheme={"blue"}
							isLoading={isLoading}
							onClick={handleAssignReviewer}
						>
							Save
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
