import { DiscussionCategory } from "../../entities/discussionCategory"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function discussionCategoryListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/discussion/category/list/v1"
	const requester = new ApiRequester<RequestDataShape, DiscussionCategory[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
