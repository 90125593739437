import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	registrationIds: string[]
	reviewerId: string
}

export async function reviewerRegistrationsLinkApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/reviewer/registrations/link/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
