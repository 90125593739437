import { Unit } from "src/domain/entities/unit"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	position: number
}

export async function unitAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/unit/add/v1"
	const requester = new ApiRequester<RequestDataShape, Unit>(endpoint)
	return await requester.sendRequest(payload, token)
}
