import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"

import { Facility } from "../../../domain/entities/facility"
import { useFacilityListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { FacilityAddDrawerFormController } from "./FacilityAddDrawerForm"
import { FacilityDeleteDialogController } from "./FacilityDeleteDialogController"
import { FacilityListController } from "./FacilityList"
import { FacilityUpdateDrawerFormController } from "./FacilityUpdateDrawerForm"

export const FacilitiesPage: FC = () => {
	const { facilityList, isLoading, fetchFacilityList } = useFacilityListApi()
	const [isFacilityDeleteDialogOpen, setIsFacilityDeleteDialogOpen] = useState(false)
	const facilityAddDrawerDisclosure = useDisclosure()
	const facilityUpdateDrawerDisclosure = useDisclosure()

	const fetchFacilities = useCallback(async () => {
		return await fetchFacilityList({})
	}, [fetchFacilityList])

	useEffect(() => {
		fetchFacilities()
	}, [fetchFacilities])

	const updateFacilityRef = useRef<Facility>()
	const deleteFacilityRef = useRef<Facility>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Facilities
					</Text>
					<Button
						size="sm"
						onClick={facilityAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Facility
					</Button>
				</Flex>
			</Box>
			<FacilityListController
				list={facilityList}
				isLoading={isLoading}
				onUpdate={(facility: Facility) => {
					updateFacilityRef.current = facility
					facilityUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(facility: Facility) => {
					deleteFacilityRef.current = facility
					setIsFacilityDeleteDialogOpen(true)
				}}
				onAddClick={facilityAddDrawerDisclosure.onOpen}
			/>
			{facilityAddDrawerDisclosure.isOpen ? (
				<FacilityAddDrawerFormController
					{...facilityAddDrawerDisclosure}
					onSuccess={() => fetchFacilityList()}
				/>
			) : null}
			{updateFacilityRef.current && facilityUpdateDrawerDisclosure.isOpen ? (
				<FacilityUpdateDrawerFormController
					{...facilityUpdateDrawerDisclosure}
					facility={updateFacilityRef.current}
					onSuccess={() => fetchFacilityList()}
				/>
			) : null}
			{deleteFacilityRef.current && isFacilityDeleteDialogOpen ? (
				<FacilityDeleteDialogController
					isOpen={isFacilityDeleteDialogOpen}
					setIsOpen={setIsFacilityDeleteDialogOpen}
					facility={deleteFacilityRef.current}
					onSuccess={() => fetchFacilityList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
