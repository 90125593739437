import {
	Box,
	Flex,
	Image,
	Switch,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsPatchCheckFill, BsPerson } from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { MatrimonyProfile } from "../../../../domain/entities/matrimonyProfile"

export const MatrimonyProfileListView: FC<{
	list: MatrimonyProfile[]
	onMatrimonyProfileClick: (matrimonyProfileId: string) => void
	handleMatrimonyProfileVerificationChange: (
		val: boolean,
		matrimonyProfile: MatrimonyProfile,
	) => Promise<void>
	handleMatrimonyProfileActiveChange: (
		val: boolean,
		matrimonyProfile: MatrimonyProfile,
	) => Promise<void>
	onDelete: (matrimonyProfile: MatrimonyProfile) => void
}> = ({
	list,
	onMatrimonyProfileClick,
	handleMatrimonyProfileVerificationChange,
	handleMatrimonyProfileActiveChange,
	onDelete,
}) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Avatar</Th>
				<Th>Name</Th>
				<Th>Age</Th>
				<Th>Is Verified?</Th>
				<Th>Is Active?</Th>
				<Th>Created At</Th>
				<Th>Updated At</Th>
				<Th>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((matrimonyProfile, i) => (
				<Tr
					key={i}
					style={{ cursor: "pointer" }}
					_hover={{ bg: "gray.100" }}
					onClick={() => onMatrimonyProfileClick(matrimonyProfile.id)}
				>
					<Td>
						{matrimonyProfile?.featuredImageUrl ? (
							<Image
								style={{ aspectRatio: "1" }}
								src={matrimonyProfile?.featuredImageUrl}
								height="50px"
								rounded={"lg"}
							/>
						) : (
							<Flex
								height={50}
								width={50}
								bgColor="gray.100"
								rounded={"lg"}
								justify="center"
								align={"center"}
								color="gray.500"
							>
								<BsPerson size={20} />
							</Flex>
						)}
					</Td>

					<Td>
						<Text fontWeight={500}>
							<Flex align={"center"}>
								<Text>
									{matrimonyProfile?.profile?.firstName?.en}{" "}
									{matrimonyProfile?.profile?.middleName?.en}{" "}
									{matrimonyProfile?.profile?.surname
										? matrimonyProfile?.profile.surname?.name.en
										: null}
								</Text>
								{matrimonyProfile.isVerified ? (
									<Text ml={2} color="blue.400">
										<BsPatchCheckFill size={16} />
									</Text>
								) : null}
							</Flex>
						</Text>
						<Text>
							{matrimonyProfile?.profile?.firstName?.gu}{" "}
							{matrimonyProfile?.profile?.middleName?.gu}{" "}
							{matrimonyProfile?.profile?.surname
								? matrimonyProfile?.profile?.surname?.name.gu
								: null}
						</Text>
					</Td>
					<Td>
						<Text fontWeight={"medium"}>
							{Math.floor(
								(new Date().getTime() -
									new Date(
										matrimonyProfile?.profile?.dateOfBirth ?? 0,
									).getTime()) /
									(1000 * 3600 * 24 * 365),
							)}{" "}
							yrs
						</Text>
					</Td>

					<Td onClick={(e) => e.stopPropagation()}>
						<Switch
							isChecked={matrimonyProfile.isVerified}
							onChange={(val) => {
								handleMatrimonyProfileVerificationChange(
									val.target.checked,
									matrimonyProfile,
								)
							}}
						/>
					</Td>
					<Td onClick={(e) => e.stopPropagation()}>
						<Switch
							isChecked={matrimonyProfile.isActive}
							onChange={(val) => {
								handleMatrimonyProfileActiveChange(
									val.target.checked,
									matrimonyProfile,
								)
							}}
						/>
					</Td>
					<Td>
						{matrimonyProfile.createdAt ? (
							<Text fontWeight={"medium"}>
								{new Date(
									matrimonyProfile.createdAt,
								).toLocaleDateString()}
							</Text>
						) : null}
					</Td>
					<Td>
						{matrimonyProfile.updatedAt ? (
							<Text fontWeight={"medium"}>
								{new Date(
									matrimonyProfile.updatedAt,
								).toLocaleDateString()}
							</Text>
						) : null}
					</Td>
					<Box ml={"5"} mt={"5"}>
						<DeleteIconButton onClick={() => onDelete(matrimonyProfile)} />
					</Box>
				</Tr>
			))}
		</Tbody>
	</Table>
)
