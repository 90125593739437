import { FC, useState } from "react"
import { Discussion } from "src/domain/entities/discussion"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { ProfileDetailsModal } from "../../ProfilesPage/ProfileDetailsModal"
import { DiscussionDetailsModal } from "../DiscussionDetailsModal"
import { DiscussionListView } from "./DiscussionsListView"

interface Props {
	list: Discussion[]
	isLoading: boolean
	onDelete: (discussion: Discussion) => void
	onNotify: (discussion: Discussion) => void
	onUpdate: (discussion: Discussion) => void
}

export const DiscussionsListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onNotify,
	onUpdate,
}) => {
	const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)

	const [selectedDiscussionId, setSelectedDiscussionId] = useState<string | null>(null)

	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Discussion found." />
	}

	const handleProfileClick = (profileId: string) => {
		setSelectedProfileId(profileId)
	}

	const handleDiscussionClick = (discussionId: string) => {
		setSelectedDiscussionId(discussionId)
	}

	return (
		<>
			<DiscussionListView
				list={list}
				onDelete={onDelete}
				onNotify={onNotify}
				onUpdate={onUpdate}
				onProfileClick={handleProfileClick}
				onDiscussionClick={handleDiscussionClick}
			/>
			{selectedProfileId ? (
				<ProfileDetailsModal
					isOpen={!!selectedProfileId}
					setIsOpen={(val) => {
						if (!val) setSelectedProfileId(null)
					}}
					profileId={selectedProfileId}
					handleProfileClick={handleProfileClick}
				/>
			) : null}
			{selectedDiscussionId ? (
				<DiscussionDetailsModal
					isOpen={!!selectedDiscussionId}
					setIsOpen={(val) => {
						if (!val) {
							setSelectedDiscussionId(null)
						}
					}}
					discussionId={selectedDiscussionId}
					onProfileClick={() => {}}
				/>
			) : null}
		</>
	)
}
