import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	businessId: string
	isVerified: boolean
}

export async function businessVerifyApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/business/verify/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
