import moment from "moment"
import { ComponentProps, FC, useCallback } from "react"
import { iosBuildUpdateApi } from "src/domain/api/iosBuild/iosBuildUpdateApi"
import { IosBuild } from "../../../../domain/entities/iosBuild"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IIosBuildUpdateFormFields } from "./IIosBuildUpdateFormFields"
import { IosBuildUpdateDrawerFormView } from "./IosBuildUpdateDrawerFormView"

type Props = Omit<ComponentProps<typeof IosBuildUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (iosBuild: IosBuild) => void
}

export const IosBuildUpdateDrawerFormController: FC<Props> = ({
	iosBuild,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IIosBuildUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (values.publishDate) {
					values.publishDate = moment(values.publishDate).unix() * 1000
				}
				const updatedIosBuild = await iosBuildUpdateApi(
					{ id: iosBuild.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedIosBuild)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ version: message })
			}
		},
		[onSuccess, rest, token, iosBuild.id],
	)

	return (
		<IosBuildUpdateDrawerFormView
			iosBuild={iosBuild}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
