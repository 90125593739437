import { Business } from "src/domain/entities/business"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	logoImageId?: string
	description?: string
	imageIds?: string[]
	currentVillageId: string
	locationCoordinate?: {
		longitude: number
		latitude: number
	}
	servingLocationIds?: string[]
	type: "product" | "service" | "both"
	categoryId: string
	registrationType?:
		| "unregistered"
		| "one_person_company"
		| "sole_proprietorship_firm"
		| "partnership_firm"
		| "limited_liability_partnership_firm"
		| "private_limited_company"
		| "public_limited_company"
	mobileNumber1?: string
	mobileNumber2?: string
	email?: string
	website?: string
	address: string
	createdById?: string
}

export async function businessAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/business/add/v1"
	const requester = new ApiRequester<RequestDataShape, Business>(endpoint)

	if (!payload.email) delete payload.email
	if (!payload.website) delete payload.website
	if (!payload.mobileNumber1) delete payload.mobileNumber1
	if (!payload.mobileNumber2) delete payload.mobileNumber2
	if (!payload.description) delete payload.description
	if (!payload.logoImageId) delete payload.logoImageId
	if (!payload.registrationType) delete payload.registrationType

	const response = await requester.sendRequest(payload, token)
	return response
}
