import {
	Box,
	Flex,
	FormControl,
	Image,
	Input,
	Stack,
	Tag,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { DrawerForm, ErrorMessageField } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { Business } from "src/domain/entities/business"
import { File } from "src/domain/entities/file"
import { Village } from "src/domain/entities/village"
import {
	BusinessRegistrationType,
	BusinessType,
	getBusinessRegistrationType,
	getBusinessType,
} from "src/utils/enums"
import { fetchGujaratiSuggestions, getVillageName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IBusinessUpdateFormFields } from "./IBusinessUpdateFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	business: Business
	handleSubmit: FormikOnSubmit<IBusinessUpdateFormFields>
	villageList: Village[]
	setVillageSearchText: (text: string) => void
	handleUploadImage: (file: any) => Promise<File>
	selectedImage?: File
	setSelectedImage: (file?: File) => void
}

export const BusinessUpdateDrawerFormView: FC<Props> = ({
	business,
	handleSubmit,
	villageList,
	setVillageSearchText,
	handleUploadImage,
	selectedImage,
	setSelectedImage,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IBusinessUpdateFormFields>
			initialValues={{
				name: {
					en: business.name.en,
					gu: business.name.gu,
				},
				logoImageId: selectedImage?.id ?? business.logoImage?.id,
				mobileNumber1: business.mobileNumber1,
				mobileNumber2: business.mobileNumber2,
				currentVillageId: business.currentVillage?.id,
				description: business.description,
				registrationType: business.registrationType,
				type: business.type,
				email: business.email,
				website: business.website,
				address: business.address,
			}}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const villageOptions = villageList.map((village) => ({
					value: village.id,
					label: getVillageName(village),
				}))

				const businessTypeOptions: {
					label: string
					value?: BusinessType
				}[] = Object.values(BusinessType).map((type) => ({
					label: getBusinessType(type),
					value: type,
				}))

				const businessRegistrationTypeOptions: {
					label: string
					value?: BusinessRegistrationType
				}[] = Object.values(BusinessRegistrationType).map((type) => ({
					label: getBusinessRegistrationType(type),
					value: type,
				}))

				return (
					<DrawerForm
						size="md"
						headerLabel="Update Business"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"md"} marginX={"auto"} rowGap={2}>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={100}
											required
											autoFocus
											value={values.name?.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Name Gu */}
								<FormControl flex={5}>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={100}
										required
										value={values.name?.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
							<Flex gridGap={2}>
								{/* Mobile Number 1 */}
								<FormControl>
									<InputLabel label="Mobile Number 1" />
									<Input
										name="mobileNumber1"
										placeholder="Mobile Number"
										value={values.mobileNumber1?.toString()}
										onChange={handleChange}
										maxLength={10}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="mobileNumber1"
									/>
								</FormControl>
								{/* Mobile Number 2 */}
								<FormControl>
									<InputLabel label="Mobile Number 2" />
									<Input
										name="mobileNumber2"
										placeholder="Mobile Number"
										value={values.mobileNumber2?.toString()}
										onChange={handleChange}
										maxLength={10}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="mobileNumber2"
									/>
								</FormControl>
							</Flex>
							{/* Email */}
							<FormControl>
								<InputLabel label="Email" />
								<Input
									name="email"
									placeholder="Email"
									value={values.email}
									onChange={handleChange}
									type={"email"}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="email"
								/>
							</FormControl>
							{/* Website */}
							<FormControl>
								<InputLabel label="Website" />
								<Input
									name="website"
									placeholder="Website"
									value={values.website}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="website"
								/>
							</FormControl>
							{/* Address */}
							<FormControl>
								<InputLabel label="Address" />
								<Input
									name="address"
									placeholder="Address"
									required
									value={values.address}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="address"
								/>
							</FormControl>
							<FormControl>
								<InputLabel label="Village / City" />
								<ReactSelect
									name="currentVillageId"
									onChange={(newValue) => {
										setFieldValue(
											"currentVillageId",
											(newValue as SelectOption).value,
										)
									}}
									required
									onInputChange={(text) => {
										if (text.length) {
											setVillageSearchText(text)
										}
									}}
									value={villageOptions.find(
										(el) => el.value === values.currentVillageId,
									)}
									options={villageOptions}
									isSearchable
								/>
							</FormControl>
							<Flex>
								{/* Business Type */}
								<FormControl mr={4}>
									<InputLabel label="Business Type" />
									<ReactSelect
										name="type"
										onChange={(newValue) => {
											setFieldValue(
												"type",
												(newValue as SelectOption).value,
											)
										}}
										value={businessTypeOptions.find(
											(el) => el.value === values.type,
										)}
										required
										options={businessTypeOptions}
										isSearchable
									/>
								</FormControl>
								{/* Registration Type */}
								<FormControl>
									<InputLabel label="Registration Type" />
									<ReactSelect
										name="registrationType"
										onChange={(newValue) => {
											setFieldValue(
												"registrationType",
												(newValue as SelectOption).value,
											)
										}}
										value={businessRegistrationTypeOptions.find(
											(el) => el.value === values.registrationType,
										)}
										options={businessRegistrationTypeOptions}
										isSearchable
									/>
								</FormControl>
							</Flex>
							{/* Description textarea */}
							<FormControl mt={2}>
								<InputLabel label="Description" />
								<Textarea
									name="description"
									placeholder="Description"
									value={values.description}
									onChange={handleChange}
								/>
							</FormControl>
							{/* Identity Proof Image file */}
							{/* Image file */}
							<FormControl flex={2}>
								<InputLabel label="Image file" />
								<Input
									name="imageFile"
									type="file"
									onChange={(e) => {
										if (!e.target.files?.[0]) return
										handleUploadImage(e.target.files?.[0])
									}}
								/>
							</FormControl>
							{/* Image preview */}
							{selectedImage ? (
								<Box>
									<Image
										src={selectedImage?.variants?.[0]}
										alt="Image preview"
										width="100%"
										height="auto"
										objectFit="cover"
										borderRadius="lg"
									/>
								</Box>
							) : null}
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
