import { useCallback, useState } from "react"
import { occupationCategoryListApi } from "../api/occupationCategory/occupationCategoryListApi"
import { OccupationCategory } from "../entities/occupationCategory"
import { useAuth } from "./useAuth"

export function useOccupationCategoryListApi(): {
	occupationCategoryList: OccupationCategory[]
	isLoading: boolean
	fetchOccupationCategoryList: (
		filter?: Parameters<typeof occupationCategoryListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [occupationCategoryList, setOccupationCategoryList] = useState<
		OccupationCategory[]
	>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchOccupationCategoryList = useCallback(
		async (filter: Parameters<typeof occupationCategoryListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await occupationCategoryListApi(filter, token)
			setOccupationCategoryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { occupationCategoryList, isLoading, fetchOccupationCategoryList }
}
