import {
	Box,
	Button,
	Flex,
	FormControl,
	Image,
	Input,
	Stack,
	Tag,
	Text,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import React, { ComponentProps, FC, useEffect, useState } from "react"
import { BsImage } from "react-icons/bs"
import ReactSelect from "react-select"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SelectOption } from "src/components/shared/ReactSelect"
import { File } from "src/domain/entities/file"
import { OccupationCategory } from "src/domain/entities/occupationCategory"
import { Village } from "src/domain/entities/village"
import {
	BusinessRegistrationType,
	BusinessType,
	getBusinessRegistrationType,
	getBusinessType,
} from "src/utils/enums"
import { IBusinessAddFormFields } from "."
import { fetchGujaratiSuggestions, getVillageName } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IBusinessAddFormFields>
	villageList: Village[]
	setVillageSearchText: (text: string) => void
	businessCategoryList: OccupationCategory[]
	handleUploadImage: (file: any) => Promise<File>
	selectedImage?: File
	setSelectedImage: (file?: File) => void
}

export const BusinessAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	villageList,
	setVillageSearchText,
	businessCategoryList,
	handleUploadImage,
	selectedImage,
	setSelectedImage,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	const [isLogoImageUploading, setIsLogoImageUploading] = useState<boolean>(false)

	const [_, setLogoImageId] = useState<string>("")

	useEffect(() => {
		if (selectedImage) {
			setLogoImageId(selectedImage.id)
		}
	}, [selectedImage])

	const businessTypeOptions: {
		label: string
		value?: BusinessType
	}[] = Object.values(BusinessType).map((type) => ({
		label: getBusinessType(type),
		value: type,
	}))

	const businessRegistrationTypeOptions: {
		label: string
		value?: BusinessRegistrationType
	}[] = Object.values(BusinessRegistrationType).map((type) => ({
		label: getBusinessRegistrationType(type),
		value: type,
	}))

	return (
		<Formik<IBusinessAddFormFields>
			initialValues={{
				name: {
					en: "",
					gu: "",
				},
				address: "",
				currentVillageId: "",
				logoImageId: "",
				email: "",
				mobileNumber1: "",
				mobileNumber2: "",
				website: "",
				type: BusinessType.PRODUCT,
				registrationType: undefined,
				categoryId: "",
				locationCoordinate: {
					latitude: 0,
					longitude: 0,
				},
			}}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleLogoImageSelected = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					if (!e.target.files?.[0]) return
					setIsLogoImageUploading(true)
					const res = await handleUploadImage(e.target.files?.[0])
					setLogoImageId(res.id)
					setFieldValue("logoImageId", res.id)
					console.log(res)
					setIsLogoImageUploading(false)
				}

				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const villageOptions = villageList.map((village) => ({
					value: village.id,
					label: getVillageName(village),
				}))

				const businessCategoryOptions: {
					label: string
					value: string | null
				}[] = businessCategoryList.map((businessCategory) => ({
					value: businessCategory.id,
					label: businessCategory.name.en + ` (${businessCategory.name.gu})`,
				}))

				businessCategoryOptions.unshift({
					value: null,
					label: "None",
				})

				return (
					<DrawerForm
						size="md"
						headerLabel="Add Business"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"md"} marginX={"auto"} rowGap={2}>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={100}
											required
											autoFocus
											value={values.name.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Name Gu */}
								<FormControl flex={5}>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={100}
										required
										value={values.name.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
							{/* Address */}
							<FormControl>
								<InputLabel label="Address" />
								<Input
									name="address"
									placeholder="Address"
									required
									value={values.address}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="address"
								/>
							</FormControl>
							<FormControl>
								<InputLabel label="Village / City" />
								<ReactSelect
									name="currentVillageId"
									required
									onChange={(newValue) => {
										setFieldValue(
											"currentVillageId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(text) => {
										if (text.length) {
											setVillageSearchText(text)
										}
									}}
									value={villageOptions.find(
										(el) => el.value === values.categoryId,
									)}
									options={villageOptions}
									isSearchable
								/>
							</FormControl>
							{/* Business Type */}
							<FormControl mr={4}>
								<InputLabel label="Business Type" />
								<ReactSelect
									name="type"
									required
									onChange={(newValue) => {
										setFieldValue(
											"type",
											(newValue as SelectOption).value,
										)
									}}
									value={businessTypeOptions.find(
										(el) => el.value === values.type,
									)}
									options={businessTypeOptions}
									isSearchable
								/>
							</FormControl>
							{/* Business Category */}
							<FormControl flex={2}>
								<InputLabel label="Business Category" />
								<ReactSelect
									name="categoryId"
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
									}}
									value={businessCategoryOptions.find(
										(el) => el.value === values.categoryId,
									)}
									options={businessCategoryOptions}
									isSearchable
								/>
							</FormControl>

							{/* ALL OPTIONAL FIELDS */}
							<Box bgColor={"gray.200"} py={2} rounded={"xl"}>
								<Text fontWeight={"semibold"} textAlign={"center"}>
									Optional Fields
								</Text>
							</Box>
							<Flex gridGap={2}>
								{/* Mobile Number 1 */}
								<FormControl>
									<InputLabel label="Mobile Number 1" />
									<Input
										name="mobileNumber1"
										placeholder="Mobile Number"
										value={values.mobileNumber1?.toString()}
										onChange={handleChange}
										maxLength={10}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="mobileNumber1"
									/>
								</FormControl>
								{/* Mobile Number 2 */}
								<FormControl>
									<InputLabel label="Mobile Number 2" />
									<Input
										name="mobileNumber2"
										placeholder="Mobile Number"
										value={values.mobileNumber2?.toString()}
										onChange={handleChange}
										maxLength={10}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="mobileNumber2"
									/>
								</FormControl>
							</Flex>
							<FormControl>
								<InputLabel label="Email" />
								<Input
									name="email"
									placeholder="Email"
									value={values.email}
									onChange={handleChange}
									type={"email"}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="email"
								/>
							</FormControl>
							{/* Website */}
							<FormControl>
								<InputLabel label="Website" />
								<Input
									name="website"
									placeholder="Website"
									value={values.website}
									onChange={handleChange}
								/>
								<ErrorMessage
									component={ErrorMessageField}
									name="website"
								/>
							</FormControl>
							<Flex>
								{/* Registration Type */}
								<FormControl>
									<InputLabel label="Registration Type" />
									<ReactSelect
										name="registrationType"
										onChange={(newValue) => {
											setFieldValue(
												"registrationType",
												(newValue as SelectOption).value,
											)
										}}
										value={businessRegistrationTypeOptions.find(
											(el) => el.value === values.registrationType,
										)}
										options={businessRegistrationTypeOptions}
										isSearchable
									/>
								</FormControl>
							</Flex>

							{/* Logo Image */}
							<FormControl flex={2}>
								<InputLabel label="Logo Image" />
								<Box
									position={"relative"}
									backgroundColor={"gray.100"}
									color={"gray.700"}
									rounded={"lg"}
								>
									{selectedImage ? (
										<Flex
											p={4}
											align={"center"}
											justify={"space-between"}
										>
											<Flex flex={1} align={"center"}>
												<Image
													src={selectedImage?.variants?.[0]}
													alt="Image preview"
													width="30%"
													objectFit="cover"
													rounded="lg"
												/>
												<Text ml={4}>Image Selected!</Text>
											</Flex>
											<Button
												variant="outline"
												colorScheme="red"
												onClick={(e) => {
													e.stopPropagation()
													setSelectedImage(undefined)
												}}
											>
												Remove
											</Button>
										</Flex>
									) : (
										<>
											<Flex p={8}>
												{isLogoImageUploading ? (
													<Box mx={"auto"}>
														<CenteredSpinner />
													</Box>
												) : (
													<>
														<BsImage size={24} />
														<Text ml={2}>
															Pick an Image here...
														</Text>
													</>
												)}
											</Flex>
											<Input
												name="imageFile"
												type="file"
												height="100%"
												width="100%"
												position="absolute"
												top="0"
												left="0"
												opacity="0"
												aria-hidden="true"
												accept="image/*"
												onChange={handleLogoImageSelected}
												cursor="pointer"
											/>
										</>
									)}
								</Box>
							</FormControl>
							{/* Description textarea */}
							<FormControl mt={2}>
								<InputLabel label="Description" />
								<Textarea
									name="description"
									placeholder="Description"
									value={values.description}
									onChange={handleChange}
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
