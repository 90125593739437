import { useCallback, useState } from "react"
import { mobileListApi } from "../api/mobile/mobileListApi"
import { Mobile } from "../entities/mobile"
import { useAuth } from "./useAuth"

export function useMobileListApi(): {
	mobileList: Mobile[]
	isLoading: boolean
	fetchMobileList: (filter?: Parameters<typeof mobileListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [mobileList, setMobileList] = useState<Mobile[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchMobileList = useCallback(
		async (filter: Parameters<typeof mobileListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await mobileListApi(filter, token)
			setMobileList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { mobileList, isLoading, fetchMobileList }
}
