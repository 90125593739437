import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { Country } from "src/domain/entities/country"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { State } from "../../../domain/entities/state"
import { useCountryListApi, useStateListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { StateAddDrawerFormController } from "./StateAddDrawerForm"
import { StateDeleteDialogController } from "./StateDeleteDialogController"
import { StateListController } from "./StateList"
import { StateUpdateDrawerFormController } from "./StateUpdateDrawerForm"

export const StatesPage: FC = () => {
	const { stateList, isLoading, fetchStateList } = useStateListApi()
	const [isStateDeleteDialogOpen, setIsStateDeleteDialogOpen] = useState(false)
	const stateAddDrawerDisclosure = useDisclosure()
	const stateUpdateDrawerDisclosure = useDisclosure()

	const { countryList, fetchCountryList } = useCountryListApi()

	const [selectedCountry, setSelectedCountry] = useState<Country>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchStates = useCallback(async () => {
		return await fetchStateList({
			fetch: { country: true },
			search: searchText,
			countryId: selectedCountry?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchStateList, pagination.currentPage, selectedCountry?.id, searchText])

	useEffect(() => {
		fetchStates()
		fetchCountryList()
	}, [fetchStates, fetchCountryList])

	const updateStateRef = useRef<State>()
	const deleteStateRef = useRef<State>()

	const countryOptions: {
		label: string
		value?: Country
	}[] = countryList.map((country) => ({
		label: country.name.en,
		value: country,
	}))

	countryOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						States
					</Text>
					<Button
						size="sm"
						onClick={stateAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add State
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box
						width={{ base: "full", lg: "fit-content" }}
						my={{ base: "2", lg: "0" }}
					>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Country */}
					<Box width={{ base: "full", lg: "20%" }}>
						<InputLabel label="Country" />
						<ReactSelect
							name="countryId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedCountry(val?.value)
							}}
							value={countryOptions.find(
								(el) => el.value?.id === selectedCountry?.id,
							)}
							options={countryOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<StateListController
				list={stateList}
				isLoading={isLoading}
				onUpdate={(state: State) => {
					updateStateRef.current = state
					stateUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(state: State) => {
					deleteStateRef.current = state
					setIsStateDeleteDialogOpen(true)
				}}
				onAddClick={stateAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={stateList.length === 0}
			/>
			{stateAddDrawerDisclosure.isOpen ? (
				<StateAddDrawerFormController
					{...stateAddDrawerDisclosure}
					onSuccess={() => fetchStates()}
				/>
			) : null}
			{updateStateRef.current && stateUpdateDrawerDisclosure.isOpen ? (
				<StateUpdateDrawerFormController
					{...stateUpdateDrawerDisclosure}
					state={updateStateRef.current}
					onSuccess={() => fetchStates()}
				/>
			) : null}
			{deleteStateRef.current && isStateDeleteDialogOpen ? (
				<StateDeleteDialogController
					isOpen={isStateDeleteDialogOpen}
					setIsOpen={setIsStateDeleteDialogOpen}
					state={deleteStateRef.current}
					onSuccess={() => fetchStates()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
