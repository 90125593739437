import { ComponentProps, FC, useCallback, useEffect } from "react"
import { educationCategoryAddApi } from "src/domain/api/educationCategory/educationCategoryAddApi"
import { EducationCategory } from "../../../../domain/entities/educationCategory"
import { useAuth, useEducationCategoryListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { EducationCategoryAddDrawerFormView } from "./EducationCategoryAddDrawerFormView"
import { IEducationCategoryAddFormFields } from "./IEducationCategoryAddFormFields"

interface Props
	extends Omit<
		ComponentProps<typeof EducationCategoryAddDrawerFormView>,
		"handleSubmit" | "parentEducationCategoryList"
	> {
	onSuccess: (educationCategory: EducationCategory) => void
}

export const EducationCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IEducationCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const educationCategory = await educationCategoryAddApi(values, token)
				onSuccess(educationCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchEducationCategoryList, educationCategoryList } =
		useEducationCategoryListApi()

	useEffect(() => {
		fetchEducationCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
			},
		})
	}, [fetchEducationCategoryList])

	return (
		<EducationCategoryAddDrawerFormView
			handleSubmit={handleSubmit}
			parentEducationCategoryList={educationCategoryList}
			{...rest}
		/>
	)
}
