import {
	Avatar,
	Box,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { DiscussionReply } from "src/domain/entities/discussionReply"
import { useDiscussionReplyListApi } from "src/domain/hooks/useDiscussionReplyListApi"
import { useDiscussionListApi } from "src/domain/hooks/useDiscussionsListApi"
import { DiscussionReplyDeleteDialogController } from "./DiscussionReplyDeleteDialogController"
import { DiscussionsReplyUpdateDrawerFormController } from "./DiscussionsReply/DiscussionsUpdateDrawerForm"

interface Props {
	isOpen: boolean
	onProfileClick: (profileId: string) => void
	setIsOpen: (isOpen: boolean) => void
	discussionId: string
}

export const DiscussionDetailsModal: FC<Props> = ({
	isOpen,
	setIsOpen,
	discussionId,
	onProfileClick,
}) => {
	const { discussionList, fetchDiscussionList } = useDiscussionListApi()

	const fetchDiscussions = useCallback(async () => {
		await fetchDiscussionList({
			id: discussionId,
			fetch: {
				createdBy: {
					profile: true,
				},
				category: true,
			},
		})
	}, [fetchDiscussionList, discussionId])

	useEffect(() => {
		fetchDiscussions()
	}, [fetchDiscussions])

	const [discussionDetails] = discussionList

	const { discussionReplyList, fetchDiscussionReplyList } = useDiscussionReplyListApi()

	const fetchDiscussionReply = useCallback(async () => {
		await fetchDiscussionReplyList({
			discussionId,
			fetch: {
				allReplies: true,
				createdBy: {
					profile: true,
				},
				category: true,
			},
		})
	}, [fetchDiscussionReplyList, discussionId])

	useEffect(() => {
		fetchDiscussionReply()
	}, [fetchDiscussionReply])

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={"lg"}>
				<ModalOverlay />
				<ModalContent rounded={"2xl"} overflow="hidden">
					<ModalHeader mx={"auto"}>Discussion Replies</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={"3"}>
						<Box>
							{!discussionDetails ? null : (
								<>
									<Flex
										fontWeight={"medium"}
										mb={"20px"}
										bgColor={"gray.100"}
										p={"12px"}
										borderRadius={"8px"}
										direction={"column"}
									>
										<Box color={"#444"}>{discussionDetails.text}</Box>
										<Flex justify={"space-between"} align={"center"}>
											<Flex
												align={"center"}
												color={"#64748B"}
												mt={"10px"}
											>
												<Image
													src="/images/total_answers.png"
													w={"15px"}
													h={"15px"}
												/>
												<Box ml={"8px"}>
													{discussionDetails.totalReplies}
												</Box>
												<Text ml={"5px"}>Answers</Text>
											</Flex>
											<Flex align={"center"} mt={"10px"}>
												<Image
													src={`data:image/svg+xml;utf8,${discussionDetails.category?.icon}`}
												/>
												<Box color={"#000"} ml={"12px"}>
													{discussionDetails.category?.name.en}
												</Box>
											</Flex>
										</Flex>
									</Flex>
									<Box>
										{discussionReplyList.map((discussionReply, e) => {
											return (
												<DiscussionReplyListItem
													discussionReply={discussionReply}
													fetchDiscussions={fetchDiscussions}
													onProfileClick={onProfileClick}
													key={e}
												/>
											)
										})}
									</Box>
								</>
							)}
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

const DiscussionReplyListItem: FC<{
	discussionReply: DiscussionReply

	onProfileClick: (profileId: string) => void
	fetchDiscussions: () => void
}> = ({ discussionReply, onProfileClick, fetchDiscussions }) => {
	const discussionReplyUpdateDrawerDisclosure = useDisclosure()
	const deleteDiscussionReplyRef = useRef<DiscussionReply>()
	const [isDiscussionReplyDeleteDialogOpen, setIsDiscussionReplyDeleteDialogOpen] =
		useState(false)

	const updateDiscussionReplyRef = useRef<DiscussionReply>()
	return (
		<>
			<Flex
				borderBottom={"1px"}
				borderColor={"gray.300"}
				borderStyle={"solid"}
				my={"8px"}
				bgColor={"#F8FAFC"}
				boxShadow={"md"}
				direction={"column"}
				borderRadius={"8px"}
				p={"10px"}
			>
				<Flex mt={"10px"} width={"full"} justifyContent={"space-between"}>
					<Flex
						width={"full"}
						direction={"row"}
						alignItems={"center"}
						justifyContent={"space-between"}
						onClick={
							discussionReply.createdBy?.profile
								? () =>
										onProfileClick(
											discussionReply.createdBy!.profile!.id,
										)
								: () => {}
						}
					>
						<Box
							onClick={
								discussionReply?.createdBy?.profile
									? () =>
											onProfileClick(
												discussionReply?.createdBy!.profile!.id,
											)
									: () => {}
							}
						>
							<Avatar
								size={"xs"}
								mr={"8px"}
								border={"1px"}
								borderColor={"#d5d5d5"}
								name={discussionReply.createdBy?.id}
								src={discussionReply.createdBy?.profile?.profileImageUrl}
							/>
							<span>
								{discussionReply.createdBy?.profile?.firstName.en ?? ""}{" "}
								{discussionReply.createdBy?.profile?.surname?.name.en ??
									""}
							</span>
						</Box>
						<Flex>
							<EditIconButton
								onClick={() => {
									updateDiscussionReplyRef.current = discussionReply
									discussionReplyUpdateDrawerDisclosure.onOpen()
								}}
							/>
							<DeleteIconButton
								onClick={() => {
									deleteDiscussionReplyRef.current = discussionReply
									setIsDiscussionReplyDeleteDialogOpen(true)
								}}
							/>
						</Flex>
					</Flex>
				</Flex>
				<Box
					color={"gray.700"}
					textTransform={"capitalize"}
					fontSize={"sm"}
					my={"10px"}
				>
					{discussionReply.text}
				</Box>
				<Flex>
					<Flex align={"center"}>
						<Image src="/images/like.svg" w={"15px"} h={"15px"} />
						<Box ml={"5px"}>{discussionReply.likesCount}</Box>
					</Flex>
					<Flex align={"center"} ml={"24px"}>
						<Image src="/images/dislike.svg" w={"15px"} h={"15px"} />
						<Box ml={"5px"}>{discussionReply.dislikesCount}</Box>
					</Flex>
				</Flex>
			</Flex>
			{updateDiscussionReplyRef.current &&
			discussionReplyUpdateDrawerDisclosure.isOpen ? (
				<DiscussionsReplyUpdateDrawerFormController
					{...discussionReplyUpdateDrawerDisclosure}
					discussionReply={updateDiscussionReplyRef.current}
					onSuccess={() => fetchDiscussions()}
				/>
			) : null}
			{deleteDiscussionReplyRef.current && isDiscussionReplyDeleteDialogOpen ? (
				<DiscussionReplyDeleteDialogController
					isOpen={isDiscussionReplyDeleteDialogOpen}
					setIsOpen={setIsDiscussionReplyDeleteDialogOpen}
					discussionReply={deleteDiscussionReplyRef.current}
					onSuccess={() => fetchDiscussions()}
				/>
			) : null}
		</>
	)
}
