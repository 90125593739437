import { FC } from "react"
import { AndroidBuild } from "../../../../domain/entities/androidBuild"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { AndroidBuildListView } from "./AndroidBuildListView"

interface Props {
	list: AndroidBuild[]
	isLoading: boolean
	onUpdate: (androidBuild: AndroidBuild) => void
	onDelete: (androidBuild: AndroidBuild) => void
	onAddClick: () => void
}

export const AndroidBuildListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No Android Builds found."
				onAddClick={onAddClick}
			/>
		)
	}

	return <AndroidBuildListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
