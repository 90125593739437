import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"

import { AndroidBuild } from "../../../domain/entities/androidBuild"
import { useAndroidBuildListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { AndroidBuildAddDrawerFormController } from "./AndroidBuildAddDrawerForm"
import { AndroidBuildDeleteDialogController } from "./AndroidBuildDeleteDialogController"
import { AndroidBuildListController } from "./AndroidBuildList"
import { AndroidBuildUpdateDrawerFormController } from "./AndroidBuildUpdateDrawerForm"

export const AndroidBuildsPage: FC = () => {
	const { androidBuildList, isLoading, fetchAndroidBuildList } =
		useAndroidBuildListApi()
	const [isAndroidBuildDeleteDialogOpen, setIsAndroidBuildDeleteDialogOpen] =
		useState(false)
	const androidBuildAddDrawerDisclosure = useDisclosure()
	const androidBuildUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchAndroidBuildList()
	}, [fetchAndroidBuildList])

	const updateAndroidBuildRef = useRef<AndroidBuild>()
	const deleteAndroidBuildRef = useRef<AndroidBuild>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Android Builds
					</Text>
					<Button
						size="sm"
						onClick={androidBuildAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Android Build
					</Button>
				</Flex>
			</Box>
			<AndroidBuildListController
				list={androidBuildList}
				isLoading={isLoading}
				onUpdate={(androidBuild: AndroidBuild) => {
					updateAndroidBuildRef.current = androidBuild
					androidBuildUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(androidBuild: AndroidBuild) => {
					deleteAndroidBuildRef.current = androidBuild
					setIsAndroidBuildDeleteDialogOpen(true)
				}}
				onAddClick={androidBuildAddDrawerDisclosure.onOpen}
			/>
			{androidBuildAddDrawerDisclosure.isOpen ? (
				<AndroidBuildAddDrawerFormController
					{...androidBuildAddDrawerDisclosure}
					onSuccess={() => fetchAndroidBuildList()}
				/>
			) : null}
			{updateAndroidBuildRef.current &&
			androidBuildUpdateDrawerDisclosure.isOpen ? (
				<AndroidBuildUpdateDrawerFormController
					{...androidBuildUpdateDrawerDisclosure}
					androidBuild={updateAndroidBuildRef.current}
					onSuccess={() => fetchAndroidBuildList()}
				/>
			) : null}
			{deleteAndroidBuildRef.current && isAndroidBuildDeleteDialogOpen ? (
				<AndroidBuildDeleteDialogController
					isOpen={isAndroidBuildDeleteDialogOpen}
					setIsOpen={setIsAndroidBuildDeleteDialogOpen}
					androidBuild={deleteAndroidBuildRef.current}
					onSuccess={() => fetchAndroidBuildList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
