import { Unit } from "src/domain/entities/unit"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	isApproved?: boolean
	search?: string
	sortingFields?: {
		position: "ASC"
	}
	pagination?: RequestPagination
}

export async function unitListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/unit/list/v1"
	const requester = new ApiRequester<RequestDataShape, Unit[]>(endpoint)
	return await requester.sendRequest(
		{ ...payload, sortingFields: { position: "ASC" } },
		token,
	)
}
