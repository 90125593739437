import { useCallback, useState } from "react"
import { educationCategoryListApi } from "../api/educationCategory/educationCategoryListApi"
import { EducationCategory } from "../entities/educationCategory"
import { useAuth } from "./useAuth"

export function useEducationCategoryListApi(): {
	educationCategoryList: EducationCategory[]
	isLoading: boolean
	fetchEducationCategoryList: (
		filter?: Parameters<typeof educationCategoryListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [educationCategoryList, setEducationCategoryList] = useState<
		EducationCategory[]
	>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchEducationCategoryList = useCallback(
		async (filter: Parameters<typeof educationCategoryListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await educationCategoryListApi(filter, token)
			setEducationCategoryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { educationCategoryList, isLoading, fetchEducationCategoryList }
}
