import { ProductCategory } from "src/domain/entities/productCategory"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	parentCategoryId?: string
	imageId?: string
}

export async function productCategoryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/productCategory/add/v1"
	const requester = new ApiRequester<RequestDataShape, ProductCategory>(endpoint)
	return await requester.sendRequest(payload, token)
}
