import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { DiscussionCategory } from "../../../../domain/entities/discussionCategory"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const DiscussionCategoryListView: FC<{
	list: DiscussionCategory[]
	onUpdate: (discussionCategory: DiscussionCategory) => void
	onDelete: (discussionCategory: DiscussionCategory) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Name Gujarati</Th>
				<Th>Color</Th>
				<Th>Icon</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((discussionCategory, i) => (
				<Tr key={i}>
					<Td>{discussionCategory.name.en}</Td>
					<Td>{discussionCategory.name.gu}</Td>
					<Td>
						<Flex align={"center"} gap={1}>
							<Box
								h={4}
								w={4}
								backgroundColor={discussionCategory.color}
							></Box>
							<Box>{discussionCategory.color}</Box>
						</Flex>
					</Td>
					<Td className="text-red">
						<img
							src={`data:image/svg+xml;utf8,${discussionCategory.icon}`}
							alt="icon"
						/>
					</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(discussionCategory)} />
						<DeleteIconButton onClick={() => onDelete(discussionCategory)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
