import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import moment from "moment"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { MobileAppBuildStatus } from "src/utils/enums"
import { IIosBuildUpdateFormFields } from "."
import { IosBuild } from "../../../../domain/entities/iosBuild"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	iosBuild: IosBuild
	handleSubmit: FormikOnSubmit<IIosBuildUpdateFormFields>
}

export const IosBuildUpdateDrawerFormView: FC<Props> = ({
	iosBuild,
	handleSubmit,
	...rest
}) => {
	return (
		<Formik<IIosBuildUpdateFormFields>
			initialValues={{
				buildNo: iosBuild.buildNo,
				version: iosBuild.version,
				publishDate: iosBuild.publishDate,
				status: iosBuild.status,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const statusOptions = [
					{ value: MobileAppBuildStatus.TESTING, label: "Testing" },
					{ value: MobileAppBuildStatus.LIVE, label: "Live" },
					{ value: MobileAppBuildStatus.DEPRECATED, label: "Deprecated" },
					{ value: MobileAppBuildStatus.ABANDONED, label: "Abandoned" },
				]

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add IosBuild"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								{/* Build No */}
								<FormControl flex={2}>
									<InputLabel label="Build No" />
									<Input
										name="buildNo"
										placeholder="1"
										maxLength={5}
										required
										type={"number"}
										value={values.buildNo}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="buildNo"
									/>
								</FormControl>
								{/* Version No */}
								<FormControl flex={2}>
									<InputLabel label="Version No" />
									<Input
										name="version"
										placeholder="0.0.1"
										maxLength={10}
										required
										value={values.version}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="version"
									/>
								</FormControl>
							</Flex>
							<Flex gridGap={2}>
								{/* Publish date */}
								<FormControl flex={2}>
									<InputLabel label="Publish Date" />
									<Input
										name="publishDate"
										placeholder="2021-01-01"
										maxLength={10}
										required
										type={"date"}
										value={moment(values.publishDate).format(
											"YYYY-MM-DD",
										)}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="publishDate"
									/>
								</FormControl>
								{/* Status */}
								<FormControl flex={2}>
									<InputLabel label="Status" />
									<ReactSelect
										name="status"
										onChange={(newValue) => {
											setFieldValue(
												"status",
												(newValue as SelectOption).value,
											)
										}}
										value={statusOptions.find(
											(el) => el.value === values.status,
										)}
										options={statusOptions}
									/>
								</FormControl>
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
