import { useCallback, useState } from "react"
import { facilityListApi } from "../api/facility/facilityListApi"
import { Facility } from "../entities/facility"
import { useAuth } from "./useAuth"

export function useFacilityListApi(): {
	facilityList: Facility[]
	isLoading: boolean
	fetchFacilityList: (filter?: Parameters<typeof facilityListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [facilityList, setFacilityList] = useState<Facility[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchFacilityList = useCallback(
		async (filter: Parameters<typeof facilityListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await facilityListApi(filter, token)
			setFacilityList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { facilityList, isLoading, fetchFacilityList }
}
