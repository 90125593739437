import { UserSession } from "src/domain/entities/userSession"
import { AuthRole } from "src/utils/enums"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	userIds?: string[]
	profileCode?: string
	registrationId?: string | null
	status?: boolean
	role?: AuthRole
	pagination?: RequestPagination
}

export async function userSessionListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/session/list/v1"
	const requester = new ApiRequester<RequestDataShape, UserSession[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
