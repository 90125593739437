import {
	Avatar,
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	Grid,
	Image,
	Input,
	Select,
	Stack,
	Tag,
	TagCloseButton,
	TagLabel,
	Text,
	Textarea,
} from "@chakra-ui/react"
import { Formik } from "formik"
import React, { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { BsCheck2, BsImage, BsPlusLg, BsX } from "react-icons/bs"
import ReactSelect from "react-select"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SelectOption } from "src/components/shared/ReactSelect"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { File } from "src/domain/entities/file"
import { Village } from "src/domain/entities/village"
import { useAuth, useProfileListApi } from "src/domain/hooks"
import {
	AnnualIncomeClass,
	BloodGroup,
	getAnnualIncomeClass,
	getBloodGroupType,
	getMatrimonyProfilePropertyType,
	MatrimonyProfilePropertyType,
} from "src/utils/enums"
import { getFullName } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"
import { IMatrimonyProfileAddFormFields } from "./IMatrimonyProfileAddFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IMatrimonyProfileAddFormFields>
	villageList: Village[]
	setVillageSearchText: (text: string) => void
	handleUploadImage: (file: any) => Promise<File>
	selectedImage?: File
	// propertiesList: MatrimonyProfileProperty[]
	setSelectedImage: (file?: File) => void
}

export const MatrimonyProfileAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	villageList,
	setVillageSearchText,
	handleUploadImage,
	selectedImage,
	// propertiesList,
	setSelectedImage,
	...rest
}) => {
	const [isLogoImageUploading, setIsLogoImageUploading] = useState<boolean>(false)

	const [_, setFeaturedImageId] = useState<string>("")

	useEffect(() => {
		if (selectedImage) {
			setFeaturedImageId(selectedImage.id)
		}
	}, [selectedImage])

	const [selectedHeight, setSelectedHeight] = useState(0)

	const [selectedProfileId, setSelectedProfileId] = useState<string>()
	const [profileSearchText, setProfileSearchText] = useState("")
	const { fetchProfileList, profileList } = useProfileListApi()
	const fetchProfiles = useCallback(async () => {
		fetchProfileList({
			search: profileSearchText,
			pagination: { limit: 10, page: 1 },
			fetch: {
				surname: {},
			},
		})
	}, [fetchProfileList, profileSearchText])

	useEffect(() => {
		fetchProfiles()
	}, [fetchProfiles])

	type Hobby = {
		name: string
	}

	const [hobbies, setHobbies] = useState<Hobby[]>([])
	const [newHobby, setNewHobby] = useState<string>("")

	return (
		<Formik<IMatrimonyProfileAddFormFields>
			initialValues={{
				aboutMe: "",
				featuredImageId: "",
				imageIds: [],
				profileId: "",
				height: 0,
				contactDetails: "",
				bloodGroup: BloodGroup.AB_POSITIVE,
				annualIncomeClass: AnnualIncomeClass.BET_5_AND_20_LAC,
				properties: [],
				hobbies: [],
				residenceAddress: "",
				weight: 0,
			}}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleLogoImageSelected = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					if (!e.target.files?.[0]) return
					setIsLogoImageUploading(true)
					const res = await handleUploadImage(e.target.files?.[0])
					setFeaturedImageId(res.id)
					setFieldValue("featuredImageId", res.id)
					console.log(res)
					setIsLogoImageUploading(false)
				}

				const handleAddHobby = () => {
					if (newHobby.trim() !== "") {
						const newHobbyObj: Hobby = {
							name: newHobby,
						}

						setHobbies([...hobbies, newHobbyObj])
						setFieldValue("hobbies", [
							...hobbies.map((hobby) => hobby.name),
							newHobbyObj.name,
						])
						setNewHobby("")
					}
				}

				const heightOptions = []
				for (let feet = 3; feet <= 6; feet++) {
					for (let inches = 0; inches <= 11; inches++) {
						const centimeters = (feet * 30 + inches * 2.5).toFixed(1)
						const label = `${feet}ft ${inches}in / ${centimeters}cm`
						const value = centimeters

						heightOptions.push({ label, value })
					}
				}

				const handleHeightChange = (
					event: React.ChangeEvent<HTMLSelectElement>,
				) => {
					setSelectedHeight(+event.target.value)
					setFieldValue("height", +event.target.value)
				}

				const bloodGroupOptions: {
					label: string
					value?: BloodGroup
				}[] = Object.values(BloodGroup).map((type) => ({
					label: getBloodGroupType(type),
					value: type,
				}))

				const propertyOptions: {
					label: string
					value: MatrimonyProfilePropertyType
				}[] = Object.values(MatrimonyProfilePropertyType).map((type) => ({
					label: getMatrimonyProfilePropertyType(type),
					value: type,
				}))

				const annualIncomeOptions: {
					label: string
					value?: AnnualIncomeClass
				}[] = Object.values(AnnualIncomeClass).map((type) => ({
					label: getAnnualIncomeClass(type),
					value: type,
				}))

				const handleRemoveHobby = (name: string) => {
					const updatedHobbies = hobbies.filter((hobby) => hobby.name !== name)
					setHobbies(updatedHobbies)
				}

				return (
					<DrawerForm
						size="md"
						headerLabel="Add Matrimony Profile"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"md"} marginX={"auto"} rowGap={2}>
							<FormControl>
								<InputLabel label="Select Profile" />
								<Input
									type="text"
									value={profileSearchText}
									onChange={(e) => {
										setProfileSearchText(e.target.value)
										setSelectedProfileId(undefined)
									}}
								/>
								{profileList
									.filter((e) =>
										selectedProfileId
											? e.id === selectedProfileId
											: true,
									)
									.map((profile) => (
										<Box
											p={2}
											backgroundColor={"gray.100"}
											cursor={"pointer"}
											rounded={"md"}
											my={2}
											onClick={() => {
												setSelectedProfileId(profile.id)
												setFieldValue("profileId", profile.id)
											}}
										>
											<Flex
												justify={"space-between"}
												align={"center"}
											>
												<Flex>
													<Avatar
														size={"sm"}
														src={profile.profileImageUrl}
														bgSize={"cover"}
														rounded={"full"}
														mr={2}
													/>
													<Text>{getFullName(profile)}</Text>
												</Flex>
												{selectedProfileId === profile.id ? (
													<Box color={"green.500"}>
														<BsCheck2 size={20} />
													</Box>
												) : null}
											</Flex>
										</Box>
									))}
							</FormControl>
							<Flex justify={"space-between"}>
								{/* Height */}
								<Box maxWidth={"fit-content"}>
									<InputLabel label="Height" />
									<Select
										value={selectedHeight}
										onChange={handleHeightChange}
									>
										{heightOptions.map((option) => (
											<option
												key={option.value}
												value={option.value}
											>
												{option.label}
											</option>
										))}
									</Select>
								</Box>
								{/* Weight */}
								<FormControl maxW={"24"}>
									<InputLabel label="Weight" />
									<Input
										type={"number"}
										name="weight"
										placeholder="Weight"
										required
										autoFocus
										value={values.weight}
										onChange={handleChange}
									/>
								</FormControl>
								{/* Blood Group */}
								<FormControl maxWidth={"fit-content"}>
									<InputLabel label="Blood Group" />
									<ReactSelect
										name="bloodGroup"
										onChange={(newValue) => {
											setFieldValue(
												"bloodGroup",
												(newValue as SelectOption).value,
											)
										}}
										value={bloodGroupOptions.find(
											(el) => el.value === values.bloodGroup,
										)}
										options={bloodGroupOptions}
										isSearchable
									/>
								</FormControl>
							</Flex>
							{/* About */}
							<FormControl>
								<InputLabel label="About" />
								<Textarea
									name="aboutMe"
									placeholder="Write your passion, ambition, anything that makes you stand out..."
									value={values.aboutMe}
									onChange={handleChange}
								/>
							</FormControl>
							<Box>
								<Stack spacing={4}>
									<Input
										placeholder="Enter a new hobby"
										value={newHobby}
										onChange={(e) => setNewHobby(e.target.value)}
									/>
									<Button colorScheme="blue" onClick={handleAddHobby}>
										Add Hobby
									</Button>
								</Stack>

								<Flex mt={4}>
									{hobbies.map((hobby) => (
										<Tag
											key={hobby.name}
											borderRadius="8"
											variant="solid"
											colorScheme={"blue"}
											padding={"2"}
											mr={2}
										>
											<TagLabel>{hobby.name}</TagLabel>
											<TagCloseButton
												onClick={() =>
													handleRemoveHobby(hobby.name)
												}
											/>
										</Tag>
									))}
								</Flex>
							</Box>
							{/* Featured Image */}
							<FormControl flex={2}>
								<InputLabel label="Featured Image" />
								<Box
									position={"relative"}
									backgroundColor={"gray.100"}
									color={"gray.700"}
									rounded={"lg"}
								>
									{selectedImage ? (
										<Flex
											p={4}
											align={"center"}
											justify={"space-between"}
										>
											<Flex flex={1} align={"center"}>
												<Image
													src={selectedImage?.variants?.[0]}
													alt="Image preview"
													width="30%"
													objectFit="cover"
													rounded="lg"
												/>
												<Text ml={4}>Image Selected!</Text>
											</Flex>
											<Button
												variant="outline"
												colorScheme="red"
												onClick={(e) => {
													e.stopPropagation()
													setSelectedImage(undefined)
												}}
											>
												Remove
											</Button>
										</Flex>
									) : (
										<>
											<Flex p={8}>
												{isLogoImageUploading ? (
													<Box mx={"auto"}>
														<CenteredSpinner />
													</Box>
												) : (
													<>
														<BsImage size={24} />
														<Text ml={2}>
															Pick an Image here...
														</Text>
													</>
												)}
											</Flex>
											<Input
												name="imageFile"
												type="file"
												height="100%"
												width="100%"
												position="absolute"
												top="0"
												left="0"
												opacity="0"
												aria-hidden="true"
												accept="image/*"
												onChange={handleLogoImageSelected}
												cursor="pointer"
											/>
										</>
									)}
								</Box>
							</FormControl>
							{/* More Images */}
							<FormControl flex={2}>
								<InputLabel label="More Images" />
								<Flex
									backgroundColor={"gray.100"}
									p={4}
									rounded={"lg"}
									gridGap="2"
									wrap={"wrap"}
								>
									{values.imageIds.map((_el, i) => (
										<CustomImageInput
											key={_el}
											onImageSelected={(image: File) => {
												// set image id
												if (image) {
													setFieldValue(
														`imageIds.${i}`,
														image.id,
													)
												}
											}}
											onClearImage={() => {
												const newImageIds =
													values.imageIds.filter(
														(_el, index) => index !== i,
													)
												setFieldValue("imageIds", newImageIds)
											}}
										/>
									))}
									{values.imageIds.length >= 10 ? null : (
										<Flex
											h={"50px"}
											w={"70px"}
											borderWidth={"2px"}
											borderColor={"gray.400"}
											color={"gray.500"}
											rounded={"lg"}
											justify={"center"}
											alignItems={"center"}
											onClick={() => {
												setFieldValue("imageIds", [
													...values.imageIds,
													null,
												])
											}}
											cursor={"pointer"}
										>
											<BsPlusLg size={20} />
										</Flex>
									)}
								</Flex>
							</FormControl>
							{/* Annual Income */}
							<FormControl>
								<InputLabel label="Annual Income" />
								<ReactSelect
									name="annualIncomeClass"
									onChange={(newValue) => {
										setFieldValue(
											"annualIncomeClass",
											(newValue as SelectOption).value,
										)
									}}
									value={annualIncomeOptions.find(
										(el) => el.value === values.annualIncomeClass,
									)}
									options={annualIncomeOptions}
									isSearchable
								/>
							</FormControl>
							{/* Residential Address */}
							<FormControl>
								<InputLabel label="Residential Address" />
								<Textarea
									name="residenceAddress"
									placeholder="Enter your residence address here..."
									value={values.residenceAddress}
									onChange={handleChange}
								/>
							</FormControl>
							{/* Contact Details */}
							<FormControl>
								<InputLabel label="Contact Details" />
								<Textarea
									name="contactDetails"
									placeholder=" +91 XXXXX XXXXX"
									value={values.contactDetails}
									onChange={handleChange}
								/>
							</FormControl>
							{/* Properties */}
							<FormControl maxWidth={"fit-content"}>
								<InputLabel label="Properties" />
								<Grid templateColumns="repeat(2, 1fr)" gap={4} p={1}>
									{propertyOptions.map((property) => (
										<Checkbox
											name="properties"
											checked={
												!!values.properties.find(
													(e) => e.type === property.value,
												)
											}
											onChange={(e) => {
												if (e.target.checked) {
													values.properties.push({
														type: property.value,
														description: "",
													})
												} else {
													values.properties =
														values.properties.filter(
															(e) =>
																e.type !== property.value,
														)
												}
											}}
										>
											<Flex justify={"space-between"} mr={2}>
												{property.label}
											</Flex>
										</Checkbox>
									))}
								</Grid>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}

const CustomImageInput: FC<{
	onImageSelected: (file: File) => void
	onClearImage: () => void
}> = ({ onImageSelected, onClearImage }) => {
	const { token } = useAuth()

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)
			onImageSelected(response as File)
		},
		[token, onImageSelected],
	)

	return (
		<Box position={"relative"}>
			<Flex
				h={"50px"}
				w={"70px"}
				borderWidth={"2px"}
				borderColor={"gray.400"}
				color={"gray.500"}
				rounded={"lg"}
				justify={"center"}
				alignItems={"center"}
				overflow="hidden"
			>
				{selectedImage ? (
					<>
						<Image src={selectedImage?.variants?.[0]} alt="Image preview" />
						<Flex
							pos={"absolute"}
							h={4}
							w={4}
							backgroundColor="gray.400"
							color={"gray.800"}
							opacity={"0.8"}
							rounded={"full"}
							top={-1}
							right={-1}
							justify={"center"}
							alignItems={"center"}
							cursor="pointer"
							onClick={(e) => {
								e.stopPropagation()
								setSelectedImage(undefined)
								onClearImage()
							}}
						>
							<BsX size={12} />
						</Flex>
					</>
				) : (
					<BsImage size={20} />
				)}
			</Flex>
			{selectedImage ? null : (
				<Input
					type="file"
					height="100%"
					width="100%"
					position="absolute"
					top="0"
					left="0"
					opacity="0"
					aria-hidden="true"
					accept="image/*"
					onChange={(e) => {
						if (!e.target.files?.[0]) return
						handleUploadImage(e.target.files?.[0])
					}}
					cursor="pointer"
				/>
			)}
		</Box>
	)
}
