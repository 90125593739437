import { FC } from "react"
import { ProductCategory } from "src/domain/entities/productCategory"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { ProductCategoryListView } from "./ProductCategoryListView"

interface Props {
	list: ProductCategory[]
	isLoading: boolean
	onUpdate: (productCategory: ProductCategory) => void
	onDelete: (productCategory: ProductCategory) => void
	onAddClick: () => void
}

export const ProductCategoryListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No Product Categories found."
				onAddClick={onAddClick}
			/>
		)
	}

	return <ProductCategoryListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
