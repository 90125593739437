import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Icon,
	Text,
} from "@chakra-ui/react"
import { FC } from "react"
import { IconType } from "react-icons"
import { Link } from "react-router-dom"
import { getCurrentPath } from "../../domain/utils"
import { useCoreContext } from "../context/core"
import { ThePatidarsLogo } from "./ThePatidarsLogo"

export type NavItem = {
	name: string
	count?: number
	link: string
	icon: IconType
	subItems?: NavItem[]
}

export const SideBar: FC<{ items: NavItem[] }> = ({ items }) => (
	<Box
		display={{ base: "none", lg: "block" }}
		width="2xs"
		height="100vh"
		paddingBottom="10"
		overflowX="hidden"
		overflowY="auto"
		bg={"gray.800"}
		borderRightWidth="1px"
	>
		<SideBarTitle items={items} />
		<Flex as="div" direction="column">
			{items.map((item, i) =>
				item.subItems?.length ? (
					<AccordionMenuItem item={item} key={i} />
				) : (
					<MenuItem item={item} key={i} />
				),
			)}
		</Flex>
	</Box>
)

export const SideBarTitle: FC<{ children?: JSX.Element; items: NavItem[] }> = ({
	items,
}) => {
	const { admin } = useCoreContext()

	const path = getCurrentPath()

	const selectedNavItem = items.find((e) => {
		if (e.subItems?.length) {
			return e.subItems.find((sI) => sI.link === path)
		}
		return e.link === path
	})

	const navItemName = selectedNavItem?.name

	return (
		<Flex px={{ lg: "4" }} py={{ base: 1, lg: "5" }} direction="column">
			<Box ml={2}>
				<ThePatidarsLogo fontSize={"2xl"} variant={"light"} />
			</Box>

			<Text mt={{ lg: 1 }} ml="2" color="whiteAlpha.800">
				Admin - {admin?.name ?? ""}{" "}
				<Box display={{ base: "inline-block", lg: "none" }}> | {navItemName}</Box>
			</Text>
		</Flex>
	)
}

export const MenuItem: FC<{ item: NavItem }> = ({
	item: { icon, name, link, subItems, count },
}) => {
	let isSelected = false

	if (subItems) {
		isSelected = subItems.some((item) => getCurrentPath() === item.link)
	} else {
		isSelected = getCurrentPath() === link
	}

	const navItem = (
		<Flex
			align="center"
			px="4"
			mx="2"
			rounded="md"
			py="2"
			cursor={isSelected ? "auto" : "pointer"}
			color={isSelected ? "whiteAlpha.800" : "whiteAlpha.800"}
			backgroundColor={isSelected ? "whiteAlpha.200" : "transparent"}
			_hover={isSelected ? {} : { bg: "blackAlpha.300", color: "whiteAlpha.900" }}
			role="group"
			fontWeight="semibold"
			transition=".15s ease"
			textDecoration="none"
			mb="1"
		>
			<Icon mr="2" boxSize="4" as={icon} />
			{name}
			{count && (
				<Text
					ml="2"
					color="whiteAlpha.700"
					backgroundColor={"gray.600"}
					px={1}
					rounded={"md"}
					fontSize={"xs"}
				>
					{count}
				</Text>
			)}
		</Flex>
	)

	if (subItems?.length) return navItem

	return <Link to={link}>{navItem}</Link>
}

export const AccordionMenuItem: FC<{ item: NavItem }> = ({ item }) => {
	return (
		<Accordion
			allowToggle
			defaultIndex={
				item.subItems?.some((item) => getCurrentPath() === item.link)
					? 0
					: undefined
			}
		>
			<AccordionItem border={"none"}>
				<AccordionButton
					p={0}
					_focus={{
						outline: "none",
					}}
					display={"block"}
				>
					<MenuItem item={item} />
				</AccordionButton>
				<AccordionPanel>
					{item.subItems?.map((item, i) => (
						<MenuItem item={item} key={i} />
					))}
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}
