import { ComponentProps, FC, useCallback } from "react"
import { discussionCategoryAddApi } from "src/domain/api/discussionCategory/discussionCategoryAddApi"
import { DiscussionCategory } from "../../../../domain/entities/discussionCategory"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { DiscussionCategoryAddDrawerFormView } from "./DiscussionCategoryAddDrawerFormView"
import { IDiscussionCategoryAddFormFields } from "./IDiscussionCategoryAddFormFields"

interface Props
	extends Omit<
		ComponentProps<typeof DiscussionCategoryAddDrawerFormView>,
		"handleSubmit"
	> {
	onSuccess: (discussionCategory: DiscussionCategory) => void
}

export const DiscussionCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IDiscussionCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const discussionCategory = await discussionCategoryAddApi(values, token)
				onSuccess(discussionCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	return <DiscussionCategoryAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
