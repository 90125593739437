import { ComponentProps, FC, useCallback } from "react"
import { countryAddApi } from "src/domain/api/country/countryAddApi"
import { Country } from "../../../../domain/entities/country"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { CountryAddDrawerFormView } from "./CountryAddDrawerFormView"
import { ICountryAddFormFields } from "./ICountryAddFormFields"

interface Props
	extends Omit<ComponentProps<typeof CountryAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (country: Country) => void
}

export const CountryAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ICountryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const country = await countryAddApi(values, token)
				onSuccess(country)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	return <CountryAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
