import { useCallback, useState } from "react"
import { profileListApi } from "../api/profile/profileListApi"
import { Profile } from "../entities/profile"
import { useAuth } from "./useAuth"

export function useProfileListApi(): {
	profileList: Profile[]
	isLoading: boolean
	error: null | Error
	fetchProfileList: (
		filter?: Parameters<typeof profileListApi>[0],
	) => Promise<Profile[]>
} {
	const { token } = useAuth()
	const [profileList, setProfileList] = useState<Profile[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchProfileList = useCallback(
		async (filter: Parameters<typeof profileListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await profileListApi(filter, token)
				setProfileList(data)
				return data
			} catch (error) {
				setError(error as Error)
				return []
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { profileList, isLoading, error, fetchProfileList }
}
