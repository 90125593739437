import { Box, Card, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { formatDate } from "src/utils/helpers"
import { District } from "../../../../domain/entities/district"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const DistrictListView: FC<{
	list: District[]
	onUpdate: (district: District) => void
	onDelete: (district: District) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		<Table size={"sm"} display={{ base: "none", lg: "table" }}>
			<Thead>
				<Tr>
					<Th>Name</Th>
					<Th>Name Gujarati</Th>
					<Th>State</Th>
					<Th>Country</Th>
					<Th>Created At</Th>
					<Th>Updated At</Th>
					<Th isNumeric>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{list.map((district, i) => (
					<Tr key={i}>
						<Td>{district.name.en}</Td>
						<Td>{district.name.gu}</Td>
						<Td>
							<Text fontWeight={500}>{district.state?.name?.en}</Text>
							<Text>{district.state?.name.gu}</Text>
						</Td>
						<Td>
							<Text fontWeight={500}>
								{district.state?.country?.name?.en}
							</Text>
							<Text>{district.state?.country?.name.gu}</Text>
						</Td>
						<Td>{formatDate(district.createdAt)}</Td>
						<Td>{formatDate(district.updatedAt)}</Td>
						<Td isNumeric>
							<EditIconButton onClick={() => onUpdate(district)} />
							<DeleteIconButton onClick={() => onDelete(district)} />
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>

		{list.map((district, i) => {
			return (
				<Card
					key={i}
					display={{ base: "flex", lg: "none" }}
					shadow={"md"}
					m={"2"}
				>
					<Box display={"flex"} justifyContent={"space-between"} m={"2"}>
						<Box alignItems={"center"}>
							<Flex>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									Name:
								</Box>
								<Box ml={"2"}>{district.name.en}</Box>
							</Flex>

							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									Name Gujarati:
								</Box>
								<Box ml={"2"} fontSize={"sm"}>
									{district.name.gu}
								</Box>
							</Flex>
							<Flex direction={"row"} align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									State:
								</Box>
								<Box mx={"2"}>{district.state?.name?.en}</Box>
								<Box fontSize={"sm"}>{district.state?.name?.gu}</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									Country:
								</Box>
								<Box mx={"2"}>{district.state?.country?.name?.en}</Box>
								<Box fontSize={"sm"}>
									{district.state?.country?.name?.gu}
								</Box>
							</Flex>
						</Box>
						<Box>
							<Flex>
								<EditIconButton onClick={() => onUpdate(district)} />
								<DeleteIconButton onClick={() => onDelete(district)} />
							</Flex>
						</Box>
					</Box>
				</Card>
			)
		})}
	</>
)
