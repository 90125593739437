import { FC, useCallback, useState } from "react"
import { talukaDeleteApi } from "src/domain/api/taluka/talukaDeleteApi"
import { Taluka } from "src/domain/entities/taluka"
import { useAuth } from "../../../domain/hooks"
import { getErrorMessage } from "../../../utils/helpers"
import DeleteItemDialog from "../../shared/DeleteItemDialog"

interface Props {
	taluka: Taluka
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const TalukaDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	taluka,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleTalukaDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await talukaDeleteApi({ id: taluka.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [taluka, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Taluka: ${taluka.name.en}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleTalukaDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
