import { MatrimonyProfile } from "src/domain/entities/matrimonyProfile"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	profileId: string
	userId?: string
	identityProofImageId?: string
	aboutMe: string
	height: number
	weight: number
	hobbies: string[]
	bloodGroup:
		| "a_positive"
		| "a_negative"
		| "b_positive"
		| "b_negative"
		| "o_positive"
		| "o_negative"
		| "ab_positive"
		| "ab_negative"
	featuredImageId: string
	imageIds: string[]
	residenceAddress: string
	annualIncomeClass:
		| "less_5_lac"
		| "bet_5_and_20_lac"
		| "bet_20_and_50_lac"
		| "more_50_lac"
	contactDetails: string
	properties: {
		type:
			| "house"
			| "plot"
			| "agricultural_land"
			| "shop"
			| "factory"
			| "commercial_space"
		description: string
	}[]
}

export async function matrimonyProfileAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/matrimony/profile/add/v1"
	const requester = new ApiRequester<RequestDataShape, MatrimonyProfile>(endpoint)

	const response = await requester.sendRequest(payload, token)
	return response
}
