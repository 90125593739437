import { useCallback, useState } from "react"
import { userListApi } from "../api/user/userListApi"
import { User } from "../entities/user"
import { useAuth } from "./useAuth"

export function useUserListApi(): {
	userList: User[]
	isLoading: boolean
	error: null | Error
	fetchUserList: (filter?: Parameters<typeof userListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [userList, setUserList] = useState<User[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchUserList = useCallback(
		async (filter: Parameters<typeof userListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await userListApi(filter, token)
				setUserList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { userList, isLoading, error, fetchUserList }
}
