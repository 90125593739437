import { FC, useCallback, useEffect, useState } from "react"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { Business } from "../../../../domain/entities/business"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { ProfileDetailsModal } from "../../ProfilesPage/ProfileDetailsModal"
import { BusinessDetailsModal } from "../BusinessDetailsModal"
import { BusinessListView } from "./BusinessListView"

interface Props {
	list: Business[]
	handleBusinessVerificationChange: (val: boolean, business: Business) => Promise<void>
	isLoading: boolean
	onUpdate: (business: Business) => void
	onDelete: (business: Business) => void
}

export const BusinessListController: FC<Props> = ({
	isLoading,
	list,
	handleBusinessVerificationChange,
	onUpdate,
	onDelete,
}) => {
	const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(null)

	const [params, setParams] = useSearchParams()

	const handleBusinessClick = useCallback(
		(businessId: string) => {
			var updatedParams = createSearchParams({
				id: businessId,
			})
			setParams(updatedParams)
			setSelectedBusinessId(businessId)
		},
		[setParams],
	)

	const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)
	useEffect(() => {
		setTimeout(() => {
			const businessId = params.get("id")

			if (businessId) {
				handleBusinessClick(businessId)
			}
		}, 0)
	}, [handleBusinessClick, params])

	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Businesses found." />
	}

	const handleProfileClick = (profileId: string) => {
		setSelectedProfileId(profileId)
	}

	return (
		<>
			<BusinessListView
				onBusinessClick={handleBusinessClick}
				handleBusinessVerificationChange={handleBusinessVerificationChange}
				list={list}
				onDelete={onDelete}
				onUpdate={onUpdate}
			/>
			{selectedBusinessId ? (
				<BusinessDetailsModal
					isOpen={!!selectedBusinessId}
					setIsOpen={(val) => {
						if (!val) {
							setParams(createSearchParams())
							setSelectedBusinessId(null)
						}
					}}
					businessId={selectedBusinessId}
					handleProfileClick={handleProfileClick}
					onProfileClick={handleProfileClick}
				/>
			) : null}
			{selectedProfileId ? (
				<ProfileDetailsModal
					isOpen={!!selectedProfileId}
					setIsOpen={(val) => {
						if (!val) setSelectedProfileId(null)
					}}
					profileId={selectedProfileId}
					handleProfileClick={handleProfileClick}
				/>
			) : null}
		</>
	)
}
