import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"
import {
	AiFillHome,
	AiFillWechat,
	AiOutlineAndroid,
	AiOutlineApple,
} from "react-icons/ai"
import {
	BsBank2,
	BsBox,
	BsBriefcaseFill,
	BsBullseye,
	BsChatDotsFill,
	BsPinMap,
	BsRulers,
} from "react-icons/bs"
import { FaUserAlt, FaUserCheck, FaUserClock, FaUserGraduate } from "react-icons/fa"
import { IoBuild } from "react-icons/io5"
import { MdCategory, MdLocationOn, MdWork } from "react-icons/md"
import { RiHeart2Fill, RiLogoutBoxRFill } from "react-icons/ri"
import { useCoreContext } from "../context/core"

import { Header } from "../shared/Header"
import { NavItem, SideBar } from "../shared/SideBar"

const items: NavItem[] = [
	{ name: "Dashboard", link: "/", icon: AiFillHome },
	{ name: "Users", link: "/users", icon: FaUserAlt },
	{ name: "Registrations", link: "/registrations", icon: FaUserClock },
	{ name: "Profiles", link: "/profiles", icon: FaUserCheck },
	{ name: "Matrimony Profiles", link: "/matrimonyProfiles", icon: RiHeart2Fill },
	{
		name: "Discussion",
		link: "/discussion",
		icon: BsChatDotsFill,
		subItems: [
			{ name: "Discussions", link: "/discussions", icon: BsChatDotsFill },
			{
				name: "Discussions Categories",
				link: "/discussionCategories",
				icon: AiFillWechat,
			},
		],
	},
	{
		name: "Business",
		link: "/businesse",
		icon: BsBriefcaseFill,
		subItems: [
			{ name: "Businesses", link: "/businesses", icon: BsBriefcaseFill },
			{ name: "Products Categories", link: "/productCategories", icon: BsBox },
			{
				name: "Units",
				link: "/units",
				icon: BsRulers,
			},
		],
	},
	{
		name: "Organizations",
		link: "/organization",
		icon: BsBank2,
		subItems: [
			{ name: "Organizations", link: "/organizations", icon: BsBank2 },
			{
				name: "Facilities",
				link: "/facilities",
				icon: BsBullseye,
			},
		],
	},
	{ name: "SubCastes", link: "/subCastes", icon: MdCategory },
	{ name: "Surnames", link: "/surnames", icon: MdCategory },
	{ name: "Education Categories", link: "/educationCategories", icon: FaUserGraduate },
	{
		name: "Occupation Categories",
		link: "/occupationCategories",
		icon: MdWork,
	},

	{
		name: "Locations",
		link: "/locations",
		icon: MdLocationOn,
		subItems: [
			{ name: "Countries", link: "/countries", icon: BsPinMap },
			{ name: "States", link: "/states", icon: BsPinMap },
			{ name: "Districts", link: "/districts", icon: BsPinMap },
			{ name: "Talukas", link: "/talukas", icon: BsPinMap },
			{ name: "Villages", link: "/villages", icon: BsPinMap },
		],
	},
	{
		name: "Builds",
		link: "/androidBuilds",
		icon: IoBuild,
		subItems: [
			{ name: "Android Builds", link: "/androidBuilds", icon: AiOutlineAndroid },
			{ name: "iOS Builds", link: "/iosBuilds", icon: AiOutlineApple },
		],
	},
	{ name: "Logout", link: "/logout", icon: RiLogoutBoxRFill },
]

export const DashboardWrapper: FC<{
	children?: (JSX.Element | null)[] | JSX.Element | null
}> = ({ children }) => {
	const { systemStats } = useCoreContext()

	function setCounts(navItems: NavItem[]) {
		navItems.forEach((item) => {
			if (item.link === "/profiles") {
				item.count = systemStats?.profileCount
			}
			if (item.link === "/registrations") {
				item.count = systemStats?.registrationCount
			}
			if (item.link === "/users") {
				item.count = systemStats?.userCount
			}
			if (item.link === "/matrimonyProfiles") {
				item.count = systemStats?.matrimonyCount
			}
			if (item.link === "/discussions") {
				item.count = systemStats?.discussionCount
			}
			if (item.link === "/discussionsCategories") {
				item.count = systemStats?.discussionCategoryCount
			}
			if (item.link === "/organizations") {
				item.count = systemStats?.organizationCount
			}
			if (item.link === "/surnames") {
				item.count = systemStats?.surnameCount
			}
			if (item.link === "/countries") {
				item.count = systemStats?.countryCount
			}
			if (item.link === "/states") {
				item.count = systemStats?.stateCount
			}
			if (item.link === "/districts") {
				item.count = systemStats?.districtCount
			}
			if (item.link === "/talukas") {
				item.count = systemStats?.talukaCount
			}
			if (item.link === "/businesses") {
				item.count = systemStats?.businessCount
			}
			if (item.link === "/products") {
				item.count = systemStats?.productCategoryCount
			}
			if (item.link === "/units") {
				item.count = systemStats?.unitCount
			}

			if (item.subItems?.length) {
				setCounts(item.subItems)
			}
		})
	}

	setCounts(items)

	return (
		<Flex height="100vh" overflowY="hidden" direction={{ base: "column", lg: "row" }}>
			<Header items={items} />
			<SideBar items={items} />
			<Box
				width="100%"
				overflowY="auto"
				px={{ lg: 6 }}
				py={{ lg: 2 }}
				mt={{ base: "79px", lg: "0" }}
			>
				{children}
			</Box>
		</Flex>
	)
}
