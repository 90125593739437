import { FormControl, Stack, Textarea } from "@chakra-ui/react"
import { Formik } from "formik"
import { ComponentProps, FC } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { Discussion } from "src/domain/entities/discussion"
import { DiscussionCategory } from "src/domain/entities/discussionCategory"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"
import { IDiscussionsUpdateFormFields } from "./IDiscussionsUpdateFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	discussion: Discussion
	handleSubmit: FormikOnSubmit<IDiscussionsUpdateFormFields>
	discussionCategoryList: DiscussionCategory[]
}

export const DiscussionsUpdateDrawerFormView: FC<Props> = ({
	discussion,
	handleSubmit,
	discussionCategoryList,
	...rest
}) => {
	return (
		<Formik<IDiscussionsUpdateFormFields>
			initialValues={{
				text: discussion.text,
				categoryId: discussion.category?.id,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, setFieldValue, handleChange }) => {
				const discussionCategoryOptions = discussionCategoryList.map(
					(discussionCategory) => ({
						value: discussionCategory.id,
						label:
							discussionCategory.name.en +
							` (${discussionCategory.name.gu})`,
					}),
				)

				return (
					<DrawerForm
						size="sm"
						headerLabel="Update Discussion"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Discussion Cateogry */}
							<FormControl flex={2}>
								<InputLabel label="Discussion Category" />
								<ReactSelect
									name="categoryId"
									onChange={(newValue) => {
										setFieldValue(
											"categoryId",
											(newValue as SelectOption).value,
										)
									}}
									value={discussionCategoryOptions.find(
										(el) => el.value === values.categoryId,
									)}
									options={discussionCategoryOptions}
									isSearchable
								/>
							</FormControl>
							{/* Discussion Text */}
							<FormControl mt={2}>
								<InputLabel label="Discussion" />
								<Textarea
									name="text"
									value={values.text}
									onChange={handleChange}
									size={"lg"}
									width={"full"}
									height={"50vh"}
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
