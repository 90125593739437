import { FC } from "react"
import { Surname } from "../../../../domain/entities/surname"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { SurnameListView } from "./SurnameListView"

interface Props {
	list: Surname[]
	isLoading: boolean
	onUpdate: (surname: Surname) => void
	onDelete: (surname: Surname) => void
	onRelatedSurnamesButtonClick: (surname: Surname) => void
	onAddClick: () => void
}

export const SurnameListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
	onRelatedSurnamesButtonClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Surnames found." onAddClick={onAddClick} />
	}

	return (
		<SurnameListView
			list={list}
			onUpdate={onUpdate}
			onDelete={onDelete}
			onRelatedSurnamesButtonClick={onRelatedSurnamesButtonClick}
		/>
	)
}
