import { useCallback, useState } from "react"
import { stateListApi } from "../api/state/stateListApi"
import { State } from "../entities/state"
import { useAuth } from "./useAuth"

export function useStateListApi(): {
	stateList: State[]
	isLoading: boolean
	error: null | Error
	fetchStateList: (filter?: Parameters<typeof stateListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [stateList, setStateList] = useState<State[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchStateList = useCallback(
		async (filter: Parameters<typeof stateListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await stateListApi(filter, token)
				setStateList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { stateList, isLoading, error, fetchStateList }
}
