import { MultiLangText } from "src/utils/types"
import { Organization } from "../../entities/organization"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	villageId: string
	totalArea: number
	constructedArea: number
	description: MultiLangText
	locationCoordinate: {
		latitude: number
		longitude: number
	}
	featuredImageId: string
	imageIds: string[]
	facilities: {
		facilityId: string
		description: MultiLangText
	}[]
	committeeMembers: {
		profileId: string
		designation: MultiLangText
	}[]
	subCasteIds: string[]
}

export async function organizationAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/organization/add/v1"
	const requester = new ApiRequester<RequestDataShape, Organization>(endpoint)
	return await requester.sendRequest(payload, token)
}
