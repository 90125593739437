import { FC, useCallback, useState } from "react"
import { unitDeleteApi } from "src/domain/api/unit/unitDeleteApi"
import { Unit } from "src/domain/entities/unit"
import { useAuth } from "../../../domain/hooks"
import { getErrorMessage } from "../../../utils/helpers"
import DeleteItemDialog from "../../shared/DeleteItemDialog"

interface Props {
	unit: Unit
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const UnitDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	unit,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleUnitDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await unitDeleteApi({ id: unit.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [unit, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Unit: ${unit.name.en}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleUnitDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
