import { Box, FormControl, Image, Input, Stack } from "@chakra-ui/react"
import { Formik } from "formik"
import { ComponentProps, FC } from "react"
import { DrawerForm } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { File } from "src/domain/entities/file"
import { Profile } from "src/domain/entities/profile"
import { FormikOnSubmit } from "src/utils/types"
import { IProfileImageUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	profile: Profile
	handleSubmit: FormikOnSubmit<IProfileImageUpdateFormFields>
	handleUploadImage: (file: any) => void
	selectedImage?: File
}

export const ProfileImageUpdateDrawerFormView: FC<Props> = ({
	profile,
	handleSubmit,
	handleUploadImage,
	selectedImage,
	...rest
}) => {
	if (!profile) return null

	return (
		<Formik<IProfileImageUpdateFormFields>
			initialValues={{
				profileImageId: profile.profileImage?.id,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ isSubmitting }) => {
				return (
					<DrawerForm
						size="sm"
						headerLabel="Image Details Update"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Image file */}
							<FormControl flex={2}>
								<InputLabel label="Image file" />
								<Input
									name="imageFile"
									type="file"
									onChange={(e) => {
										if (!e.target.files?.[0]) return
										handleUploadImage(e.target.files?.[0])
									}}
								/>
							</FormControl>
							{/* Image preview */}
							{selectedImage ? (
								<Box>
									<Image
										src={selectedImage?.variants?.[0]}
										alt="Image preview"
										width="100%"
										height="auto"
										objectFit="cover"
										borderRadius="lg"
									/>
								</Box>
							) : null}
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
