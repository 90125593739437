import { useCallback, useState } from "react"
import { villageListApi } from "../api/village/villageListApi"
import { Village } from "../entities/village"
import { useAuth } from "./useAuth"

export function useVillageListApi(): {
	villageList: Village[]
	isLoading: boolean
	error: null | Error
	fetchVillageList: (filter?: Parameters<typeof villageListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [villageList, setVillageList] = useState<Village[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchVillageList = useCallback(
		async (filter: Parameters<typeof villageListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await villageListApi(filter, token)
				setVillageList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { villageList, isLoading, error, fetchVillageList }
}
