import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ProductCategoryAddDrawerFormController } from "./ProductCategoryAddDrawerForm"

import { ProductCategory } from "src/domain/entities/productCategory"
import { useProductCategoryListApi } from "src/domain/hooks/useProductCategoryListApi"
import { ProductCategoryDeleteDialogController } from "./ProductCategoryDeleteDialogController"
import { ProductCategoryListController } from "./ProductCategoryList"
import { ProductCategoryUpdateDrawerFormController } from "./ProductCategoryUpdateDrawerForm"

export const ProductCategoriesPage: FC = () => {
	const { productCategoryList, isLoading, fetchProductCategoryList } =
		useProductCategoryListApi()
	const {
		productCategoryList: parentProductCategoryList,
		isLoading: isParentProductCategoryListLoading,
		fetchProductCategoryList: fetchParentProductCategoryList,
	} = useProductCategoryListApi()
	const [isProductCategoryDeleteDialogOpen, setIsProductCategoryDeleteDialogOpen] =
		useState(false)
	const productCategoryAddDrawerDisclosure = useDisclosure()
	const productCategoryUpdateDrawerDisclosure = useDisclosure()

	const [selectedParentProductCategory, setSelectedParentProductCategory] =
		useState<ProductCategory>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchProductCategories = useCallback(async () => {
		return await fetchProductCategoryList({
			fetch: { childCategories: true, image: true },
			search: searchText,
			parentCategoryId: null,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchProductCategoryList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchProductCategories()
	}, [fetchProductCategories])

	useEffect(() => {
		fetchParentProductCategoryList({
			fetch: { childCategories: true, image: true },
		})
	}, [fetchParentProductCategoryList])

	const updateProductCategoryRef = useRef<ProductCategory>()
	const deleteProductCategoryRef = useRef<ProductCategory>()

	const parentProductCategoryOptions: {
		label: string
		value?: ProductCategory
	}[] = parentProductCategoryList.map((parentProductCategory) => ({
		label: parentProductCategory.name.en,
		value: parentProductCategory,
	}))

	parentProductCategoryOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text
						fontSize="2xl"
						fontWeight="bold"
						display={{ base: "none", lg: "block" }}
					>
						Product Categories
					</Text>
					<Button
						display={{ base: "none", lg: "block" }}
						m={{ base: "auto", lg: "0" }}
						size="sm"
						onClick={productCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Product Category
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Parent Category */}
					<Box width={{ base: "full", lg: "20%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="Parent Category" />
						<ReactSelect
							name="parentProductCategoryId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedParentProductCategory(val?.value)
							}}
							isLoading={isParentProductCategoryListLoading}
							value={parentProductCategoryOptions.find(
								(el) =>
									el.value?.id === selectedParentProductCategory?.id,
							)}
							options={parentProductCategoryOptions}
						/>
					</Box>
				</Flex>
				<Button
					display={{ base: "block", lg: "none" }}
					m={{ base: "auto", lg: "0" }}
					size="sm"
					onClick={productCategoryAddDrawerDisclosure.onOpen}
					colorScheme="blue"
				>
					Add Product Category
				</Button>
			</Box>
			<ProductCategoryListController
				list={productCategoryList}
				isLoading={isLoading}
				onUpdate={(productCategory: ProductCategory) => {
					updateProductCategoryRef.current = productCategory
					productCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(productCategory: ProductCategory) => {
					deleteProductCategoryRef.current = productCategory
					setIsProductCategoryDeleteDialogOpen(true)
				}}
				onAddClick={productCategoryAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={productCategoryList.length === 0}
			/>
			{productCategoryAddDrawerDisclosure.isOpen ? (
				<ProductCategoryAddDrawerFormController
					{...productCategoryAddDrawerDisclosure}
					onSuccess={() => fetchProductCategories()}
				/>
			) : null}
			{updateProductCategoryRef.current &&
			productCategoryUpdateDrawerDisclosure.isOpen ? (
				<ProductCategoryUpdateDrawerFormController
					{...productCategoryUpdateDrawerDisclosure}
					productCategory={updateProductCategoryRef.current}
					onSuccess={() => fetchProductCategories()}
				/>
			) : null}
			{deleteProductCategoryRef.current && isProductCategoryDeleteDialogOpen ? (
				<ProductCategoryDeleteDialogController
					isOpen={isProductCategoryDeleteDialogOpen}
					setIsOpen={setIsProductCategoryDeleteDialogOpen}
					productCategory={deleteProductCategoryRef.current}
					onSuccess={() => fetchProductCategories()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
