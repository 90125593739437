import { ComponentProps, FC, useCallback } from "react"
import { unitUpdateApi } from "src/domain/api/unit/unitUpdateApi"
import { Unit } from "src/domain/entities/unit"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IUnitUpdateFormFields } from "./IUnitUpdateFormFields"
import { UnitUpdateDrawerFormView } from "./UnitUpdateDrawerFormView"

type Props = Omit<ComponentProps<typeof UnitUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (unit: Unit) => void
}

export const UnitUpdateDrawerFormController: FC<Props> = ({
	unit,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IUnitUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedUnit = await unitUpdateApi(
					{
						id: unit.id,
						update: {
							...values,
							position: values.position ? +values.position : undefined,
						},
					},
					token,
				)
				onSuccess && onSuccess(updatedUnit)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, unit.id],
	)

	return <UnitUpdateDrawerFormView unit={unit} handleSubmit={handleSubmit} {...rest} />
}
