import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AuthContextProvider } from "./components/context/auth"
import { CoreContextProvider } from "./components/context/core"
import { HomePage, LoginPage, LogoutPage, NotFoundPage } from "./components/pages"
import { AndroidBuildsPage } from "./components/pages/AndroidBuildsPage"
import { BusinessesPage } from "./components/pages/BusinessesPage"
import { CountriesPage } from "./components/pages/CountriesPage"
import { DiscussionCategoriesPage } from "./components/pages/DiscussionCategoriesPage"
import { DiscussionsPage } from "./components/pages/DiscussionsPage"
import { DistrictsPage } from "./components/pages/DistrictsPage"
import { EducationCategoriesPage } from "./components/pages/EducationCategoriesPage"
import { FacilitiesPage } from "./components/pages/FacilitiesPage"
import { IosBuildsPage } from "./components/pages/IosBuildsPage"
import { MatrimonyProfilesPage } from "./components/pages/MatrimonyProfilesPage"
import { OccupationCategoriesPage } from "./components/pages/OccupationCategoriesPage"
import { OrganizationsPage } from "./components/pages/OrganizationsPage"
import { ProductCategoriesPage } from "./components/pages/ProductCategoriesPage"
import { ProfilesPage } from "./components/pages/ProfilesPage"
import { RegistrationsPage } from "./components/pages/RegistrationsPage"
import { RegistrationDetailsController } from "./components/pages/RegistrationsPage/RegistrationDetails"
import { StatesPage } from "./components/pages/StatesPage"
import { SubCastesPage } from "./components/pages/SubCastesPage"
import { SurnamesPage } from "./components/pages/SurnamesPage"
import { TalukasPage } from "./components/pages/TalukasPage"
import { UnitsPage } from "./components/pages/UnitsPage"
import { UsersPage } from "./components/pages/UsersPage"
import { VillagesPage } from "./components/pages/VillagesPage"
import { PrivateRoute } from "./components/shared/PrivateRoute"

export const App: React.FC = () => (
	<ChakraProvider>
		<AuthContextProvider>
			<CoreContextProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/logout" element={<LogoutPage />} />
						<Route
							path="/"
							element={<PrivateRoute element={<HomePage />} />}
						/>

						<Route
							path="/subCastes"
							element={<PrivateRoute element={<SubCastesPage />} />}
						/>

						<Route
							path="/surnames"
							element={<PrivateRoute element={<SurnamesPage />} />}
						/>

						<Route
							path="/countries"
							element={<PrivateRoute element={<CountriesPage />} />}
						/>

						<Route
							path="/states"
							element={<PrivateRoute element={<StatesPage />} />}
						/>

						<Route
							path="/districts"
							element={<PrivateRoute element={<DistrictsPage />} />}
						/>

						<Route
							path="/talukas"
							element={<PrivateRoute element={<TalukasPage />} />}
						/>

						<Route
							path="/villages"
							element={<PrivateRoute element={<VillagesPage />} />}
						/>

						<Route
							path="/registrations"
							element={<PrivateRoute element={<RegistrationsPage />} />}
						/>

						<Route
							path="/businesses"
							element={<PrivateRoute element={<BusinessesPage />} />}
						/>

						<Route
							path="/units"
							element={<PrivateRoute element={<UnitsPage />} />}
						/>

						<Route
							path="/registrations/:id"
							element={
								<PrivateRoute
									element={<RegistrationDetailsController />}
								/>
							}
						/>

						<Route
							path="/profiles"
							element={<PrivateRoute element={<ProfilesPage />} />}
						/>

						<Route
							path="/educationCategories"
							element={
								<PrivateRoute element={<EducationCategoriesPage />} />
							}
						/>
						<Route
							path="/productCategories"
							element={<PrivateRoute element={<ProductCategoriesPage />} />}
						/>

						<Route
							path="/occupationCategories"
							element={
								<PrivateRoute element={<OccupationCategoriesPage />} />
							}
						/>

						<Route
							path="/users"
							element={<PrivateRoute element={<UsersPage />} />}
						/>

						<Route
							path="/matrimonyProfiles"
							element={<PrivateRoute element={<MatrimonyProfilesPage />} />}
						/>

						<Route
							path="/discussionCategories"
							element={
								<PrivateRoute element={<DiscussionCategoriesPage />} />
							}
						/>

						<Route
							path="/discussions"
							element={<PrivateRoute element={<DiscussionsPage />} />}
						/>

						<Route
							path="/facilities"
							element={<PrivateRoute element={<FacilitiesPage />} />}
						/>

						<Route
							path="/organizations"
							element={<PrivateRoute element={<OrganizationsPage />} />}
						/>

						<Route
							path="/androidBuilds"
							element={<PrivateRoute element={<AndroidBuildsPage />} />}
						/>

						<Route
							path="/iosBuilds"
							element={<PrivateRoute element={<IosBuildsPage />} />}
						/>

						<Route element={<NotFoundPage />} />
					</Routes>
				</BrowserRouter>
			</CoreContextProvider>
		</AuthContextProvider>
	</ChakraProvider>
)
