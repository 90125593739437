import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../../ApiRequester"

interface RequestDataShape {
	organizationId: string
	profileId: string
	designation: MultiLangText
}

export async function organizationCommitteeMemberAddApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/organization/committee/member/add/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
