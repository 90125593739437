import { MultiLangText } from "src/utils/types"
import { Organization } from "../../entities/organization"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: MultiLangText
		villageId?: string
		totalArea?: number
		locationCoordinate?: {
			latitude?: number
			longitude?: number
		}
		description?: MultiLangText
		constructedArea?: number
		featuredImageId?: string
		imageIds?: string[]
		subCasteIds?: string[]
	}
}

export async function organizationUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/organization/update/v1"
	const requester = new ApiRequester<RequestDataShape, Organization>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
