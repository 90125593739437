import Select, { Props } from "react-select"

export interface SelectOption<T = string> {
	value: T | null
	label: string
}

function CustomSelect<Option extends SelectOption>(props: Props<Option>) {
	return <Select {...props} theme={(theme) => ({ ...theme, borderRadius: 5 })} />
}

type ComponentProps = Omit<React.ComponentProps<typeof CustomSelect>, "defaultValue"> & {
	defaultValue?: string
}

export const ReactSelect: React.FC<ComponentProps> = ({ defaultValue, ...rest }) => {
	const label: string =
		rest.options && defaultValue
			? rest.options.find(
					(option) => (option as SelectOption).value === defaultValue,
			  )?.label || ""
			: ""

	const value = typeof defaultValue === "string" ? defaultValue : ""

	return <CustomSelect defaultValue={{ label, value }} {...rest} />
}
