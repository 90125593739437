import { Surname } from "../../entities/surname"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	surnameId: string
	relatedSurnameId: string
}

export async function surnameRelateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/surname/relate/v1"
	const requester = new ApiRequester<RequestDataShape, Surname>(endpoint)
	return await requester.sendRequest(payload, token)
}
