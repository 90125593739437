import { MultiLangText } from "src/utils/types"
import { OccupationCategory } from "../../entities/occupationCategory"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	parentCategoryId?: string
}

export async function occupationCategoryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/occupationCategory/add/v1"
	const requester = new ApiRequester<RequestDataShape, OccupationCategory>(endpoint)
	return await requester.sendRequest(payload, token)
}
