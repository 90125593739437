import { MobileAppBuildStatus } from "src/utils/enums"
import { RequestPagination } from "src/utils/types"
import { IosBuild } from "../../entities/iosBuild"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	buildNo?: number
	version?: string
	publishDate?: number
	status?: MobileAppBuildStatus
	pagination?: RequestPagination
}

export async function iosBuildListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/ios/build/list/v1"
	const requester = new ApiRequester<RequestDataShape, IosBuild[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
