import { Box, Center } from "@chakra-ui/react"
import { FC } from "react"
import { LoginFormContainer } from "./LoginForm"

export const LoginPage: FC<{ children?: JSX.Element }> = () => {
	return (
		<Box backgroundColor="gray.50">
			<Center height={"100vh"}>
				<LoginFormContainer />
			</Center>
		</Box>
	)
}
