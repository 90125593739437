import {
	Avatar,
	Flex,
	IconButton,
	Stack,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { IoNotifications } from "react-icons/io5"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { Discussion } from "src/domain/entities/discussion"
import { formatDate } from "src/utils/helpers"

export const DiscussionListView: FC<{
	list: Discussion[]
	onProfileClick: (profileId: string) => void
	onDiscussionClick: (discussionId: string) => void
	onDelete: (discussion: Discussion) => void
	onNotify: (discussion: Discussion) => void
	onUpdate: (discussion: Discussion) => void
}> = ({ list, onDelete, onNotify, onProfileClick, onUpdate, onDiscussionClick }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Replies</Th>
				<Th>Created By</Th>
				<Th>Created At</Th>
				<Th>Action</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((discussion, i) => (
				<Tr key={i} onClick={() => onDiscussionClick(discussion.id)}>
					<Td fontWeight={"medium"} width={["50%"]}>
						{discussion.text}
					</Td>
					<Td>{discussion.totalReplies}</Td>
					<Td
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<Stack
							direction={"row"}
							alignItems={"center"}
							onClick={
								discussion.createdBy?.profile
									? () =>
											onProfileClick(
												discussion.createdBy!.profile!.id,
											)
									: () => {}
							}
						>
							<Avatar
								size={"xs"}
								border={"1px"}
								borderColor={"#d5d5d5"}
								name={discussion.createdBy?.id}
								src={discussion.createdBy?.profile?.profileImageUrl}
							/>
							<span>
								{discussion.createdBy?.profile?.firstName.en ?? ""}{" "}
								{discussion.createdBy?.profile?.surname?.name.en ?? ""}
							</span>
						</Stack>
					</Td>
					<Td>
						{formatDate(discussion.createdAt, {
							includeTime: true,
						})}
					</Td>
					<Flex ml={"5"} my={"2"}>
						<IconButton
							aria-label="notify"
							size={"sm"}
							backgroundColor={"#f5f5f500"}
							color={"blue.600"}
							icon={<IoNotifications />}
							onClick={(e) => {
								e.stopPropagation()
								onNotify(discussion)
							}}
						/>
						<EditIconButton onClick={() => onUpdate(discussion)} />
						<DeleteIconButton onClick={() => onDelete(discussion)} />
					</Flex>
				</Tr>
			))}
		</Tbody>
	</Table>
)
