import {
	Box,
	Button,
	Card,
	Flex,
	Grid,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Switch,
	Tag,
	Text,
	Wrap,
} from "@chakra-ui/react"
import { FC, useState } from "react"
import { BsImage, BsMap, BsPeople, BsPinMap, BsThreeDotsVertical } from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { getVillageName } from "src/utils/helpers"
import { Organization } from "../../../../domain/entities/organization"

export const OrganizationListView: FC<{
	list: Organization[]
	onMemberButtonClick: (organization: Organization) => void
	handleOrganizationVerificationChange: (
		val: boolean,
		organization: Organization,
	) => Promise<void>
	onUpdate: (organization: Organization) => void
	onDelete: (organization: Organization) => void
}> = ({
	list,
	onUpdate,
	onDelete,
	onMemberButtonClick,
	handleOrganizationVerificationChange,
}) => (
	<>
		<Grid
			templateColumns={{
				base: "repeat(1, 1fr)",
				sm: "repeat(2, 1fr)",
				md: "repeat(3, 1fr)",
			}}
			gap={6}
		>
			{list.map((organization, i) => {
				return (
					<OrganizationCard
						key={i}
						organization={organization}
						onDelete={onDelete}
						onUpdate={onUpdate}
						onMemberButtonClick={onMemberButtonClick}
						handleOrganizationVerificationChange={
							handleOrganizationVerificationChange
						}
					/>
				)
			})}
		</Grid>
	</>
)

const OrganizationCard: FC<{
	organization: Organization
	onMemberButtonClick: (organization: Organization) => void
	handleOrganizationVerificationChange: (
		val: boolean,
		organization: Organization,
	) => Promise<void>
	onUpdate: (organization: Organization) => void
	onDelete: (organization: Organization) => void
}> = ({
	organization,
	onDelete,
	onUpdate,
	onMemberButtonClick,
	handleOrganizationVerificationChange,
}) => {
	const [isMapOpen, setIsMapOpen] = useState(false)

	return (
		<Card m={"2"} rounded={"xl"} overflow={"hidden"}>
			<Box
				backgroundImage={
					isMapOpen
						? `url(https://api.mapbox.com/styles/v1/harshit-sangani/cleq4c43b002h01lb8gmru2sk/static/${organization.locationCoordinate.longitude},${organization.locationCoordinate.latitude},15,0/300x200?access_token=pk.eyJ1IjoiaGFyc2hpdC1zYW5nYW5pIiwiYSI6ImNsZXExYWRiZDAwbmczeHBrZTczM2Jwd2MifQ.MCbULrGBAqqspCgXc4nlMg)`
						: `url(${organization.featuredImageUrl})`
				}
				height={"200px"}
				backgroundSize={"cover"}
				backgroundPosition={"center"}
				position={"relative"}
			>
				<Flex
					h={"full"}
					w={"full"}
					background={
						"linear-gradient(0deg, rgba(9,7,40,1) 0%, rgba(18,18,42,1) 5%, rgba(9,9,121,0) 100%)"
					}
					px={4}
					py={2}
					direction={"column"}
					justify={"flex-end"}
					color={"white"}
				>
					<Text fontWeight={"semibold"}>{organization.name.en}</Text>
					<Flex align={"center"} gap={2}>
						<Flex flex={1} alignItems={"center"}>
							<BsPinMap size={12} />
							<Text fontSize={"xs"} ml={2}>
								{getVillageName(organization.village)}
							</Text>
						</Flex>

						<Switch
							pl={4}
							isChecked={organization.isVerified}
							onChange={(val) => {
								handleOrganizationVerificationChange(
									val.target.checked,
									organization,
								)
							}}
						/>
					</Flex>
					<Flex position={"absolute"} top={2} right={2}>
						<Menu>
							<MenuButton
								as={Button}
								size={"sm"}
								mx="1"
								onClick={(e) => {
									e.stopPropagation()
								}}
								aria-label="Options"
								backgroundColor={"gray.700"}
								_hover={{ backgroundColor: "gray.600" }}
								_focus={{ backgroundColor: "gray.600" }}
								_active={{ backgroundColor: "gray.600" }}
							>
								<BsThreeDotsVertical size={16} />
							</MenuButton>
							<MenuList>
								<MenuItem
									onClick={(e) => {
										e.stopPropagation()
										onUpdate(organization)
									}}
									icon={<EditIconButton />}
									color={"yellow.600"}
								>
									Edit
								</MenuItem>
								<MenuItem
									onClick={(e) => {
										e.stopPropagation()
										onDelete(organization)
									}}
									icon={<DeleteIconButton />}
									color={"red.600"}
								>
									Delete
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
					<Button
						position={"absolute"}
						top={2}
						left={2}
						cursor={"pointer"}
						onClick={(e) => {
							e.stopPropagation()
							setIsMapOpen(!isMapOpen)
						}}
						variant={"ghost"}
						size={"sm"}
						_hover={{ backgroundColor: "gray.600" }}
						_focus={{ backgroundColor: "gray.600" }}
						_active={{ backgroundColor: "gray.600" }}
					>
						{isMapOpen ? <BsImage size={16} /> : <BsMap size={16} />}
					</Button>
					<Button
						position={"absolute"}
						top={2}
						left={14}
						cursor={"pointer"}
						onClick={(e) => {
							e.stopPropagation()
							onMemberButtonClick(organization)
						}}
						variant={"ghost"}
						size={"sm"}
						_hover={{ backgroundColor: "gray.600" }}
						_focus={{ backgroundColor: "gray.600" }}
						_active={{ backgroundColor: "gray.600" }}
					>
						<Flex align={"center"} gridGap={1}>
							{<BsPeople size={16} />}{" "}
							{organization.committeeMembers?.length
								? organization.committeeMembers.length
								: null}
						</Flex>
					</Button>
				</Flex>
			</Box>
			<Box p={4}>
				<Wrap gap={2}>
					{organization.facilities?.map((facility, i) => (
						<Tag
							key={i}
							fontSize={"sm"}
							color={facility.facility.color}
							backgroundColor={`${facility.facility.color}22`}
							borderColor={`${facility.facility.color}44`}
							borderWidth={1.5}
						>
							{facility.facility.name.en}
						</Tag>
					))}
				</Wrap>
				<Text fontSize={"sm"} color="gray.700" mt={4}>
					{organization.description.en}
				</Text>
			</Box>
		</Card>
	)
}
