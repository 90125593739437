import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { productCategoryAddApi } from "src/domain/api/productCategory/productCategoryAddApi"
import { File } from "src/domain/entities/file"
import { ProductCategory } from "src/domain/entities/productCategory"
import { useProductCategoryListApi } from "src/domain/hooks/useProductCategoryListApi"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IProductCategoryAddFormFields } from "./IProductCategoryAddFormFields"
import { ProductCategoryAddDrawerFormView } from "./ProductCategoryAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof ProductCategoryAddDrawerFormView>,
		| "handleSubmit"
		| "parentProductCategoryList"
		| "handleUploadImage"
		| "selectedImage"
		| "setSelectedImage"
	> {
	onSuccess: (productCategory: ProductCategory) => void
}

export const ProductCategoryAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)

			return response as File
		},
		[token],
	)

	const handleSubmit = useCallback<FormikOnSubmit<IProductCategoryAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const productCategory = await productCategoryAddApi(
					{ ...values, imageId: selectedImage?.id },
					token,
				)
				onSuccess(productCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchProductCategoryList, productCategoryList } = useProductCategoryListApi()

	useEffect(() => {
		fetchProductCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
				image: true,
			},
		})
	}, [fetchProductCategoryList])

	return (
		<ProductCategoryAddDrawerFormView
			handleSubmit={handleSubmit}
			parentProductCategoryList={productCategoryList}
			selectedImage={selectedImage}
			setSelectedImage={setSelectedImage}
			handleUploadImage={handleUploadImage}
			{...rest}
		/>
	)
}
