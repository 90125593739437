import { FC, useCallback, useEffect, useState } from "react"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { MatrimonyProfile } from "../../../../domain/entities/matrimonyProfile"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { ProfileDetailsModal } from "../../ProfilesPage/ProfileDetailsModal"
import { MatrimonyProfileDetailsModal } from "../MatrimonyProfileDetailsModal"
import { MatrimonyProfileListView } from "./MatrimonyProfileListView"

interface Props {
	list: MatrimonyProfile[]
	handleMatrimonyProfileVerificationChange: (
		val: boolean,
		matrimonyProfile: MatrimonyProfile,
	) => Promise<void>
	handleMatrimonyProfileActiveChange: (
		val: boolean,
		matrimonyProfile: MatrimonyProfile,
	) => Promise<void>
	isLoading: boolean
	onDelete: (matrimonyProfile: MatrimonyProfile) => void
}

export const MatrimonyProfileListController: FC<Props> = ({
	isLoading,
	list,
	handleMatrimonyProfileVerificationChange,
	handleMatrimonyProfileActiveChange,
	onDelete,
}) => {
	const [selectedMatrimonyProfileId, setSelectedMatrimonyProfileId] = useState<
		string | null
	>(null)

	const [params, setParams] = useSearchParams()

	const handleMatrimonyProfileClick = useCallback(
		(matrimonyProfileId: string) => {
			var updatedParams = createSearchParams({
				id: matrimonyProfileId,
			})
			setParams(updatedParams)
			setSelectedMatrimonyProfileId(matrimonyProfileId)
		},
		[setParams],
	)

	const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null)
	useEffect(() => {
		setTimeout(() => {
			const matrimonyProfileId = params.get("id")

			if (matrimonyProfileId) {
				handleMatrimonyProfileClick(matrimonyProfileId)
			}
		}, 0)
	}, [handleMatrimonyProfileClick, params])

	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No MatrimonyProfiles found." />
	}

	const handleProfileClick = (profileId: string) => {
		setSelectedProfileId(profileId)
	}

	return (
		<>
			<MatrimonyProfileListView
				onMatrimonyProfileClick={handleMatrimonyProfileClick}
				handleMatrimonyProfileVerificationChange={
					handleMatrimonyProfileVerificationChange
				}
				handleMatrimonyProfileActiveChange={handleMatrimonyProfileActiveChange}
				list={list}
				onDelete={onDelete}
			/>
			{selectedMatrimonyProfileId ? (
				<MatrimonyProfileDetailsModal
					isOpen={!!selectedMatrimonyProfileId}
					setIsOpen={(val) => {
						if (!val) {
							setParams(createSearchParams())
							setSelectedMatrimonyProfileId(null)
						}
					}}
					matrimonyProfileId={selectedMatrimonyProfileId}
					handleProfileClick={handleProfileClick}
				/>
			) : null}
			{selectedProfileId ? (
				<ProfileDetailsModal
					isOpen={!!selectedProfileId}
					setIsOpen={(val) => {
						if (!val) setSelectedProfileId(null)
					}}
					profileId={selectedProfileId}
					handleProfileClick={handleProfileClick}
				/>
			) : null}
		</>
	)
}
