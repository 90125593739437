import { useCallback, useState } from "react"
import { subCasteListApi } from "../api/subCaste/subCasteListApi"
import { SubCaste } from "../entities/subCaste"
import { useAuth } from "./useAuth"

export function useSubCasteListApi(): {
	subCasteList: SubCaste[]
	isLoading: boolean
	fetchSubCasteList: (filter?: Parameters<typeof subCasteListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [subCasteList, setSubCasteList] = useState<SubCaste[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchSubCasteList = useCallback(
		async (filter: Parameters<typeof subCasteListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await subCasteListApi(filter, token)
			setSubCasteList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { subCasteList, isLoading, fetchSubCasteList }
}
