import { useCallback, useState } from "react"
import { countryListApi } from "../api/country/countryListApi"
import { Country } from "../entities/country"
import { useAuth } from "./useAuth"

export function useCountryListApi(): {
	countryList: Country[]
	isLoading: boolean
	fetchCountryList: (filter?: Parameters<typeof countryListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [countryList, setCountryList] = useState<Country[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchCountryList = useCallback(
		async (filter: Parameters<typeof countryListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await countryListApi(filter, token)
			setCountryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { countryList, isLoading, fetchCountryList }
}
