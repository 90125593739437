import { useToast } from "@chakra-ui/react"
import moment from "moment"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { profileUpdateApi } from "src/domain/api/profile/profileUpdateApi"
import { Profile } from "src/domain/entities/profile"
import { DomainError } from "src/domain/errors"
import { useAuth, useVillageListApi } from "src/domain/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IProfileLocationUpdateFormFields } from "./IProfileLocationUpdateFormFields"
import { ProfileLocationUpdateDrawerFormView } from "./ProfileLocationUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof ProfileLocationUpdateDrawerFormView>,
	| "handleSubmit"
	| "nativeVillageList"
	| "nativeVillageSearchText"
	| "setNativeVillageSearchText"
	| "currentVillageList"
	| "currentVillageSearchText"
	| "setCurrentVillageSearchText"
> & {
	onSuccess?: (profileLocation: Profile) => void
}

export const ProfileLocationUpdateDrawerFormController: FC<Props> = ({
	profile,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()
	const toast = useToast()

	const handleSubmit = useCallback<FormikOnSubmit<IProfileLocationUpdateFormFields>>(
		async (values) => {
			try {
				if (!profile) return

				if (values.dateOfBirth) {
					values.dateOfBirth = moment(values.dateOfBirth).unix() * 1000
				}
				if (values.dateOfDeath) {
					values.dateOfDeath = moment(values.dateOfDeath).unix() * 1000
				}

				const updatedState = await profileUpdateApi(
					{ id: profile.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedState)
				rest.onClose()
			} catch (err) {
				if (err instanceof DomainError) {
					toast({
						title: err.name,
						description: err.message,
						status: "error",
						position: "bottom-right",
						isClosable: true,
						duration: 10000,
					})
				}
			}
		},
		[onSuccess, rest, token, profile, toast],
	)
	const { fetchVillageList: fetchNativeVillageList, villageList: nativeVillageList } =
		useVillageListApi()

	const [nativeVillageSearchText, setNativeVillageSearchText] = useState<string>(
		profile.nativeVillage?.name.en || "",
	)

	useEffect(() => {
		fetchNativeVillageList({
			search: nativeVillageSearchText,
			fetch: {
				talukaDistrictState: true,
			},
			pagination: {
				page: 1,
				limit: 20,
			},
		})
	}, [fetchNativeVillageList, nativeVillageSearchText, profile])

	const { fetchVillageList: fetchCurrentVillageList, villageList: currentVillageList } =
		useVillageListApi()

	const [currentVillageSearchText, setCurrentVillageSearchText] = useState<string>(
		profile.currentVillage?.name.en || "",
	)

	useEffect(() => {
		fetchCurrentVillageList({
			search: currentVillageSearchText,
			fetch: {
				talukaDistrictState: true,
			},
			pagination: {
				page: 1,
				limit: 20,
			},
		})
	}, [fetchCurrentVillageList, currentVillageSearchText, profile])

	return (
		<ProfileLocationUpdateDrawerFormView
			profile={profile}
			handleSubmit={handleSubmit}
			nativeVillageList={nativeVillageList}
			nativeVillageSearchText={nativeVillageSearchText}
			setNativeVillageSearchText={setNativeVillageSearchText}
			currentVillageList={currentVillageList}
			currentVillageSearchText={currentVillageSearchText}
			setCurrentVillageSearchText={setCurrentVillageSearchText}
			{...rest}
		/>
	)
}
