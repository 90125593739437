import { ComponentProps, FC, useCallback, useEffect } from "react"
import { discussionReplyUpdateApi } from "src/domain/api/discussionReply/discussionReplyUpdateApi"
import { DiscussionReply } from "src/domain/entities/discussionReply"
import { useAuth } from "src/domain/hooks"
import { useDiscussionReplyListApi } from "src/domain/hooks/useDiscussionReplyListApi"
import { FormikOnSubmit } from "src/utils/types"
import { DiscussionsReplyUpdateDrawerFormView } from "./DiscussionsReplyUpdateDrawerFormView"
import { IDiscussionsReplyUpdateFormFields } from "./IDiscussionsReplyUpdateFormFields"

type Props = Omit<
	ComponentProps<typeof DiscussionsReplyUpdateDrawerFormView>,
	"handleSubmit" | "discussionReplyList"
> & {
	discussionReply: DiscussionReply
	onSuccess?: (discussionReply: DiscussionReply) => void
}

export const DiscussionsReplyUpdateDrawerFormController: FC<Props> = ({
	discussionReply,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IDiscussionsReplyUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedDiscussionReply = await discussionReplyUpdateApi(
					{
						id: discussionReply.id,
						createdById: discussionReply.createdBy?.id,
						update: {
							...values,
							text: values.text ?? "",
						},
					},
					token,
				)
				onSuccess && onSuccess(updatedDiscussionReply)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ text: message })
			}
		},
		[onSuccess, rest, token, discussionReply.id],
	)

	const { fetchDiscussionReplyList, discussionReplyList } = useDiscussionReplyListApi()

	useEffect(() => {
		fetchDiscussionReplyList()
	}, [fetchDiscussionReplyList])

	return (
		<DiscussionsReplyUpdateDrawerFormView
			discussionReply={discussionReply}
			discussionReplyList={discussionReplyList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
