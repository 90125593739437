import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { SubCaste } from "src/domain/entities/subCaste"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { useProfileListApi, useSubCasteListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { ProfileListController } from "./ProfileList"

import { debounce } from "lodash"
import { Profile } from "src/domain/entities/profile"
import { ProfileAddDrawerFormController } from "./ProfileAddDrawerForm"
import { ProfileBasicUpdateDrawerFormController } from "./ProfileBasicUpdateDrawerForm"
import { ProfileDeleteDialogController } from "./ProfileDeleteDialogController"
import { ProfileImageUpdateDrawerFormController } from "./ProfileImageUpdateDrawerForm"
import { ProfileLocationUpdateDrawerFormController } from "./ProfileLocationUpdateDrawerForm"

export const ProfilesPage: FC = () => {
	const [isProfileDeleteDialogOpen, setIsProfileDeleteDialogOpen] = useState(false)
	const deleteProfileRef = useRef<Profile>()
	const profileAddDrawerDisclosure = useDisclosure()
	const profileBasicUpdateDrawerDisclosure = useDisclosure()
	const profileImageUpdateDrawerDisclosure = useDisclosure()
	const profileLocationUpdateDrawerDisclosure = useDisclosure()
	const [selectedProfile, setSelectedProfile] = useState<Profile>()

	const { profileList, isLoading, fetchProfileList } = useProfileListApi()

	const { subCasteList, fetchSubCasteList } = useSubCasteListApi()

	const [selectedSubCaste, setSelectedSubCaste] = useState<SubCaste>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const searchText = useRef("")
	const [searchInputText, setSearchInputText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const [selectedIsVerifiedFilter, setSelectedIsVerifiedFilter] = useState<boolean>()

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchProfiles = useCallback(async () => {
		return await fetchProfileList({
			fetch: {
				surname: {
					subCaste: true,
				},
				nativeVillage: {
					taluka: true,
					district: true,
					state: true,
				},
				currentVillage: {
					taluka: true,
					district: true,
					state: true,
					country: true,
				},
				reviewedBy: {
					details: true,
				},
			},
			search: searchText.current,
			subCasteId: selectedSubCaste?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			isVerified: selectedIsVerifiedFilter,
		})
	}, [
		fetchProfileList,
		pagination.currentPage,
		selectedSubCaste?.id,
		selectedIsVerifiedFilter,
	])

	const isVerifiedOptions: {
		label: string
		value?: boolean
	}[] = [
		{
			label: "All",
			value: undefined,
		},
		{
			label: "Verified",
			value: true,
		},
		{
			label: "Un Verified",
			value: false,
		},
	]

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchProfiles = useCallback(
		debounce(async () => {
			await fetchProfiles()
		}, 500),
		[fetchProfiles],
	)

	useEffect(() => {
		fetchProfiles()
	}, [fetchProfiles])

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const subCasteOptions: {
		label: string
		value?: SubCaste
	}[] = subCasteList.map((subCaste) => ({
		label: subCaste.name.en,
		value: subCaste,
	}))

	subCasteOptions.unshift({
		label: "All",
		value: undefined,
	})

	const handleEditButtonClick = async (
		profile: Profile,
		type: "basic" | "image" | "location",
	) => {
		setSelectedProfile(profile)
		if (type === "basic") {
			profileBasicUpdateDrawerDisclosure.onOpen()
		} else if (type === "image") {
			profileImageUpdateDrawerDisclosure.onOpen()
		} else if (type === "location") {
			profileLocationUpdateDrawerDisclosure.onOpen()
		}
	}

	return (
		<DashboardWrapper>
			<Box padding={2} display={{ base: "none", lg: "contents" }}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Profiles
					</Text>
					<Button
						display={{ base: "none", lg: "block" }}
						size="sm"
						onClick={profileAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Profile
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box
						maxWidth={{ base: "full", lg: "50%" }}
						my={{ base: "2", lg: "0" }}
					>
						<InputGroup>
							<Input
								value={searchInputText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									searchText.current = e.target.value
									setSearchInputText(e.target.value)
									debouncedFetchProfiles()
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									searchText.current = el
									setSearchInputText(el)
									debouncedFetchProfiles()
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Sub Caste */}
					<Box width={{ base: "full", lg: "20%" }}>
						<InputLabel label="Sub Caste" />
						<ReactSelect
							name="subCasteId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedSubCaste(val?.value)
							}}
							value={subCasteOptions.find(
								(el) => el.value?.id === selectedSubCaste?.id,
							)}
							options={subCasteOptions}
						/>
					</Box>

					{/* Verification */}
					<Box width={{ base: "100%", lg: "15%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Verification" />
						<ReactSelect
							name="verification"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedIsVerifiedFilter(val?.value)
							}}
							value={isVerifiedOptions.find(
								(el) => el.value === selectedIsVerifiedFilter,
							)}
							options={isVerifiedOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<ProfileListController
				list={profileList}
				isLoading={isLoading}
				handleEditButtonClick={handleEditButtonClick}
				onDelete={(profile) => {
					deleteProfileRef.current = profile
					setIsProfileDeleteDialogOpen(true)
				}}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={profileList.length === 0}
			/>
			{profileAddDrawerDisclosure.isOpen ? (
				<ProfileAddDrawerFormController
					{...profileAddDrawerDisclosure}
					onSuccess={() => fetchProfiles()}
				/>
			) : null}
			{profileBasicUpdateDrawerDisclosure.isOpen && selectedProfile ? (
				<ProfileBasicUpdateDrawerFormController
					{...profileBasicUpdateDrawerDisclosure}
					profile={selectedProfile}
					onSuccess={() => fetchProfiles()}
				/>
			) : null}
			{profileImageUpdateDrawerDisclosure.isOpen && selectedProfile ? (
				<ProfileImageUpdateDrawerFormController
					{...profileImageUpdateDrawerDisclosure}
					profile={selectedProfile}
					onSuccess={() => fetchProfiles()}
				/>
			) : null}
			{profileLocationUpdateDrawerDisclosure.isOpen && selectedProfile ? (
				<ProfileLocationUpdateDrawerFormController
					{...profileLocationUpdateDrawerDisclosure}
					profile={selectedProfile}
					onSuccess={() => fetchProfiles()}
				/>
			) : null}
			{deleteProfileRef.current && isProfileDeleteDialogOpen ? (
				<ProfileDeleteDialogController
					isOpen={isProfileDeleteDialogOpen}
					setIsOpen={setIsProfileDeleteDialogOpen}
					profile={deleteProfileRef.current}
					onSuccess={() => fetchProfiles()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
