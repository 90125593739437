import {
	Box,
	Card,
	Checkbox,
	Flex,
	Image,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsFilePerson, BsGeo, BsKey, BsPerson } from "react-icons/bs"
import { AuthMethodTag } from "src/components/shared/AuthMethodTag"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { getUserFullName } from "src/domain/entities/user"
import { formatDate } from "src/utils/helpers"
import { Registration } from "../../../../domain/entities/registration"
import { getReviewStatusDetails } from "../RegistrationDetails"

export const RegistrationListView: FC<{
	list: Registration[]
	onRegistrationClick: (registration: Registration) => void
	selectedRegistrations: Registration[]
	handleSelectRegistration: (registration: Registration) => void
	handleSelectAllRegistrations: () => void
	onDelete: (registration: Registration) => void
}> = ({
	list,
	onRegistrationClick,
	selectedRegistrations,
	handleSelectRegistration,
	handleSelectAllRegistrations,
	onDelete,
}) => {
	function getUserAvatar(registration: Registration) {
		return registration.identity?.profileImageUrl ? (
			<Image
				style={{ aspectRatio: "1" }}
				src={registration.identity?.profileImageUrl}
				height="50px"
				rounded={"lg"}
			/>
		) : (
			<Flex
				height={50}
				width={50}
				bgColor="gray.100"
				rounded={"lg"}
				justify="center"
				align={"center"}
				color="gray.500"
			>
				<BsPerson size={20} />
			</Flex>
		)
	}

	function getUserName(registration: Registration) {
		return (
			<Box>
				<Text fontWeight={500}>
					{registration.basic?.firstName?.en}{" "}
					{registration.basic?.surname
						? registration.basic?.surname?.name.en
						: registration.basic?.customSurname?.text?.en}
				</Text>
				<Text>
					{registration.basic?.firstName?.gu}{" "}
					{registration.basic?.surname
						? registration.basic?.surname?.name.gu
						: registration.basic?.customSurname?.text?.gu}
				</Text>
			</Box>
		)
	}

	function getSteps(registration: Registration) {
		return (
			<Box>
				<Flex>
					{[
						true,
						registration.basic,
						registration.location,
						registration.identity,
					].map((step, index) => (
						<Flex align={"center"} key={index}>
							<Text as={"span"} color={step ? "blue.500" : "gray.400"}>
								{index === 0 ? (
									<BsKey size={16} />
								) : index === 1 ? (
									<BsPerson size={16} />
								) : index === 2 ? (
									<BsGeo size={16} />
								) : index === 3 ? (
									<BsFilePerson size={16} />
								) : (
									<>?</>
								)}
							</Text>
							{index !== 3 ? (
								<Box
									height={1}
									mx={1}
									width={5}
									borderRadius={1}
									bgColor="gray.200"
								></Box>
							) : null}
						</Flex>
					))}
				</Flex>
			</Box>
		)
	}

	function getReviewStatus(registration: Registration) {
		return (
			<Box>
				{registration.reviews?.length ? (
					<Flex>
						{[...(registration.reviews ?? [])]
							.slice(0, 5)
							.reverse()
							.map((review, index) => (
								<Flex align={"center"} key={index}>
									<Tooltip
										label={review.note.en}
										hasArrow
										placement="top"
										openDelay={300}
										bgColor={"#334155"}
										rounded={"md"}
										p={2}
									>
										<Text
											as={"span"}
											color={
												getReviewStatusDetails(review.status)
													.color
											}
										>
											{getReviewStatusDetails(review.status).icon}
										</Text>
									</Tooltip>
									{index !==
									registration.reviews!.slice(0, 5).length - 1 ? (
										<Box
											height={1}
											mx={1}
											width={5}
											borderRadius={1}
											bgColor="gray.200"
										></Box>
									) : null}
								</Flex>
							))}
					</Flex>
				) : (
					<Text fontSize={"sm"} color="gray.400">
						Not started
					</Text>
				)}
			</Box>
		)
	}

	return (
		<>
			<Table size={"sm"} display={{ base: "none", lg: "table" }}>
				<Thead>
					<Tr>
						<Th>
							<Checkbox
								checked={selectedRegistrations.length === list.length}
								onChange={handleSelectAllRegistrations}
							/>
						</Th>
						<Th>Avatar</Th>
						<Th textAlign={"center"}>Auth Method</Th>
						<Th>Name</Th>
						<Th>Step</Th>
						<Th>Review</Th>
						<Th>Reviewer</Th>
						<Th>Updated At</Th>
						<Th>Created At</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((registration) => {
						const isSelected = !!selectedRegistrations.find(
							(e) => e.id === registration.id,
						)

						return (
							<Tr
								key={registration.id}
								style={{ cursor: "pointer" }}
								_hover={{ bg: "gray.100" }}
								onClick={() => onRegistrationClick(registration)}
							>
								<Td onClick={(e) => e.stopPropagation()}>
									<Checkbox
										isChecked={isSelected}
										onChange={() =>
											handleSelectRegistration(registration)
										}
									/>
								</Td>
								<Td>{getUserAvatar(registration)}</Td>
								<Td>
									<AuthMethodTag
										authMethod={registration.user?.authMethod}
									/>
								</Td>
								<Td>{getUserName(registration)}</Td>
								<Td>{getSteps(registration)}</Td>
								<Td>{getReviewStatus(registration)}</Td>
								<Td>
									<Box>
										{getUserFullName(registration.reviewer) ?? (
											<Text fontSize={"sm"} color="gray.400">
												No reviewer
											</Text>
										)}
									</Box>
									<Box>
										{getUserFullName(registration.reviewer, "gu")}
									</Box>
								</Td>
								<Td>
									{formatDate(registration.updatedAt, {
										includeTime: true,
									})}
								</Td>
								<Td>
									{formatDate(registration.createdAt, {
										includeTime: true,
									})}
								</Td>
								<Box mt={"4"} ml={"6"}>
									<DeleteIconButton
										onClick={() => onDelete(registration)}
									/>
								</Box>
							</Tr>
						)
					})}
				</Tbody>
			</Table>

			<Box display={{ lg: "none" }} mx={"2"}>
				{list.map((registration) => {
					const isSelected = !!selectedRegistrations.find(
						(e) => e.id === registration.id,
					)
					return (
						<Card
							p={"3"}
							my={"4"}
							onClick={() => onRegistrationClick(registration)}
						>
							<Flex justify={"space-between"}>
								<Flex>
									<Box>{getUserAvatar(registration)}</Box>
									<Box ml={"2"}>{getUserName(registration)}</Box>
								</Flex>
								<Flex
									onClick={(e) => e.stopPropagation()}
									direction={"column"}
									justify={"center"}
									align={"center"}
								>
									<Checkbox
										checked={isSelected}
										onChange={() =>
											handleSelectRegistration(registration)
										}
									/>
								</Flex>
							</Flex>
							<Flex justify={"space-between"} align={"center"}>
								<Flex>
									<Box mt={"2"}>Steps:</Box>
									<Box mt={"13px"} ml={"2"}>
										{getSteps(registration)}
									</Box>
								</Flex>
							</Flex>
							<Flex align={"center"} mt={"2"}>
								<Box>Review:</Box>
								<Box ml={"2"}>{getReviewStatus(registration)}</Box>
							</Flex>
							<Flex align={"center"} mt={"1.5"}>
								<Box>Reviewer:</Box>
								<Box ml={"1.5"}>
									{getUserFullName(registration.reviewer) ?? (
										<Text fontSize={"sm"} color="gray.400">
											No reviewer
										</Text>
									)}
								</Box>
							</Flex>
							<Flex align={"center"} mt={"2"}>
								<Box>Updated At:</Box>
								<Box ml={"1.5"}>
									{formatDate(registration.updatedAt, {
										includeTime: true,
									})}
								</Box>
							</Flex>
							<Flex align={"center"} mt={"2"}>
								<Box>Created At:</Box>
								<Box ml={"1.5"}>
									{formatDate(registration.createdAt, {
										includeTime: true,
									})}
								</Box>
							</Flex>
						</Card>
					)
				})}
			</Box>
		</>
	)
}
