import { RequestPagination } from "src/utils/types"
import { Organization } from "../../entities/organization"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	geographicalLocations?: {
		countryIds?: string[]
		stateIds?: string[]
		districtIds?: string[]
		talukaIds?: string[]
		villageIds?: string[]
	}
	facilityIds?: string[]
	subCasteIds?: string[]
	fetch?: {
		village?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		committeeMembers?: boolean
		facilities?: boolean
		subCaste?: boolean
	}
	pagination?: RequestPagination
}

export async function organizationListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/organization/list/v1"
	const requester = new ApiRequester<RequestDataShape, Organization[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
