import { DiscussionReply } from "src/domain/entities/discussionReply"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	discussionId?: string
	categoryId?: string
	createdById?: string
	fetch?: {
		category?: boolean
		createdBy?: {
			profile?: boolean
			profileDetails?: boolean
			registration?: boolean
			registrationDetails?: boolean
		}
		allReplies?: boolean
	}
}

export async function discussionReplyListApi(payload: RequestDataShape, token: string) {
	const endpoint = "/discussion/reply/list/v1"
	const requester = new ApiRequester<RequestDataShape, DiscussionReply[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
