import { FC } from "react"
import { AuthRole } from "src/utils/enums"
import { User } from "../../../../domain/entities/user"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { UserListView } from "./UserListView"

interface Props {
	list: User[]
	isLoading: boolean
	handleUserReviewerChange: (val: boolean, user: User) => Promise<void>
	handleLogoutButtonClick: (user: User, role: AuthRole | null) => Promise<void>
	onUserClick: (user: User) => void
}

export const UserListController: FC<Props> = ({ isLoading, list, ...rest }) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Users found." />
	}

	return <UserListView list={list} {...rest} />
}
