import { ComponentProps, FC, useCallback } from "react"
import { facilityUpdateApi } from "src/domain/api/facility/facilityUpdateApi"
import { Facility } from "../../../../domain/entities/facility"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { FacilityUpdateDrawerFormView } from "./FacilityUpdateDrawerFormView"
import { IFacilityUpdateFormFields } from "./IFacilityUpdateFormFields"

type Props = Omit<ComponentProps<typeof FacilityUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (facility: Facility) => void
}

export const FacilityUpdateDrawerFormController: FC<Props> = ({
	facility,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IFacilityUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedFacility = await facilityUpdateApi(
					{ id: facility.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedFacility)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, facility.id],
	)

	return (
		<FacilityUpdateDrawerFormView
			facility={facility}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
