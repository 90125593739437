import { MultiLangText } from "src/utils/types"
import { DiscussionCategory } from "../../entities/discussionCategory"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: MultiLangText
		icon?: string
		color?: string
	}
}

export async function discussionCategoryUpdateApi(
	payload: RequestDataShape,
	token: string,
) {
	const endpoint = "/discussion/category/update/v1"
	const requester = new ApiRequester<RequestDataShape, DiscussionCategory>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
