import { Box, Card, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { formatDate } from "src/utils/helpers"
import { Taluka } from "../../../../domain/entities/taluka"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const TalukaListView: FC<{
	list: Taluka[]
	onUpdate: (taluka: Taluka) => void
	onDelete: (taluka: Taluka) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		<Table size={"sm"} display={{ base: "none", lg: "table" }}>
			<Thead>
				<Tr>
					<Th>Name</Th>
					<Th>Name Gujarati</Th>
					<Th>District</Th>
					<Th>State</Th>
					<Th>Country</Th>
					<Th>Created At</Th>
					<Th>Updated At</Th>
					<Th isNumeric>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{list.map((taluka, i) => (
					<Tr key={i}>
						<Td>{taluka.name.en}</Td>
						<Td>{taluka.name.gu}</Td>
						<Td>
							<Text fontWeight={500}>{taluka.district?.name?.en}</Text>
							<Text>{taluka.district?.name.gu}</Text>
						</Td>
						<Td>
							<Text fontWeight={500}>
								{taluka.district?.state?.name?.en}
							</Text>
							<Text>{taluka.district?.state?.name.gu}</Text>
						</Td>
						<Td>
							<Text fontWeight={500}>
								{taluka.district?.state?.country?.name?.en}
							</Text>
							<Text>{taluka.district?.state?.country?.name.gu}</Text>
						</Td>
						<Td>{formatDate(taluka.createdAt)}</Td>
						<Td>{formatDate(taluka.updatedAt)}</Td>
						<Td isNumeric>
							<EditIconButton onClick={() => onUpdate(taluka)} />
							<DeleteIconButton onClick={() => onDelete(taluka)} />
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>

		{list.map((taluka, i) => {
			return (
				<Card key={i} display={{ base: "flex", lg: "none" }} m={"2"}>
					<Box display={"flex"} justifyContent={"space-between"} m={"2"}>
						<Box alignItems={"center"}>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									Name:
								</Box>
								<Box ml={"2"}>{taluka.name.en}</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									Name Gujarati:
								</Box>
								<Box ml={"2"} fontSize={"sm"}>
									{taluka.name.gu}
								</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									District:
								</Box>
								<Box mx={"2"}>{taluka.district?.name?.en}</Box>
								<Box fontSize={"sm"}>{taluka.district?.name?.gu}</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									State:
								</Box>
								<Box mx={"2"}>{taluka.district?.state?.name?.en}</Box>
								<Box fontSize={"sm"}>
									{taluka.district?.state?.name?.gu}
								</Box>
							</Flex>
							<Flex align={"center"}>
								<Box fontWeight={"semibold"} fontSize={"md"}>
									Country:
								</Box>
								<Box mx={"2"}>
									{taluka.district?.state?.country?.name?.en}
								</Box>
								<Box fontSize={"sm"}>
									{taluka.district?.state?.country?.name?.gu}
								</Box>
							</Flex>
						</Box>
						<Box>
							<EditIconButton onClick={() => onUpdate(taluka)} />
							<DeleteIconButton onClick={() => onDelete(taluka)} />
						</Box>
					</Box>
				</Card>
			)
		})}
	</>
)
