import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	registrationIds: string[]
}

export async function reviewResetApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/review/reset/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
