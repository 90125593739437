import { Box, Button, Flex, FormControl, IconButton, Input, Text } from "@chakra-ui/react"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { BsPerson, BsTrash2 } from "react-icons/bs"
import { ReactSelect } from "src/components/shared/ReactSelect"
import { InputLabel } from "src/components/ui/InputLabel"
import { organizationCommitteeMemberAddApi } from "src/domain/api/organization/committeeMember/organizationCommitteeMemberAddApi"
import { organizationCommitteeMemberDeleteApi } from "src/domain/api/organization/committeeMember/organizationCommitteeMemberDeleteApi"
import { Profile } from "src/domain/entities/profile"
import { useAuth, useOrganizationListApi, useProfileListApi } from "src/domain/hooks"
import { getFullName } from "src/utils/helpers"

import { Organization } from "../../../../domain/entities/organization"
import { DrawerBasic, DrawerForm } from "../../../ui"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	organization: Organization
}

export const OrganizationCommitteeMembersDrawer: FC<Props> = ({
	organization: { id: organizationId },
	...rest
}) => {
	const { token } = useAuth()

	const { fetchOrganizationList } = useOrganizationListApi()

	const [organization, setOrganization] = useState<Organization>()

	const fetchOrganizationDetails = useCallback(async () => {
		const organizationList = await fetchOrganizationList({
			fetch: {
				committeeMembers: true,
			},
			id: organizationId,
		})

		setOrganization(organizationList[0])
	}, [fetchOrganizationList, organizationId])

	useEffect(() => {
		fetchOrganizationDetails()
	}, [fetchOrganizationDetails])

	const { fetchProfileList, profileList } = useProfileListApi()
	const [profileSearchText, setProfileSearchText] = useState("")
	useEffect(() => {
		fetchProfileList({
			search: profileSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {
				surname: {},
			},
		})
	}, [fetchProfileList, profileSearchText])

	const profileOptions = profileList.map((profile) => ({
		value: profile.id,
		label: getFullName(profile),
	}))

	const [selectedProfileId, setSelectedProfileId] = useState<string>()
	const [designationEn, setDesignationEn] = useState("")
	const [designationGu, setDesignationGu] = useState("")

	const handleAddCommitteeMember = useCallback(async () => {
		if (!selectedProfileId) return alert("Please select a profile")

		await organizationCommitteeMemberAddApi(
			{
				organizationId,
				profileId: selectedProfileId,
				designation: {
					en: designationEn,
					gu: designationGu,
				},
			},
			token,
		)

		fetchOrganizationDetails()
		setSelectedProfileId(undefined)
		setDesignationEn("")
		setDesignationGu("")
	}, [
		selectedProfileId,
		designationEn,
		designationGu,
		token,
		organizationId,
		fetchOrganizationDetails,
	])

	const handleDeleteCommitteeMember = useCallback(
		async (profile: Profile) => {
			await organizationCommitteeMemberDeleteApi(
				{
					organizationId,
					profileId: profile.id,
				},
				token,
			)

			fetchOrganizationDetails()
		},
		[organizationId, token, fetchOrganizationDetails],
	)

	return (
		<DrawerBasic size={"md"} headerLabel={"Committee members"} {...rest}>
			{organization?.committeeMembers?.length ? (
				<>
					<Flex gridGap={4} direction={"column"}>
						{organization?.committeeMembers?.map((member, i) => (
							<Flex key={i} justify="space-between" align={"center"}>
								<Flex
									height={12}
									width={12}
									backgroundColor={"gray.100"}
									color={"gray.600"}
									direction="column"
									justify="center"
									align="center"
									rounded={"lg"}
									backgroundImage={
										member.profile?.profileImageUrl
											? `url(${member.profile?.profileImageUrl})`
											: undefined
									}
									backgroundSize={"cover"}
								>
									{member.profile?.profileImageUrl ? null : (
										<BsPerson size={24} />
									)}
								</Flex>
								<Box flex={1} mx={2}>
									<Text fontWeight={"semibold"}>
										{getFullName(member.profile)}
									</Text>

									<Text fontSize={"sm"} color={"gray.700"}>
										{member.designation.en}({member.designation.gu})
									</Text>
								</Box>

								<IconButton
									size={"sm"}
									aria-label="Delete"
									colorScheme={"red"}
									icon={<BsTrash2 />}
									onClick={() =>
										handleDeleteCommitteeMember(member.profile)
									}
								/>
							</Flex>
						))}
					</Flex>
					<hr style={{ marginTop: "16px", marginBottom: "16px" }} />
				</>
			) : null}
			<Flex gridGap={2} direction={"column"}>
				<FormControl>
					<InputLabel label="Member profile" />
					<ReactSelect
						onChange={(newValue) => {
							setSelectedProfileId((newValue as any).value)
						}}
						onInputChange={(text) => {
							setProfileSearchText(text)
						}}
						value={profileOptions.find(
							(option) => option.value === selectedProfileId,
						)}
						options={profileOptions}
						isSearchable
					/>
				</FormControl>
				<Flex gridGap={2} align={"flex-end"}>
					{/* Designation */}
					<FormControl flex={5}>
						<InputLabel label="Designation En" />
						<Input
							placeholder="Designation En"
							maxLength={100}
							value={designationEn}
							onChange={(e) => setDesignationEn(e.target.value)}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									handleAddCommitteeMember()
								}
							}}
						/>
					</FormControl>
					{/* Designation Gu */}
					<FormControl flex={5}>
						<InputLabel label="Designation Gu" />
						<Input
							placeholder="Designation Gu"
							maxLength={100}
							value={designationGu}
							onChange={(e) => setDesignationGu(e.target.value)}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									handleAddCommitteeMember()
								}
							}}
						/>
					</FormControl>
					<Button
						ml={4}
						onClick={handleAddCommitteeMember}
						colorScheme={"blue"}
					>
						Add
					</Button>
				</Flex>
			</Flex>
		</DrawerBasic>
	)
}
