import { useCallback, useState } from "react"
import { organizationListApi } from "../api/organization/organizationListApi"
import { Organization } from "../entities/organization"
import { useAuth } from "./useAuth"

export function useOrganizationListApi(): {
	organizationList: Organization[]
	isLoading: boolean
	error: null | Error
	fetchOrganizationList: (
		filter?: Parameters<typeof organizationListApi>[0],
	) => Promise<Organization[]>
} {
	const { token } = useAuth()
	const [organizationList, setOrganizationList] = useState<Organization[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchOrganizationList = useCallback(
		async (filter: Parameters<typeof organizationListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await organizationListApi(filter, token)
				setOrganizationList(data)
				return data
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}

			return []
		},
		[token],
	)

	return { organizationList, isLoading, error, fetchOrganizationList }
}
