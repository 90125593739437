import { useCallback, useState } from "react"
import { iosBuildListApi } from "../api/iosBuild/iosBuildListApi"
import { IosBuild } from "../entities/iosBuild"
import { useAuth } from "./useAuth"

export function useIosBuildListApi(): {
	iosBuildList: IosBuild[]
	isLoading: boolean
	error: null | Error
	fetchIosBuildList: (filter?: Parameters<typeof iosBuildListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [iosBuildList, setIosBuildList] = useState<IosBuild[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchIosBuildList = useCallback(
		async (filter: Parameters<typeof iosBuildListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await iosBuildListApi(filter, token)
				setIosBuildList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { iosBuildList, isLoading, error, fetchIosBuildList }
}
