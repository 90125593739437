import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	Image,
	Input,
	Stack,
	Tag,
	Text,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { BsImage, BsMap, BsPlusLg, BsX } from "react-icons/bs"
import ReactSelect from "react-select"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SelectOption } from "src/components/shared/ReactSelect"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { Facility } from "src/domain/entities/facility"
import { File } from "src/domain/entities/file"
import { SubCaste } from "src/domain/entities/subCaste"
import { Village } from "src/domain/entities/village"
import { useAuth } from "src/domain/hooks"
import { IOrganizationUpdateFormFields } from "."
import { Organization } from "../../../../domain/entities/organization"
import { fetchGujaratiSuggestions, getVillageName } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	organization: Organization
	handleSubmit: FormikOnSubmit<IOrganizationUpdateFormFields>
	villageList: Village[]
	setVillageSearchText: (text: string) => void
	subCasteList: SubCaste[]
	facilityList: Facility[]
	handleUploadImage: (file: any) => Promise<File>
	selectedImage?: File
	setSelectedImage: (file?: File) => void
}

export const OrganizationUpdateDrawerFormView: FC<Props> = ({
	organization,
	handleSubmit,
	villageList,
	setVillageSearchText,
	subCasteList,
	facilityList,
	handleUploadImage,
	selectedImage,
	setSelectedImage,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	const [featuredImageId, setFeaturedImageId] = useState<string>("")

	const [isFeaturedImageUploading, setIsFeaturedImageUploading] =
		useState<boolean>(false)

	useEffect(() => {
		if (selectedImage) {
			setFeaturedImageId(selectedImage.id)
		}
	}, [selectedImage])

	return (
		<Formik<IOrganizationUpdateFormFields>
			initialValues={{
				name: {
					en: organization.name.en,
					gu: organization.name.gu,
				},
				constructedArea: organization.constructedArea,
				description: {
					en: organization.description.en,
					gu: organization.description.gu,
				},
				totalArea: organization.totalArea,
				featuredImageId,
				villageId: organization.village?.id,
				locationCoordinate: {
					latitude: organization.locationCoordinate.latitude,
					longitude: organization.locationCoordinate.longitude,
				},
				subCasteIds: organization.subCastes?.map((el) => el.id) ?? [],
				imageIds: organization.images.map((i) => i.id),
			}}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleFeaturedImageSelected = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					if (!e.target.files?.[0]) return
					setIsFeaturedImageUploading(true)
					const res = await handleUploadImage(e.target.files?.[0])
					setFieldValue("featuredImageId", res.id)
					setFeaturedImageId(res.id)
					setIsFeaturedImageUploading(false)
				}

				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const villageOptions = villageList.map((village) => ({
					value: village.id,
					label: getVillageName(village),
				}))

				const subCasteOptions = subCasteList.map((subCaste) => ({
					value: subCaste.id,
					label: `${subCaste.name.en} (${subCaste.name.gu})`,
				}))

				return (
					<DrawerForm
						size="md"
						headerLabel="Update Organization"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"md"} marginX={"auto"} rowGap={2}>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={100}
											required
											autoFocus
											value={values.name?.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Name Gu */}
								<FormControl flex={5}>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={100}
										required
										value={values.name?.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
							<Flex gridGap={2}>
								{/* Total area */}
								<FormControl>
									<InputLabel label="Total area (sq.ft)" />
									<Input
										name="totalArea"
										placeholder="Total area"
										type={"number"}
										required
										value={values.totalArea}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="totalArea"
									/>
								</FormControl>
								{/* Constructed area */}
								<FormControl>
									<InputLabel label="Constructed area (sq.ft)" />
									<Input
										name="constructedArea"
										placeholder="Constructed area"
										type={"number"}
										required
										value={values.constructedArea}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="constructedArea"
									/>
								</FormControl>
							</Flex>
							<FormControl>
								<InputLabel label="Village / City" />
								<ReactSelect
									name="villageId"
									onChange={(newValue) => {
										setFieldValue(
											"villageId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(text) => {
										if (text.length) {
											setVillageSearchText(text)
										}
									}}
									value={villageOptions.find(
										(el) => el.value === values.villageId,
									)}
									options={villageOptions}
									isSearchable
								/>
							</FormControl>
							{/* Featured Image */}
							<FormControl flex={2}>
								<InputLabel label="Featured Image" />
								<Box
									position={"relative"}
									backgroundColor={"gray.100"}
									color={"gray.700"}
									rounded={"lg"}
								>
									{selectedImage?.id ? (
										<Flex
											p={4}
											align={"center"}
											justify={"space-between"}
										>
											<Flex flex={1} align={"center"}>
												<Image
													src={selectedImage?.variants?.[0]}
													alt="Image preview"
													width="30%"
													objectFit="cover"
													rounded="lg"
												/>
												<Text ml={4}>Image Selected!</Text>
											</Flex>
											<Button
												variant="outline"
												colorScheme="red"
												onClick={(e) => {
													e.stopPropagation()
													setSelectedImage(undefined)
												}}
											>
												Remove
											</Button>
										</Flex>
									) : (
										<>
											<Flex p={8}>
												{isFeaturedImageUploading ? (
													<Box mx={"auto"}>
														<CenteredSpinner />
													</Box>
												) : (
													<>
														<BsImage size={24} />
														<Text ml={2}>
															Pick an Image here...
														</Text>
													</>
												)}
											</Flex>
											<Input
												name="imageFile"
												type="file"
												height="100%"
												width="100%"
												position="absolute"
												top="0"
												left="0"
												opacity="0"
												aria-hidden="true"
												accept="image/*"
												onChange={handleFeaturedImageSelected}
												cursor="pointer"
											/>
										</>
									)}
								</Box>
							</FormControl>
							{/* More Images */}
							<FormControl flex={2}>
								<InputLabel label="More Images" />
								<Flex
									backgroundColor={"gray.100"}
									p={4}
									rounded={"lg"}
									gridGap="2"
									wrap={"wrap"}
								>
									{values.imageIds?.map((_el, i) => (
										<CustomImageInput
											key={_el}
											image={organization.images[i]}
											onImageSelected={(image: File) => {
												// set image id
												if (image) {
													setFieldValue(
														`imageIds.${i}`,
														image.id,
													)
												}
											}}
											onClearImage={() => {
												const newImageIds =
													values.imageIds?.filter(
														(_el, index) => index !== i,
													)
												setFieldValue("imageIds", newImageIds)
											}}
										/>
									))}
									{(values.imageIds?.length ?? 0) >= 10 ? null : (
										<Flex
											h={"50px"}
											w={"70px"}
											borderWidth={"2px"}
											borderColor={"gray.400"}
											color={"gray.500"}
											rounded={"lg"}
											justify={"center"}
											alignItems={"center"}
											onClick={() => {
												setFieldValue("imageIds", [
													...(values.imageIds ?? []),
													null,
												])
											}}
											cursor={"pointer"}
										>
											<BsPlusLg size={20} />
										</Flex>
									)}
								</Flex>
							</FormControl>
							{/* Sub caste checkboxes */}
							<FormControl>
								<InputLabel label="Sub castes" />
								<Flex gridGap={2} flexWrap={"wrap"}>
									{subCasteOptions.map((el, i) => (
										<Checkbox
											key={i}
											name="subCasteIds"
											onChange={(e) => {
												setFieldValue(
													"subCasteIds",
													e.target.checked
														? [
																...(values.subCasteIds ??
																	[]),
																el.value,
														  ]
														: values.subCasteIds?.filter(
																(_el) => _el !== el.value,
														  ),
												)
											}}
											isChecked={values.subCasteIds?.includes(
												el.value,
											)}
										>
											{el.label}
										</Checkbox>
									))}
								</Flex>
							</FormControl>
							{/* Coordinates */}
							<Flex gridGap={2} pt={2}>
								<Box flex={1}>
									<FormControl>
										<InputLabel label="Latitude" />
										<Input
											name="locationCoordinate.latitude"
											type="number"
											placeholder="Latitude"
											value={
												values.locationCoordinate?.latitude || ""
											}
											onChange={handleChange}
										/>
									</FormControl>
									<FormControl mt={2}>
										<InputLabel label="Longitude" />
										<Input
											name="locationCoordinate.longitude"
											type="number"
											placeholder="Longitude"
											value={
												values.locationCoordinate?.longitude || ""
											}
											onChange={handleChange}
										/>
									</FormControl>
								</Box>
								<Box flex={1}>
									{values.locationCoordinate?.latitude &&
									values.locationCoordinate?.longitude ? (
										<Image
											src={`https://api.mapbox.com/styles/v1/harshit-sangani/cleq4c43b002h01lb8gmru2sk/static/${values.locationCoordinate.longitude},${values.locationCoordinate.latitude},15,0/300x200?access_token=pk.eyJ1IjoiaGFyc2hpdC1zYW5nYW5pIiwiYSI6ImNsZXExYWRiZDAwbmczeHBrZTczM2Jwd2MifQ.MCbULrGBAqqspCgXc4nlMg`}
											alt="Map preview"
											width="100%"
											objectFit="cover"
											rounded="lg"
										/>
									) : (
										<Flex
											backgroundColor={"gray.100"}
											color={"gray.500"}
											p={8}
											rounded={"xl"}
											justify={"center"}
											alignItems={"center"}
											height="full"
										>
											<BsMap size={24} />
											<Text ml={2}>Map</Text>
										</Flex>
									)}
								</Box>
							</Flex>
							{/* Description textarea */}
							<FormControl mt={2}>
								<InputLabel label="Description" />
								<Textarea
									name="description.en"
									placeholder="Description"
									value={values.description?.en}
									onChange={handleChange}
								/>
							</FormControl>
							<FormControl mt={2}>
								<InputLabel label="Description Gujarati" />
								<Textarea
									name="description.gu"
									placeholder="Description Gujarati"
									value={values.description?.gu}
									onChange={handleChange}
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}

const CustomImageInput: FC<{
	onImageSelected: (file: File) => void
	onClearImage: () => void
	image?: File
}> = ({ onImageSelected, onClearImage, image }) => {
	const { token } = useAuth()

	const [selectedImage, setSelectedImage] = useState<File | undefined>(image)

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)
			onImageSelected(response as File)
		},
		[token, onImageSelected],
	)

	return (
		<Box position={"relative"}>
			<Flex
				h={"50px"}
				w={"70px"}
				borderWidth={"2px"}
				borderColor={"gray.400"}
				color={"gray.500"}
				rounded={"lg"}
				justify={"center"}
				alignItems={"center"}
				overflow="hidden"
			>
				{selectedImage ? (
					<>
						<Image src={selectedImage?.variants?.[0]} alt="Image preview" />
						<Flex
							pos={"absolute"}
							h={4}
							w={4}
							backgroundColor="gray.400"
							color={"gray.800"}
							opacity={"0.8"}
							rounded={"full"}
							top={-1}
							right={-1}
							justify={"center"}
							alignItems={"center"}
							cursor="pointer"
							onClick={(e) => {
								e.stopPropagation()
								setSelectedImage(undefined)
								onClearImage()
							}}
						>
							<BsX size={12} />
						</Flex>
					</>
				) : (
					<BsImage size={20} />
				)}
			</Flex>
			{selectedImage ? null : (
				<Input
					type="file"
					height="100%"
					width="100%"
					position="absolute"
					top="0"
					left="0"
					opacity="0"
					aria-hidden="true"
					accept="image/*"
					onChange={(e) => {
						if (!e.target.files?.[0]) return
						handleUploadImage(e.target.files?.[0])
					}}
					cursor="pointer"
				/>
			)}
		</Box>
	)
}
