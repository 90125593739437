import { Facility } from "../../entities/facility"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
}

export async function facilityListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/facility/list/v1"
	const requester = new ApiRequester<RequestDataShape, Facility[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
