import { Registration } from "src/domain/entities/registration"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		isActive?: boolean
		isVerified?: boolean
		isSubscriptionActive?: boolean
		profileId?: string
		identityProofImageId?: string
		aboutMe?: string
		height?: number
		weight?: number
		hobbies?: string[]
		bloodGroup?:
			| "a_positive"
			| "a_negative"
			| "b_positive"
			| "b_negative"
			| "o_positive"
			| "o_negative"
			| "ab_positive"
			| "ab_negative"
		featuredImageId?: string
		imageIds?: string[]
		residenceAddress?: string
		annualIncomeClass?:
			| "less_5_lac"
			| "bet_5_and_20_lac"
			| "bet_20_and_50_lac"
			| "more_50_lac"
		contactDetails?: string
	}
}

export async function matrimonyProfileUpdateApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/matrimony/profile/update/v1"
	const requester = new ApiRequester<RequestDataShape, Registration>(endpoint)
	return await requester.sendRequest(payload, token)
}
