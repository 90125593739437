import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { OccupationCategory } from "src/domain/entities/occupationCategory"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { useOccupationCategoryListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { OccupationCategoryAddDrawerFormController } from "./OccupationCategoryAddDrawerForm"
import { OccupationCategoryDeleteDialogController } from "./OccupationCategoryDeleteDialogController"
import { OccupationCategoryListController } from "./OccupationCategoryList"
import { OccupationCategoryUpdateDrawerFormController } from "./OccupationCategoryUpdateDrawerForm"

export const OccupationCategoriesPage: FC = () => {
	const { occupationCategoryList, isLoading, fetchOccupationCategoryList } =
		useOccupationCategoryListApi()
	const {
		occupationCategoryList: parentOccupationCategoryList,
		isLoading: isParentOccupationCategoryListLoading,
		fetchOccupationCategoryList: fetchParentOccupationCategoryList,
	} = useOccupationCategoryListApi()
	const [
		isOccupationCategoryDeleteDialogOpen,
		setIsOccupationCategoryDeleteDialogOpen,
	] = useState(false)
	const occupationCategoryAddDrawerDisclosure = useDisclosure()
	const occupationCategoryUpdateDrawerDisclosure = useDisclosure()

	const [selectedParentOccupationCategory, setSelectedParentOccupationCategory] =
		useState<OccupationCategory>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchOccupationCategories = useCallback(async () => {
		return await fetchOccupationCategoryList({
			fetch: { childCategories: true },
			search: searchText,
			parentCategoryId: null,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchOccupationCategoryList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchOccupationCategories()
	}, [fetchOccupationCategories])

	useEffect(() => {
		fetchParentOccupationCategoryList({
			fetch: { childCategories: true },
		})
	}, [fetchParentOccupationCategoryList])

	const updateOccupationCategoryRef = useRef<OccupationCategory>()
	const deleteOccupationCategoryRef = useRef<OccupationCategory>()

	const parentOccupationCategoryOptions: {
		label: string
		value?: OccupationCategory
	}[] = parentOccupationCategoryList.map((parentOccupationCategory) => ({
		label: parentOccupationCategory.name.en,
		value: parentOccupationCategory,
	}))

	parentOccupationCategoryOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text
						fontSize="2xl"
						fontWeight="bold"
						display={{ base: "none", lg: "block" }}
					>
						Occupation Categories
					</Text>
					<Button
						display={{ base: "none", lg: "block" }}
						m={{ base: "auto", lg: "0" }}
						size="sm"
						onClick={occupationCategoryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Occupation Category
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Parent Category */}
					<Box width={{ base: "full", lg: "20%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="Parent Category" />
						<ReactSelect
							name="parentOccupationCategoryId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedParentOccupationCategory(val?.value)
							}}
							isLoading={isParentOccupationCategoryListLoading}
							value={parentOccupationCategoryOptions.find(
								(el) =>
									el.value?.id === selectedParentOccupationCategory?.id,
							)}
							options={parentOccupationCategoryOptions}
						/>
					</Box>
				</Flex>
				<Button
					display={{ base: "block", lg: "none" }}
					m={{ base: "auto", lg: "0" }}
					size="sm"
					onClick={occupationCategoryAddDrawerDisclosure.onOpen}
					colorScheme="blue"
				>
					Add Occupation Category
				</Button>
			</Box>
			<OccupationCategoryListController
				list={occupationCategoryList}
				isLoading={isLoading}
				onUpdate={(occupationCategory: OccupationCategory) => {
					updateOccupationCategoryRef.current = occupationCategory
					occupationCategoryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(occupationCategory: OccupationCategory) => {
					deleteOccupationCategoryRef.current = occupationCategory
					setIsOccupationCategoryDeleteDialogOpen(true)
				}}
				onAddClick={occupationCategoryAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={occupationCategoryList.length === 0}
			/>
			{occupationCategoryAddDrawerDisclosure.isOpen ? (
				<OccupationCategoryAddDrawerFormController
					{...occupationCategoryAddDrawerDisclosure}
					onSuccess={() => fetchOccupationCategories()}
				/>
			) : null}
			{updateOccupationCategoryRef.current &&
			occupationCategoryUpdateDrawerDisclosure.isOpen ? (
				<OccupationCategoryUpdateDrawerFormController
					{...occupationCategoryUpdateDrawerDisclosure}
					occupationCategory={updateOccupationCategoryRef.current}
					onSuccess={() => fetchOccupationCategories()}
				/>
			) : null}
			{deleteOccupationCategoryRef.current &&
			isOccupationCategoryDeleteDialogOpen ? (
				<OccupationCategoryDeleteDialogController
					isOpen={isOccupationCategoryDeleteDialogOpen}
					setIsOpen={setIsOccupationCategoryDeleteDialogOpen}
					occupationCategory={deleteOccupationCategoryRef.current}
					onSuccess={() => fetchOccupationCategories()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
