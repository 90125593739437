import { MobileAppBuildStatus } from "src/utils/enums"
import { AndroidBuild } from "../../entities/androidBuild"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	buildNo: number
	version: string
	publishDate: number
	status: MobileAppBuildStatus
}

export async function androidBuildAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/android/build/add/v1"
	const requester = new ApiRequester<RequestDataShape, AndroidBuild>(endpoint)
	return await requester.sendRequest(payload, token)
}
