import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"

import { SubCaste } from "../../../domain/entities/subCaste"
import { useSubCasteListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { SubCasteAddDrawerFormController } from "./SubCasteAddDrawerForm"
import { SubCasteDeleteDialogController } from "./SubCasteDeleteDialogController"
import { SubCasteListController } from "./SubCasteList"
import { SubCasteUpdateDrawerFormController } from "./SubCasteUpdateDrawerForm"

export const SubCastesPage: FC = () => {
	const { subCasteList, isLoading, fetchSubCasteList } = useSubCasteListApi()
	const [isSubCasteDeleteDialogOpen, setIsSubCasteDeleteDialogOpen] = useState(false)
	const subCasteAddDrawerDisclosure = useDisclosure()
	const subCasteUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const updateSubCasteRef = useRef<SubCaste>()
	const deleteSubCasteRef = useRef<SubCaste>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						SubCastes
					</Text>
					<Button
						size="sm"
						onClick={subCasteAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add SubCaste
					</Button>
				</Flex>
			</Box>
			<SubCasteListController
				list={subCasteList}
				isLoading={isLoading}
				onUpdate={(subCaste: SubCaste) => {
					updateSubCasteRef.current = subCaste
					subCasteUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(subCaste: SubCaste) => {
					deleteSubCasteRef.current = subCaste
					setIsSubCasteDeleteDialogOpen(true)
				}}
				onAddClick={subCasteAddDrawerDisclosure.onOpen}
			/>
			{subCasteAddDrawerDisclosure.isOpen ? (
				<SubCasteAddDrawerFormController
					{...subCasteAddDrawerDisclosure}
					onSuccess={() => fetchSubCasteList()}
				/>
			) : null}
			{updateSubCasteRef.current && subCasteUpdateDrawerDisclosure.isOpen ? (
				<SubCasteUpdateDrawerFormController
					{...subCasteUpdateDrawerDisclosure}
					subCaste={updateSubCasteRef.current}
					onSuccess={() => fetchSubCasteList()}
				/>
			) : null}
			{deleteSubCasteRef.current && isSubCasteDeleteDialogOpen ? (
				<SubCasteDeleteDialogController
					isOpen={isSubCasteDeleteDialogOpen}
					setIsOpen={setIsSubCasteDeleteDialogOpen}
					subCaste={deleteSubCasteRef.current}
					onSuccess={() => fetchSubCasteList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
