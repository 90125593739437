import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"
import { FC } from "react"
import { BsCheckCircle, BsClock, BsClockHistory, BsXCircle } from "react-icons/bs"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { Mobile } from "src/domain/entities/mobile"
import { getUserFullName } from "src/domain/entities/user"
import { ReviewStatus } from "src/utils/enums"
import { formatDate, getVillageName } from "src/utils/helpers"
import { Registration } from "../../../../domain/entities/registration"

export const RegistrationDetailsView: FC<{
	registration: Registration
	handleAssignReviewer: () => void
	handleUnAssignReviewer: () => void
	handleResetReview: () => void
	onProfilePictureClick: () => void
	onIdentityProofClick: () => void
	onBasicUpdateClick: () => void
	onLocationUpdateClick: () => void
	onNewSurnameClick: () => void
	handleViewProfileButtonClick: () => void
	mobile: Mobile
}> = ({
	registration,
	handleAssignReviewer,
	handleUnAssignReviewer,
	handleResetReview,
	onProfilePictureClick,
	onIdentityProofClick,
	onBasicUpdateClick,
	onLocationUpdateClick,
	onNewSurnameClick,
	handleViewProfileButtonClick,
	mobile,
}) => {
	if (!registration) return null

	function getOverview(registration: Registration) {
		return (
			<Box
				p={4}
				bg="white"
				rounded="xl"
				w="full"
				shadow="md"
				height={"fit-content"}
			>
				<Text
					fontSize={"x-large"}
					fontWeight="semibold"
					borderBottom={"1px solid #e2e8f0"}
				>
					Overview
				</Text>
				<Flex gridGap={2} mt="4">
					<Box>
						{registration.identity?.profileImageUrl && (
							<Image
								style={{ aspectRatio: "1" }}
								src={registration.identity?.profileImageUrl}
								height="6vw"
								width="6vw"
								rounded={"xl"}
								onClick={onProfilePictureClick}
								cursor={"pointer"}
								transition="all 0.2s"
								_hover={{ filter: "brightness(0.8)" }}
							/>
						)}
					</Box>
					<Box>
						<Text
							fontSize={"lg"}
							fontWeight="semibold"
							style={{ textTransform: "capitalize" }}
						>
							{registration.basic?.firstName?.en}{" "}
							{registration.basic?.surname?.name.en ??
								registration.basic?.customSurname?.text?.en}
							({registration.basic?.firstName?.gu}{" "}
							{registration.basic?.surname?.name.gu ??
								registration.basic?.customSurname?.text?.gu}
							)
						</Text>
						<Flex align={"center"} gridGap={2}>
							<Text style={{ textTransform: "capitalize" }}>
								{registration.basic?.gender}
							</Text>
							<Box
								w="6px"
								h="6px"
								rounded={"full"}
								bgColor={"gray.400"}
							></Box>
							<Text style={{ textTransform: "capitalize" }}>
								{registration?.basic?.surname?.subCaste?.name.en ??
									registration.basic?.customSurname?.subCaste?.name?.en}
							</Text>
						</Flex>
					</Box>
				</Flex>
			</Box>
		)
	}

	function getReview(registration: Registration) {
		return (
			<Box
				mt={"4"}
				p={4}
				bg="white"
				rounded="xl"
				shadow="md"
				height={"fit-content"}
			>
				<Text
					fontSize={"x-large"}
					fontWeight="semibold"
					borderBottom={"1px solid #e2e8f0"}
				>
					Review
				</Text>
				<Flex gridGap={2} mt="4" flexDir={"column"}>
					<Box>
						<Text fontSize={"sm"} fontWeight="semibold">
							Registration ID
						</Text>
						<Text>{registration.id}</Text>
					</Box>
					<Box>
						<Text fontSize={"sm"} fontWeight="semibold">
							Registration Date
						</Text>
						<Text>{formatDate(registration.createdAt)}</Text>
					</Box>
					{mobile ? (
						<Box>
							<Text fontSize={"sm"} fontWeight="semibold">
								Mobile No.
							</Text>
							<Text>
								+{mobile.mobile.countryCode}-{mobile.mobile.mobileNo}
							</Text>
						</Box>
					) : null}
					<Box>
						<Text fontSize={"sm"} fontWeight="semibold">
							Registration Status
						</Text>
						<Text style={{ textTransform: "capitalize" }}>
							{registration.reviews?.[0]?.status ?? "-"}
						</Text>
					</Box>
					<Box>
						<Text fontSize={"sm"} fontWeight="semibold">
							Reviewer
						</Text>
						<Text style={{ textTransform: "capitalize" }}>
							{registration.reviewer?.id ? (
								<>
									<Box>{getUserFullName(registration.reviewer)}</Box>
									<Button
										onClick={handleUnAssignReviewer}
										colorScheme="red"
										size="sm"
										variant={"outline"}
									>
										Remove Reviewer
									</Button>
								</>
							) : (
								<Button
									onClick={handleAssignReviewer}
									colorScheme="blue"
									size="sm"
								>
									Assign Reviewer
								</Button>
							)}
						</Text>
					</Box>
				</Flex>
			</Box>
		)
	}

	function getReviewProcess(registration: Registration) {
		return (
			<Box
				mt={"4"}
				p={4}
				bg="white"
				rounded="xl"
				shadow="md"
				height={"fit-content"}
			>
				<Box borderBottom={"1px solid #e2e8f0"}>
					<Flex justify={"space-between"}>
						<Text fontSize={"x-large"} fontWeight="semibold">
							Review Process
						</Text>
						<Flex>
							<Button
								onClick={handleResetReview}
								colorScheme="red"
								variant={"ghost"}
								size="sm"
							>
								Reset Review
							</Button>
							{registration.reviews?.[0]?.status ===
							ReviewStatus.APPROVED ? (
								<Button
									colorScheme="blue"
									variant={"ghost"}
									size="sm"
									onClick={handleViewProfileButtonClick}
								>
									View Profile
								</Button>
							) : null}
						</Flex>
					</Flex>
				</Box>
				<Flex gridGap={2} mt="4" flexDir={"column"}>
					<Box>
						<Flex flexDirection={"column"}>
							{[...(registration.reviews ?? [])]
								.reverse()
								.map((review, index) => (
									<Flex flexDirection={"column"} key={index}>
										<Flex
											gridGap={2}
											justify="space-between"
											// mt={"2"}
										>
											<Flex align={"flex-start"} gridColumnGap={2}>
												<Flex
													flexDirection={"column"}
													height="full"
													align={"center"}
												>
													<Box
														flex={0}
														mt={1}
														color={
															getReviewStatusDetails(
																review.status,
															).color
														}
													>
														{
															getReviewStatusDetails(
																review.status,
															).icon
														}
													</Box>
													{
														// is not last review
														index !==
															registration.reviews!.length -
																1 && (
															<Box
																flex={1}
																w="1"
																h="full"
																my={1}
																rounded="full"
																bgColor={"gray.200"}
															></Box>
														)
													}
												</Flex>
												<Box>
													<Text
														style={{
															textTransform: "capitalize",
														}}
														fontWeight="semibold"
													>
														{
															getReviewStatusDetails(
																review.status,
															).text
														}
													</Text>
													<Box fontSize={"sm"} pb="1">
														{review.note?.en}
													</Box>
												</Box>
											</Flex>
											<Text
												fontSize={"sm"}
												flex={0}
												whiteSpace="nowrap"
											>
												{formatDate(review.createdAt)}
											</Text>
										</Flex>
									</Flex>
								))}
						</Flex>
					</Box>
				</Flex>
			</Box>
		)
	}

	function getBasicDetails(registration: Registration) {
		return (
			<Box p={4} bg="white" rounded="xl" w="full" shadow="md">
				<Flex
					gridGap={4}
					justify="space-between"
					align="center"
					w="full"
					borderBottom={"1px solid #e2e8f0"}
				>
					<Text fontSize={"x-large"} fontWeight="semibold">
						Basic Details
					</Text>
					<EditIconButton onClick={onBasicUpdateClick} />
				</Flex>
				<Flex gridGap={2} mt={4} flexWrap="wrap">
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							First Name
						</Text>
						<Text>{registration.basic?.firstName?.en} </Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							First Name Gu
						</Text>
						<Text>{registration.basic?.firstName?.gu} </Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Sub-caste
						</Text>
						<Text>
							{registration?.basic?.surname?.subCaste?.name.en ??
								registration.basic?.customSurname?.subCaste?.name?.en}
						</Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Surname
						</Text>
						<Text>
							{registration.basic?.surname
								? registration.basic?.surname?.name.en
								: registration.basic?.customSurname?.text?.en}
							{registration.basic?.surname ? null : (
								<>
									<Text as="span" size={"xs"} color="gray.500">
										{" "}
										(Custom)
									</Text>
									<Button
										onClick={onNewSurnameClick}
										colorScheme="blue"
										size="sm"
										variant={"outline"}
									>
										Create
									</Button>
								</>
							)}
						</Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Surname Gu
						</Text>
						<Text>
							{registration.basic?.surname
								? registration.basic?.surname?.name.gu
								: registration.basic?.customSurname?.text?.gu}
							{registration.basic?.surname ? null : (
								<>
									<Text as="span" size={"xs"} color="gray.500">
										{" "}
										(Custom)
									</Text>
									<Button
										onClick={onNewSurnameClick}
										colorScheme="blue"
										size="sm"
										variant={"outline"}
									>
										Create
									</Button>
								</>
							)}
						</Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Gender
						</Text>
						<Text style={{ textTransform: "capitalize" }}>
							{registration?.basic?.gender}
						</Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Date of Birth
						</Text>
						<Text>{formatDate(registration?.basic?.dateOfBirth ?? 0)}</Text>
					</Box>
					<Box w="30%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Marital Status
						</Text>
						<Text style={{ textTransform: "capitalize" }}>
							{registration?.basic?.maritalStatus}
						</Text>
					</Box>
				</Flex>
			</Box>
		)
	}

	function getLocationDetails(registration: Registration) {
		return (
			<Box p={4} mt="2" bg="white" rounded="xl" w="full" shadow="md">
				<Flex
					gridGap={4}
					justify="space-between"
					align="center"
					w="full"
					borderBottom={"1px solid #e2e8f0"}
				>
					<Text fontSize={"x-large"} fontWeight="semibold">
						Location Details
					</Text>
					<EditIconButton onClick={onLocationUpdateClick} />
				</Flex>
				<Flex gridGap={2} mt={4} flexDir="column">
					<Box>
						<Text fontWeight={"semibold"} fontSize="sm">
							Native Village
						</Text>
						<Text>
							{getVillageName(registration.location?.nativeVillage, {
								depth: 3,
							})}{" "}
						</Text>
					</Box>
					<Box>
						<Text fontWeight={"semibold"} fontSize="sm">
							Current city
						</Text>
						<Text>
							{registration.location?.currentVillage
								? getVillageName(registration.location?.currentVillage)
								: registration.location?.currentVillageText}
							{registration.location?.currentVillage ? null : (
								<Text as="span" size={"xs"} color="gray.500">
									{" "}
									(Custom)
								</Text>
							)}
						</Text>
					</Box>
				</Flex>
			</Box>
		)
	}

	function getIdentityDetails(registration: Registration) {
		return (
			<Box p={4} mt="2" bg="white" rounded="xl" w="full" shadow="md">
				<Flex
					gridGap={4}
					justify="space-between"
					align="center"
					w="full"
					borderBottom={"1px solid #e2e8f0"}
				>
					<Text fontSize={"x-large"} fontWeight="semibold">
						Identity Details
					</Text>
					{/* <EditIconButton onClick={onIdentityUpdateClick} /> */}
				</Flex>
				<Flex gridGap={4} mt={4}>
					<Flex w="30%" gridGap={2} flexDir="column">
						<Box>
							<Text fontWeight={"semibold"} fontSize="sm">
								Profile Picture
							</Text>
							<Box>
								{registration?.identity?.profileImageUrl && (
									<Image
										src={registration?.identity?.profileImageUrl}
										alt="Profile Picture"
										objectFit="cover"
										w="full"
										borderRadius="full"
										onClick={onProfilePictureClick}
										cursor={"pointer"}
										transition="all 0.2s"
										_hover={{ filter: "brightness(0.8)" }}
									/>
								)}
							</Box>
						</Box>
						<Box display={{ base: "flex", lg: "block" }}>
							<Text
								fontWeight={"semibold"}
								fontSize="sm"
								pt={{ base: "4", lg: "0" }}
							>
								Mobile Number
							</Text>
							<Text pt={{ base: "4", lg: "0" }}>
								+{registration?.identity?.mobile?.countryCode}{" "}
								{registration?.identity?.mobile?.mobileNo}
							</Text>
						</Box>
					</Flex>
					<Box w="60%">
						<Text fontWeight={"semibold"} fontSize="sm">
							Identity Proof
						</Text>
						<Box>
							{registration?.identity?.identityProofUrl && (
								<Image
									src={registration?.identity?.identityProofUrl}
									alt="Identity Proof"
									w="full"
									objectFit="cover"
									borderRadius="lg"
									onClick={onIdentityProofClick}
									cursor={"pointer"}
									transition="all 0.2s"
									_hover={{ filter: "brightness(0.8)" }}
								/>
							)}
						</Box>
					</Box>
				</Flex>
			</Box>
		)
	}

	return (
		<>
			<Flex
				gridGap={4}
				p={4}
				justify="space-evenly"
				color={"gray.800"}
				display={{ base: "none", lg: "flex" }}
			>
				<Box w="full">
					{getOverview(registration)}
					{getReview(registration)}
					{getReviewProcess(registration)}
				</Box>

				<Box w="full">
					{getBasicDetails(registration)}
					{getLocationDetails(registration)}
					{getIdentityDetails(registration)}
				</Box>
			</Flex>
			<Flex
				gridGap={4}
				p={{ base: "3", lg: "4" }}
				justify="space-evenly"
				color={"gray.800"}
				display={{ base: "flex", lg: "none" }}
				flexDirection={"column"}
			>
				{getOverview(registration)}
				{getBasicDetails(registration)}
				{getLocationDetails(registration)}
				{getIdentityDetails(registration)}
				{getReview(registration)}
				{getReviewProcess(registration)}
			</Flex>
		</>
	)
}

export function getReviewStatusDetails(status: ReviewStatus): {
	text: string
	color: string
	icon: JSX.Element
} {
	switch (status) {
		case ReviewStatus.PENDING:
			return { text: "Pending", color: "orange", icon: <BsClock size={20} /> }
		case ReviewStatus.IN_REVIEW:
			return {
				text: "In Review",
				color: "orange",
				icon: <BsClockHistory size={20} />,
			}
		case ReviewStatus.REJECTED:
			return { text: "Rejected", color: "red", icon: <BsXCircle size={20} /> }
		case ReviewStatus.APPROVED:
			return { text: "Approved", color: "green", icon: <BsCheckCircle size={20} /> }
	}
}
