import {
	Box,
	Button,
	Card,
	Flex,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsLink } from "react-icons/bs"
import { formatDate } from "src/utils/helpers"
import { Surname } from "../../../../domain/entities/surname"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const SurnameListView: FC<{
	list: Surname[]
	onUpdate: (surname: Surname) => void
	onDelete: (surname: Surname) => void
	onRelatedSurnamesButtonClick: (surname: Surname) => void
}> = ({ list, onUpdate, onDelete, onRelatedSurnamesButtonClick }) => (
	<>
		<Table size={"sm"} display={{ base: "none", lg: "table" }}>
			<Thead>
				<Tr>
					<Th>Code</Th>
					<Th>Surname</Th>
					<Th>Surname Gujarati</Th>
					<Th>SubCaste</Th>
					<Th>Created At</Th>
					<Th>Updated At</Th>
					<Th isNumeric>Actions</Th>
				</Tr>
			</Thead>
			<Tbody display={{ base: "none", lg: "contents" }}>
				{list.map((surname, i) => (
					<Tr key={i}>
						<Td>{surname.code}</Td>
						<Td>{surname.name.en}</Td>
						<Td>{surname.name.gu}</Td>
						<Td>
							{surname.subCaste?.name?.en +
								` (${surname.subCaste?.name.gu})`}
						</Td>
						<Td>{formatDate(surname.createdAt)}</Td>
						<Td>{formatDate(surname.updatedAt)}</Td>
						<Td isNumeric>
							<Button
								cursor={"pointer"}
								onClick={(e) => {
									e.stopPropagation()
									onRelatedSurnamesButtonClick(surname)
								}}
								variant={"ghost"}
								size={"sm"}
								_hover={{ backgroundColor: "gray.200" }}
								_focus={{ backgroundColor: "gray.200" }}
								_active={{ backgroundColor: "gray.200" }}
							>
								<Flex align={"center"} gridGap={1} fontSize={"xs"}>
									{surname.relatedSurnames?.length || ""}
									{<BsLink size={16} />}{" "}
								</Flex>
							</Button>
							<EditIconButton onClick={() => onUpdate(surname)} />
							<DeleteIconButton onClick={() => onDelete(surname)} />
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>

		{list.map((surname, i) => (
			<Card display={{ base: "flex", lg: "none" }} shadow={"md"} m={"2"} key={i}>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Box m={"2"}>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Code:
							</Box>
							<Box ml={"2"}>{surname.code}</Box>
						</Flex>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Surname:
							</Box>
							<Box ml={"2"}>{surname.name.en}</Box>
						</Flex>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Surname Gujarati:
							</Box>
							<Box ml={"2"} fontSize={"sm"}>
								{surname.name.gu}
							</Box>
						</Flex>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Subcast:
							</Box>
							<Box ml={"2"} fontSize={"sm"}>
								{surname.subCaste?.name?.en +
									` (${surname.subCaste?.name.gu})`}
							</Box>
						</Flex>
					</Box>
					<Box m={"1"}>
						<Flex align={"center"}>
							<Box ml={"2"}>
								<EditIconButton onClick={() => onUpdate(surname)} />
								<DeleteIconButton onClick={() => onDelete(surname)} />
							</Box>
						</Flex>
					</Box>
				</Box>
			</Card>
		))}
	</>
)
