import { ComponentProps, FC, useCallback } from "react"
import { subCasteUpdateApi } from "src/domain/api/subCaste/subCasteUpdateApi"
import { SubCaste } from "../../../../domain/entities/subCaste"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { ISubCasteUpdateFormFields } from "./ISubCasteUpdateFormFields"
import { SubCasteUpdateDrawerFormView } from "./SubCasteUpdateDrawerFormView"

type Props = Omit<ComponentProps<typeof SubCasteUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (subCaste: SubCaste) => void
}

export const SubCasteUpdateDrawerFormController: FC<Props> = ({
	subCaste,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISubCasteUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedSubCaste = await subCasteUpdateApi(
					{ id: subCaste.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedSubCaste)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, subCaste.id],
	)

	return (
		<SubCasteUpdateDrawerFormView
			subCaste={subCaste}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
