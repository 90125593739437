import {
	Box,
	Flex,
	Image,
	Switch,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsPatchCheckFill, BsPerson } from "react-icons/bs"
import { DeleteIconButton } from "src/components/ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { Business } from "../../../../domain/entities/business"

export const BusinessListView: FC<{
	list: Business[]
	onBusinessClick: (businessId: string) => void
	handleBusinessVerificationChange: (val: boolean, business: Business) => Promise<void>
	onUpdate: (business: Business) => void
	onDelete: (business: Business) => void
}> = ({
	list,
	onBusinessClick,
	handleBusinessVerificationChange,
	onUpdate,
	onDelete,
}) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Logo</Th>
				<Th>Name</Th>
				<Th>Is Verified?</Th>
				<Th>Created At</Th>
				<Th>Updated At</Th>
				<Th>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((business, i) => (
				<Tr
					key={i}
					style={{ cursor: "pointer" }}
					_hover={{ bg: "gray.100" }}
					onClick={() => onBusinessClick(business.id)}
				>
					<Td>
						{business?.logoImageUrl ? (
							<Image
								style={{ aspectRatio: "1" }}
								src={business?.logoImageUrl}
								height="50px"
								rounded={"lg"}
							/>
						) : (
							<Flex
								height={50}
								width={50}
								bgColor="gray.100"
								rounded={"lg"}
								justify="center"
								align={"center"}
								color="gray.500"
							>
								<BsPerson size={20} />
							</Flex>
						)}
					</Td>

					<Td>
						<Text fontWeight={500}>
							<Flex align={"center"}>
								<Text>{business.name.en}</Text>
								{business.isVerified ? (
									<Text ml={2} color="blue.400">
										<BsPatchCheckFill size={16} />
									</Text>
								) : null}
							</Flex>
						</Text>
						<Text>{business.name.gu}</Text>
					</Td>

					<Td onClick={(e) => e.stopPropagation()}>
						<Switch
							isChecked={business.isVerified}
							onChange={(val) => {
								handleBusinessVerificationChange(
									val.target.checked,
									business,
								)
							}}
						/>
					</Td>
					<Td>
						{business.createdAt ? (
							<Text fontWeight={"medium"}>
								{new Date(business.createdAt).toLocaleDateString()}
							</Text>
						) : null}
					</Td>
					<Td>
						{business.updatedAt ? (
							<Text fontWeight={"medium"}>
								{new Date(business.updatedAt).toLocaleDateString()}
							</Text>
						) : null}
					</Td>
					<Box ml={"5"} mt={"5"}>
						<EditIconButton onClick={() => onUpdate(business)} />
						<DeleteIconButton onClick={() => onDelete(business)} />
					</Box>
				</Tr>
			))}
		</Tbody>
	</Table>
)
