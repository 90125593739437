import moment from "moment"
import { ComponentProps, FC, useCallback } from "react"
import { androidBuildUpdateApi } from "src/domain/api/androidBuild/androidBuildUpdateApi"
import { AndroidBuild } from "../../../../domain/entities/androidBuild"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { AndroidBuildUpdateDrawerFormView } from "./AndroidBuildUpdateDrawerFormView"
import { IAndroidBuildUpdateFormFields } from "./IAndroidBuildUpdateFormFields"

type Props = Omit<
	ComponentProps<typeof AndroidBuildUpdateDrawerFormView>,
	"handleSubmit"
> & {
	onSuccess?: (androidBuild: AndroidBuild) => void
}

export const AndroidBuildUpdateDrawerFormController: FC<Props> = ({
	androidBuild,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IAndroidBuildUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (values.publishDate) {
					values.publishDate = moment(values.publishDate).unix() * 1000
				}

				const updatedAndroidBuild = await androidBuildUpdateApi(
					{ id: androidBuild.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedAndroidBuild)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ version: message })
			}
		},
		[onSuccess, rest, token, androidBuild.id],
	)

	return (
		<AndroidBuildUpdateDrawerFormView
			androidBuild={androidBuild}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
