import { useCallback, useState } from "react"
import { discussionReplyListApi } from "../api/discussionReply/discussionReplyListApi"
import { DiscussionReply } from "../entities/discussionReply"
import { useAuth } from "./useAuth"

export function useDiscussionReplyListApi(): {
	discussionReplyList: DiscussionReply[]
	isLoading: boolean
	fetchDiscussionReplyList: (
		filter?: Parameters<typeof discussionReplyListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [discussionReplyList, setDiscussionReplyList] = useState<DiscussionReply[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchDiscussionReplyList = useCallback(
		async (filter: Parameters<typeof discussionReplyListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await discussionReplyListApi(filter, token)
			setDiscussionReplyList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { discussionReplyList, isLoading, fetchDiscussionReplyList }
}
