import { Tag, TagLabel } from "@chakra-ui/react"
import { FC } from "react"
import { MobileAppBuildStatus } from "src/utils/enums"

const BuildStatusTag: FC<{ status: MobileAppBuildStatus }> = ({ status }) => {
	const tagData = {
		color: "",
		bgColor: "",
	}

	if (status === MobileAppBuildStatus.ABANDONED) {
		tagData.color = "#F87171"
		tagData.bgColor = "#FFE4E6"
	} else if (status === MobileAppBuildStatus.DEPRECATED) {
		tagData.color = "#FF9736"
		tagData.bgColor = "#FFE2B7"
	} else if (status === MobileAppBuildStatus.LIVE) {
		tagData.color = "#34D399"
		tagData.bgColor = "#D1FAE5"
	} else if (status === MobileAppBuildStatus.TESTING) {
		tagData.color = "#EAB308"
		tagData.bgColor = "#FEF9C3"
	}

	return (
		<Tag size={"md"} variant="subtle" color={tagData.color} bgColor={tagData.bgColor}>
			<TagLabel fontWeight={"semibold"} textTransform={"capitalize"}>
				{status}
			</TagLabel>
		</Tag>
	)
}

export default BuildStatusTag
