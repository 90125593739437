import { Box, Button, Flex, FormControl, Heading, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Form, Formik } from "formik"
import { FC } from "react"
import { ILoginFormFields } from "."
import { FormikOnSubmit } from "../../../../utils/types"
import { ThePatidarsLogo } from "../../../shared/ThePatidarsLogo"
import { ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props {
	handleSubmit: FormikOnSubmit<ILoginFormFields>
}

const gradiantStartColor = "#002A64"
const gradiantEndColor = "#006CEF"

export const LoginFormView: FC<Props> = ({ handleSubmit }) => (
	<Box
		padding={20}
		borderWidth={1}
		borderRadius={15}
		backgroundColor="white"
		boxShadow="1px 1px 30px #eee"
	>
		<Formik<ILoginFormFields>
			initialValues={{ username: "", password: "" }}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ handleChange, isSubmitting }) => (
				<Stack as={Form} maxWidth={"sm"}>
					<Box textAlign={"center"}>
						<ThePatidarsLogo />
						<Heading
							as="h2"
							width={"fit-content"}
							bgGradient={`linear(to-br, ${gradiantStartColor}, ${gradiantEndColor})`}
							bgClip="text"
							fontWeight={"bold"}
							lineHeight={"shorter"}
							fontSize={"2xl"}
							margin={"auto"}
							mb={"4"}
						>
							Admin Portal
						</Heading>
					</Box>
					<Flex direction={"column"} gridGap={"2"}>
						{/* Username */}
						<FormControl>
							<InputLabel label="Username" />
							<Input
								name="username"
								onChange={handleChange}
								isRequired
								autoFocus
								maxLength={10}
							/>
							<ErrorMessage component={ErrorMessageField} name="username" />
						</FormControl>
						{/* Password */}
						<FormControl>
							<InputLabel label="Password" />
							<Input
								type="password"
								name="password"
								isRequired
								onChange={handleChange}
							/>
							<ErrorMessage component={ErrorMessageField} name="password" />
						</FormControl>
					</Flex>
					<Box py="2">
						{/* Login Button */}
						<Button
							colorScheme={"blue"}
							type="submit"
							disabled={isSubmitting}
							isLoading={isSubmitting}
							width={"full"}
						>
							Login
						</Button>
					</Box>
				</Stack>
			)}
		</Formik>
	</Box>
)
