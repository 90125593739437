import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"

import { IosBuild } from "../../../domain/entities/iosBuild"
import { useIosBuildListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { IosBuildAddDrawerFormController } from "./IosBuildAddDrawerForm"
import { IosBuildDeleteDialogController } from "./IosBuildDeleteDialogController"
import { IosBuildListController } from "./IosBuildList"
import { IosBuildUpdateDrawerFormController } from "./IosBuildUpdateDrawerForm"

export const IosBuildsPage: FC = () => {
	const { iosBuildList, isLoading, fetchIosBuildList } = useIosBuildListApi()
	const [isIosBuildDeleteDialogOpen, setIsIosBuildDeleteDialogOpen] = useState(false)
	const iosBuildAddDrawerDisclosure = useDisclosure()
	const iosBuildUpdateDrawerDisclosure = useDisclosure()

	useEffect(() => {
		fetchIosBuildList()
	}, [fetchIosBuildList])

	const updateIosBuildRef = useRef<IosBuild>()
	const deleteIosBuildRef = useRef<IosBuild>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Ios Builds
					</Text>
					<Button
						size="sm"
						onClick={iosBuildAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Ios Build
					</Button>
				</Flex>
			</Box>
			<IosBuildListController
				list={iosBuildList}
				isLoading={isLoading}
				onUpdate={(iosBuild: IosBuild) => {
					updateIosBuildRef.current = iosBuild
					iosBuildUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(iosBuild: IosBuild) => {
					deleteIosBuildRef.current = iosBuild
					setIsIosBuildDeleteDialogOpen(true)
				}}
				onAddClick={iosBuildAddDrawerDisclosure.onOpen}
			/>
			{iosBuildAddDrawerDisclosure.isOpen ? (
				<IosBuildAddDrawerFormController
					{...iosBuildAddDrawerDisclosure}
					onSuccess={() => fetchIosBuildList()}
				/>
			) : null}
			{updateIosBuildRef.current && iosBuildUpdateDrawerDisclosure.isOpen ? (
				<IosBuildUpdateDrawerFormController
					{...iosBuildUpdateDrawerDisclosure}
					iosBuild={updateIosBuildRef.current}
					onSuccess={() => fetchIosBuildList()}
				/>
			) : null}
			{deleteIosBuildRef.current && isIosBuildDeleteDialogOpen ? (
				<IosBuildDeleteDialogController
					isOpen={isIosBuildDeleteDialogOpen}
					setIsOpen={setIsIosBuildDeleteDialogOpen}
					iosBuild={deleteIosBuildRef.current}
					onSuccess={() => fetchIosBuildList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
