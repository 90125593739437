import { MultiLangText } from "src/utils/types"
import { Facility } from "../../entities/facility"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	icon: string
	color: string
}

export async function facilityAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/facility/add/v1"
	const requester = new ApiRequester<RequestDataShape, Facility>(endpoint)
	return await requester.sendRequest(payload, token)
}
