import { useCallback, useState } from "react"
import { discussionListApi } from "../api/discussion/discussionListApi"
import { Discussion } from "../entities/discussion"
import { useAuth } from "./useAuth"

export function useDiscussionListApi(): {
	discussionList: Discussion[]
	isLoading: boolean
	fetchDiscussionList: (
		filter?: Parameters<typeof discussionListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [discussionList, setDiscussionList] = useState<Discussion[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchDiscussionList = useCallback(
		async (filter: Parameters<typeof discussionListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await discussionListApi(filter, token)
			setDiscussionList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { discussionList, isLoading, fetchDiscussionList }
}
