import { RequestPagination } from "src/utils/types"
import { Surname } from "../../entities/surname"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	code?: string
	isApproved?: boolean
	subCasteId?: string
	nameEn?: string
	nameGu?: string
	search?: string
	fetch?: { subCaste?: boolean; related?: boolean }
	pagination?: RequestPagination
}

export async function surnameListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/surname/list/v1"
	const requester = new ApiRequester<RequestDataShape, Surname[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
