import { Unit } from "src/domain/entities/unit"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: MultiLangText
		position?: number
	}
}

export async function unitUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/unit/update/v1"
	const requester = new ApiRequester<RequestDataShape, Unit>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
