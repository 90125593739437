import moment from "moment"
import { ComponentProps, FC, useCallback } from "react"
import { androidBuildAddApi } from "src/domain/api/androidBuild/androidBuildAddApi"
import { AndroidBuild } from "../../../../domain/entities/androidBuild"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { AndroidBuildAddDrawerFormView } from "./AndroidBuildAddDrawerFormView"
import { IAndroidBuildAddFormFields } from "./IAndroidBuildAddFormFields"

interface Props
	extends Omit<ComponentProps<typeof AndroidBuildAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (androidBuild: AndroidBuild) => void
}

export const AndroidBuildAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IAndroidBuildAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				values.publishDate = moment(values.publishDate).unix() * 1000

				const androidBuild = await androidBuildAddApi(values, token)
				onSuccess(androidBuild)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ version: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <AndroidBuildAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
