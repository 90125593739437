import { FC } from "react"
import { Facility } from "../../../../domain/entities/facility"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { FacilityListView } from "./FacilityListView"

interface Props {
	list: Facility[]
	isLoading: boolean
	onUpdate: (facility: Facility) => void
	onDelete: (facility: Facility) => void
	onAddClick: () => void
}

export const FacilityListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection message="No Facilities found." onAddClick={onAddClick} />
		)
	}

	return <FacilityListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
