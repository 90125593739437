import { ComponentProps, FC, useCallback, useEffect } from "react"
import { educationCategoryUpdateApi } from "src/domain/api/educationCategory/educationCategoryUpdateApi"
import { EducationCategory } from "../../../../domain/entities/educationCategory"
import { useAuth, useEducationCategoryListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { EducationCategoryUpdateDrawerFormView } from "./EducationCategoryUpdateDrawerFormView"
import { IEducationCategoryUpdateFormFields } from "./IEducationCategoryUpdateFormFields"

type Props = Omit<
	ComponentProps<typeof EducationCategoryUpdateDrawerFormView>,
	"handleSubmit" | "parentEducationCategoryList"
> & {
	onSuccess?: (educationCategory: EducationCategory) => void
}

export const EducationCategoryUpdateDrawerFormController: FC<Props> = ({
	educationCategory,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IEducationCategoryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedEducationCategory = await educationCategoryUpdateApi(
					{
						id: educationCategory.id,
						// parentCategoryId: educationCategory.parentCategory?.id,
						update: values,
					},
					token,
				)
				onSuccess && onSuccess(updatedEducationCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[
			onSuccess,
			rest,
			token,
			educationCategory.id,
			// educationCategory.parentCategory?.id,
		],
	)

	const { fetchEducationCategoryList, educationCategoryList } =
		useEducationCategoryListApi()

	useEffect(() => {
		fetchEducationCategoryList({
			fetch: {
				parentCategory: true,
				childCategories: true,
			},
		})
	}, [fetchEducationCategoryList])

	return (
		<EducationCategoryUpdateDrawerFormView
			educationCategory={educationCategory}
			parentEducationCategoryList={educationCategoryList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
