import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { Country } from "../../../domain/entities/country"
import { useCountryListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { CountryAddDrawerFormController } from "./CountryAddDrawerForm"
import { CountryDeleteDialogController } from "./CountryDeleteDialogController"
import { CountryListController } from "./CountryList"
import { CountryUpdateDrawerFormController } from "./CountryUpdateDrawerForm"

export const CountriesPage: FC = () => {
	const { countryList, isLoading, fetchCountryList } = useCountryListApi()
	const [isCountryDeleteDialogOpen, setIsCountryDeleteDialogOpen] = useState(false)
	const countryAddDrawerDisclosure = useDisclosure()
	const countryUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchCountries = useCallback(async () => {
		return await fetchCountryList({
			search: searchText,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchCountryList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchCountries()
	}, [fetchCountries])

	const updateCountryRef = useRef<Country>()
	const deleteCountryRef = useRef<Country>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Countries
					</Text>
					<Button
						size="sm"
						onClick={countryAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Country
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<InputGroup width={"fit-content"}>
						<Input
							value={searchText}
							onChange={(e) => {
								pagination.setCurrentPage(1)
								setSearchText(e.target.value)
								getGujaratiSuggestions(e.target.value)
							}}
							type="text"
							placeholder="Search"
						/>
						<InputRightAddon>
							<BsSearch />
						</InputRightAddon>
					</InputGroup>
					{gujaratiSuggestions.map((el, i) => (
						<Tag
							key={i}
							colorScheme={"green"}
							backgroundColor={"green.50"}
							variant="outline"
							_hover={{
								backgroundColor: "green.100",
							}}
							cursor="pointer"
							margin={0.5}
							onClick={() => {
								pagination.setCurrentPage(1)
								setSearchText(el)
								setGujaratiSuggestions([])
							}}
						>
							{el}
						</Tag>
					))}
				</Flex>
			</Box>
			<CountryListController
				list={countryList}
				isLoading={isLoading}
				onUpdate={(country: Country) => {
					updateCountryRef.current = country
					countryUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(country: Country) => {
					deleteCountryRef.current = country
					setIsCountryDeleteDialogOpen(true)
				}}
				onAddClick={countryAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={countryList.length === 0}
			/>
			{countryAddDrawerDisclosure.isOpen ? (
				<CountryAddDrawerFormController
					{...countryAddDrawerDisclosure}
					onSuccess={() => fetchCountryList()}
				/>
			) : null}
			{updateCountryRef.current && countryUpdateDrawerDisclosure.isOpen ? (
				<CountryUpdateDrawerFormController
					{...countryUpdateDrawerDisclosure}
					country={updateCountryRef.current}
					onSuccess={() => fetchCountryList()}
				/>
			) : null}
			{deleteCountryRef.current && isCountryDeleteDialogOpen ? (
				<CountryDeleteDialogController
					isOpen={isCountryDeleteDialogOpen}
					setIsOpen={setIsCountryDeleteDialogOpen}
					country={deleteCountryRef.current}
					onSuccess={() => fetchCountryList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
