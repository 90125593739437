import { usePagination } from "@ajna/pagination"
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { Discussion } from "src/domain/entities/discussion"
import { DiscussionCategory } from "src/domain/entities/discussionCategory"
import { useDiscussionCategoryListApi } from "src/domain/hooks"
import { useDiscussionListApi } from "src/domain/hooks/useDiscussionsListApi"

import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { DiscussionDeleteDialogController } from "./DiscussionDeleteDialogController"
import { DiscussionNotifyDialogController } from "./DiscussionNotifyDialogController"
import { DiscussionsListController } from "./DiscussionsList"
import { DiscussionsUpdateDrawerFormController } from "./DiscussionsUpdateDrawerForm"

export const DiscussionsPage: FC = () => {
	const { discussionList, isLoading, fetchDiscussionList } = useDiscussionListApi()
	const [isDiscussionDeleteDialogOpen, setIsDiscussionDeleteDialogOpen] =
		useState(false)

	const [isDiscussionNotifyDialogOpen, setIsDiscussionNotifyDialogOpen] =
		useState(false)

	const discussionUpdateDrawerDisclosure = useDisclosure()

	const { discussionCategoryList, fetchDiscussionCategoryList } =
		useDiscussionCategoryListApi()

	const [selectedDiscussionCategory, setSelectedDiscussionCategory] =
		useState<DiscussionCategory>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const fetchDiscussion = useCallback(async () => {
		return await fetchDiscussionList({
			fetch: {
				createdBy: { profile: true },
				category: true,
			},
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
			categoryId: selectedDiscussionCategory?.id,
		})
	}, [fetchDiscussionList, pagination.currentPage, selectedDiscussionCategory?.id])

	useEffect(() => {
		fetchDiscussion()
	}, [fetchDiscussion])

	const deleteDiscussionRef = useRef<Discussion>()
	const notifyDiscussionRef = useRef<Discussion>()
	const updateDiscussionRef = useRef<Discussion>()

	const discussionCategoryOptions: {
		label: string
		value?: DiscussionCategory
	}[] = discussionCategoryList.map((discussionCategory) => ({
		label: discussionCategory.name.en,
		value: discussionCategory,
	}))

	discussionCategoryOptions.unshift({
		label: "All",
		value: undefined,
	})

	useEffect(() => {
		fetchDiscussionCategoryList()
	}, [fetchDiscussionCategoryList])

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Discussions
					</Text>
					{/* Discussion Category */}
					<Box width={{ base: "full", lg: "15%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="Discussion Category" />
						<ReactSelect
							name="discussionCategoryId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedDiscussionCategory(val?.value)
							}}
							value={discussionCategoryOptions.find(
								(el) => el.value?.id === selectedDiscussionCategory?.id,
							)}
							options={discussionCategoryOptions}
						/>
					</Box>
				</Flex>
			</Box>

			<DiscussionsListController
				list={discussionList}
				isLoading={isLoading}
				onDelete={(discussion: Discussion) => {
					deleteDiscussionRef.current = discussion
					setIsDiscussionDeleteDialogOpen(true)
				}}
				onNotify={(discussion: Discussion) => {
					notifyDiscussionRef.current = discussion
					setIsDiscussionNotifyDialogOpen(true)
				}}
				onUpdate={(discussion: Discussion) => {
					updateDiscussionRef.current = discussion
					discussionUpdateDrawerDisclosure.onOpen()
				}}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={discussionList.length === 0}
			/>
			{updateDiscussionRef.current && discussionUpdateDrawerDisclosure.isOpen ? (
				<DiscussionsUpdateDrawerFormController
					{...discussionUpdateDrawerDisclosure}
					discussion={updateDiscussionRef.current}
					onSuccess={() => fetchDiscussion()}
				/>
			) : null}
			{deleteDiscussionRef.current && isDiscussionDeleteDialogOpen ? (
				<DiscussionDeleteDialogController
					isOpen={isDiscussionDeleteDialogOpen}
					setIsOpen={setIsDiscussionDeleteDialogOpen}
					discussion={deleteDiscussionRef.current}
					onSuccess={() => fetchDiscussionList()}
				/>
			) : null}
			{notifyDiscussionRef.current && isDiscussionNotifyDialogOpen ? (
				<DiscussionNotifyDialogController
					isOpen={isDiscussionNotifyDialogOpen}
					setIsOpen={setIsDiscussionNotifyDialogOpen}
					discussion={notifyDiscussionRef.current}
					onSuccess={() => {
						alert("Discussion notified")
					}}
				/>
			) : null}
		</DashboardWrapper>
	)
}
