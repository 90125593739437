import { useToast } from "@chakra-ui/react"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { registrationLocationUpdateApi } from "src/domain/api/registration/registrationLocationUpdateApi"
import { Registration } from "src/domain/entities/registration"
import { DomainError } from "src/domain/errors"
import { useAuth, useVillageListApi } from "src/domain/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IRegistrationLocationUpdateFormFields } from "./IRegistrationLocationUpdateFormFields"
import { RegistrationLocationUpdateDrawerFormView } from "./RegistrationLocationUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof RegistrationLocationUpdateDrawerFormView>,
	| "handleSubmit"
	| "nativeVillageList"
	| "nativeVillageSearchText"
	| "setNativeVillageSearchText"
	| "currentVillageList"
	| "currentVillageSearchText"
	| "setCurrentVillageSearchText"
> & {
	onSuccess?: (registrationLocation: Registration) => void
}

export const RegistrationLocationUpdateDrawerFormController: FC<Props> = ({
	registration,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()
	const toast = useToast()

	const handleSubmit = useCallback<
		FormikOnSubmit<IRegistrationLocationUpdateFormFields>
	>(
		async (values) => {
			try {
				if (!registration) return

				const updatedState = await registrationLocationUpdateApi(
					{ id: registration.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedState)
				rest.onClose()
			} catch (err) {
				if (err instanceof DomainError) {
					toast({
						title: err.name,
						description: err.message,
						status: "error",
						position: "bottom-right",
						isClosable: true,
						duration: 10000,
					})
				}
			}
		},
		[onSuccess, rest, token, registration, toast],
	)
	const { fetchVillageList: fetchNativeVillageList, villageList: nativeVillageList } =
		useVillageListApi()
	const {
		fetchVillageList: fetchCurrentVilllageList,
		villageList: currentVillageList,
	} = useVillageListApi()

	const [nativeVillageSearchText, setNativeVillageSearchText] = useState<string>(
		registration.location?.nativeVillage?.name?.en ?? "",
	)
	const [currentVillageSearchText, setCurrentVillageSearchText] = useState<string>(
		registration.location?.currentVillage?.name?.en ??
			registration.location?.currentVillageText ??
			"",
	)

	const fetchNativeVillages = useCallback(async () => {
		fetchNativeVillageList({
			search: nativeVillageSearchText,
			pagination: {
				page: 1,
				limit: 20,
			},
			fetch: {
				talukaDistrictStateCountry: true,
			},
		})
	}, [nativeVillageSearchText, fetchNativeVillageList])

	const fetchCurrentVillages = useCallback(async () => {
		fetchCurrentVilllageList({
			search: currentVillageSearchText,
			pagination: {
				page: 1,
				limit: 20,
			},
			fetch: {
				talukaDistrictStateCountry: true,
			},
		})
	}, [currentVillageSearchText, fetchCurrentVilllageList])

	useEffect(() => {
		fetchNativeVillages()
		fetchCurrentVillages()
	}, [fetchNativeVillages, fetchCurrentVillages, registration])

	return (
		<RegistrationLocationUpdateDrawerFormView
			registration={registration}
			handleSubmit={handleSubmit}
			nativeVillageList={nativeVillageList}
			nativeVillageSearchText={nativeVillageSearchText}
			setNativeVillageSearchText={setNativeVillageSearchText}
			currentVillageList={currentVillageList}
			currentVillageSearchText={currentVillageSearchText}
			setCurrentVillageSearchText={setCurrentVillageSearchText}
			{...rest}
		/>
	)
}
