import {
	Box,
	Button,
	Card,
	Flex,
	Image,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Switch,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsCheck2, BsChevronDown, BsPerson, BsX } from "react-icons/bs"
import { FiLogOut } from "react-icons/fi"
import { AuthMethodTag } from "src/components/shared/AuthMethodTag"
import { AuthRole } from "src/utils/enums"
import { formatDate } from "src/utils/helpers"
import { User } from "../../../../domain/entities/user"

export const UserListView: FC<{
	list: User[]
	handleUserReviewerChange: (val: boolean, user: User) => Promise<void>
	handleLogoutButtonClick: (user: User, role: AuthRole | null) => Promise<void>
	onUserClick: (user: User) => void
}> = ({ list, handleUserReviewerChange, handleLogoutButtonClick, onUserClick }) => {
	function getUserName(user: User) {
		return user.profile ? (
			<Box>
				<Text fontWeight={500} textTransform={"capitalize"}>
					{user.profile?.firstName?.en} {user.profile?.surname?.name.en}
				</Text>
				<Text>
					{user.profile?.firstName?.gu} {user.profile?.surname?.name.gu}
				</Text>
			</Box>
		) : user.registration ? (
			<Box>
				<Text fontWeight={500} textTransform={"capitalize"}>
					{user.registration?.basic?.firstName?.en}{" "}
					{user.registration?.basic?.surname?.name.en}
				</Text>
				<Text>
					{user.registration?.basic?.firstName?.gu}{" "}
					{user.registration?.basic?.surname?.name.gu}
				</Text>
			</Box>
		) : (
			"-"
		)
	}

	function getUserAvatar(user: User) {
		return user.registration?.identity?.profileImageUrl ? (
			<Image
				style={{ aspectRatio: "1" }}
				src={user.registration?.identity?.profileImageUrl}
				height="50px"
				rounded={"lg"}
			/>
		) : (
			<Flex
				height={50}
				width={50}
				bgColor="gray.100"
				rounded={"lg"}
				justify="center"
				align={"center"}
				color="gray.500"
			>
				<BsPerson size={20} />
			</Flex>
		)
	}

	function getUserActions(user: User) {
		return (
			<Box>
				<Menu>
					<MenuButton
						as={Button}
						size={"sm"}
						leftIcon={<FiLogOut />}
						color={"red.600"}
						bgColor={"red.50"}
						_hover={{
							backgroundColor: "red.100",
						}}
						onClick={(e) => {
							e.stopPropagation()
						}}
						rightIcon={<BsChevronDown />}
					>
						Log out
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation()
								handleLogoutButtonClick(user, null)
							}}
						>
							All
						</MenuItem>
						<MenuDivider />
						<MenuItem
							onClick={(e) => {
								e.stopPropagation()
								handleLogoutButtonClick(user, AuthRole.USER)
							}}
						>
							User
						</MenuItem>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation()
								handleLogoutButtonClick(user, AuthRole.SKALPS)
							}}
						>
							SKALPS User
						</MenuItem>
						<MenuItem
							onClick={(e) => {
								e.stopPropagation()
								handleLogoutButtonClick(user, AuthRole.REVIEWER)
							}}
						>
							Reviewer
						</MenuItem>
					</MenuList>
				</Menu>
			</Box>
		)
	}

	return (
		<>
			{/* Desktop Card View */}
			<Table display={{ base: "none", lg: "table" }} size={"sm"}>
				<Thead>
					<Tr>
						<Th>Avatar</Th>
						<Th textAlign={"center"}>Auth Method</Th>
						<Th>Name</Th>
						<Th textAlign={"center"}>Has registered</Th>
						<Th textAlign={"center"}>Has Profile</Th>
						<Th>Created At</Th>
						<Th>Is Reviewer</Th>
						<Th>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{list.map((user, i) => (
						<Tr
							key={i}
							style={{ cursor: "pointer" }}
							_hover={{ bg: "gray.100" }}
							onClick={() => onUserClick(user)}
						>
							<Td>{getUserAvatar(user)}</Td>
							<Td>
								<AuthMethodTag authMethod={user.authMethod} />
							</Td>
							<Td>{getUserName(user)}</Td>
							<Td>
								<Box display={"flex"} justifyContent={"center"}>
									{user.registration ? (
										<BsCheck2 size={"20px"} color={"#2abd51"} />
									) : (
										<BsX size={"22px"} color={"gray"} />
									)}
								</Box>
							</Td>
							<Td>
								<Box display={"flex"} justifyContent={"center"}>
									{user.profile ? (
										<BsCheck2 size={"20px"} color={"#2abd51"} />
									) : (
										<BsX size={"22px"} color={"gray"} />
									)}
								</Box>
							</Td>
							<Td>{formatDate(user.createdAt, { includeTime: true })}</Td>
							<Td>
								<Switch
									isChecked={user.isReviewer}
									onChange={(val) => {
										handleUserReviewerChange(val.target.checked, user)
									}}
								/>
							</Td>
							<Td>{getUserActions(user)}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>

			{/* Mobile List view */}
			<Box display={{ lg: "none" }} mx={2}>
				{list.map((user, i) => (
					<Card key={i} p={"3"} my={4}>
						<Flex justify={"space-between"}>
							<Flex>
								<Box>{getUserAvatar(user)}</Box>
								<Box ml={"2"}>{getUserName(user)}</Box>
							</Flex>
							<Box>{getUserActions(user)}</Box>
						</Flex>
						<Flex justify={"space-between"} mt={1}>
							<Flex direction="column">
								<Flex align={"center"}>
									<Box>Has Registration: </Box>
									<Box>
										<Box display={"flex"} justifyContent={"center"}>
											{user.registration ? (
												<BsCheck2
													size={"20px"}
													color={"#2abd51"}
												/>
											) : (
												<BsX size={"22px"} color={"gray"} />
											)}
										</Box>
									</Box>
								</Flex>
								<Flex align={"center"}>
									<Box>Has Profile: </Box>
									<Box>
										<Box display={"flex"} justifyContent={"center"}>
											{user.profile ? (
												<BsCheck2
													size={"20px"}
													color={"#2abd51"}
												/>
											) : (
												<BsX size={"22px"} color={"gray"} />
											)}
										</Box>
									</Box>
								</Flex>
								<Flex align={"center"}>
									<Box>Created at: </Box>
									<Box ml="1">
										<Box display={"flex"} justifyContent={"center"}>
											{formatDate(user.createdAt, {
												includeTime: true,
											})}
										</Box>
									</Box>
								</Flex>
							</Flex>
							<Flex direction={"column"}>
								<AuthMethodTag authMethod={user.authMethod} />
								<Switch
									isChecked={user.isReviewer}
									onChange={(val) => {
										handleUserReviewerChange(val.target.checked, user)
									}}
								/>
							</Flex>
						</Flex>
					</Card>
				))}
			</Box>
		</>
	)
}
