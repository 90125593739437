import { BusinessRegistrationType, BusinessType } from "src/utils/enums"
import { Business } from "../../entities/business"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	businessTypes?: BusinessType[]
	registrationTypes?: BusinessRegistrationType[]
	categoryIds?: string[]
	createdByIds?: string[]
	currentGeographicalLocations?: {
		countryIds?: string[]
		stateIds?: string[]
		districtIds?: string[]
		talukaIds?: string[]
		villageIds?: string[]
	}
	isVerified?: boolean
	hasFavourite?: boolean
	hasRequestedDeletion?: boolean
	sortingFields?: {
		createdAt?: "ASC" | "DESC"
		updatedAt?: "ASC" | "DESC"
		name?: "ASC" | "DESC"
		popular?: "ASC" | "DESC"
	}
	fetch?: {
		createdBy?: { profile?: boolean; profileDetails?: boolean }
		members?: {
			nativeVillage?: {
				taluka?: boolean
				district?: boolean
				state?: boolean
				country?: boolean
			}
			currentVillage?: {
				taluka?: boolean
				district?: boolean
				state?: boolean
				country?: boolean
			}
			educations?: {
				educationCategory?: boolean
			}
			occupations?: {
				occupationCategory?: boolean
			}
			surname?: {
				subCaste?: boolean
			}
			reviewedBy?: {
				details?: boolean
			}
			createdBy?: {
				details?: boolean
			}
			updatedBy?: {
				details?: boolean
			}
			acquiredBy?: {
				details?: boolean
			}
			mobile?: boolean
		}
		category?: {
			childCategories?: boolean
			parentCategory?: boolean
		}
		currentVillage?: {
			taluka?: boolean
			talukaDistrict?: boolean
			talukaDistrictState?: boolean
			talukaDistrictStateCountry?: boolean
		}
		allImages?: boolean
		logoImage?: boolean
		identityProofImage?: boolean
		servingLocation?: boolean
	}
	pagination?: {
		limit: number
		page: number
	}
}

export async function businessListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/business/list/v1"
	const requester = new ApiRequester<RequestDataShape, Business[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
