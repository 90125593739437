import { ComponentProps, FC, useCallback } from "react"
import { facilityAddApi } from "src/domain/api/facility/facilityAddApi"
import { Facility } from "../../../../domain/entities/facility"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { FacilityAddDrawerFormView } from "./FacilityAddDrawerFormView"
import { IFacilityAddFormFields } from "./IFacilityAddFormFields"

interface Props
	extends Omit<ComponentProps<typeof FacilityAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (facility: Facility) => void
}

export const FacilityAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IFacilityAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const facility = await facilityAddApi(values, token)
				onSuccess(facility)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	return <FacilityAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
