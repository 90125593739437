import { Box, Flex, FormControl, Input, Stack, Switch, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { State } from "src/domain/entities/state"
import { IDistrictUpdateFormFields } from "."
import { District } from "../../../../domain/entities/district"
import { fetchGujaratiSuggestions } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	district: District
	handleSubmit: FormikOnSubmit<IDistrictUpdateFormFields>
	stateList: State[]
}

export const DistrictUpdateDrawerFormView: FC<Props> = ({
	district,
	handleSubmit,
	stateList,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IDistrictUpdateFormFields>
			initialValues={{
				name: district.name,
				stateId: district.state?.id,
				isApproved: true,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const stateOptions = stateList.map((state) => ({
					value: state.id,
					label: state.name.en + ` (${state.name.gu})`,
				}))

				return (
					<DrawerForm
						size="sm"
						headerLabel="Update District"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* State */}
							<FormControl flex={2}>
								<InputLabel label="State" />
								<ReactSelect
									name="stateId"
									onChange={(newValue) => {
										setFieldValue(
											"stateId",
											(newValue as SelectOption).value,
										)
									}}
									value={stateOptions.find(
										(el) => el.value === values.stateId,
									)}
									options={stateOptions}
									isSearchable
								/>
							</FormControl>
							<Flex gridGap={2}>
								<Box>
									{/* Name */}
									<FormControl>
										<InputLabel label="District Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={50}
											required
											autoFocus
											value={values.name?.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>

								{/* Name Gu */}
								<FormControl>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={50}
										required
										value={values.name?.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
							{/* Is Approved */}
							<FormControl>
								<InputLabel label="Approved?" />
								<Box>
									<Switch
										isChecked={values.isApproved}
										onChange={(val) => {
											setFieldValue(
												"isApproved",
												val.target.checked,
											)
										}}
									/>
								</Box>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
