import { FC } from "react"
import { EducationCategory } from "../../../../domain/entities/educationCategory"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { EducationCategoryListView } from "./EducationCategoryListView"

interface Props {
	list: EducationCategory[]
	isLoading: boolean
	onUpdate: (educationCategory: EducationCategory) => void
	onDelete: (educationCategory: EducationCategory) => void
	onAddClick: () => void
}

export const EducationCategoryListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection
				message="No EducationCategories found."
				onAddClick={onAddClick}
			/>
		)
	}

	return (
		<EducationCategoryListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
	)
}
