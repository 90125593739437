import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { SubCaste } from "src/domain/entities/subCaste"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { Surname } from "../../../domain/entities/surname"
import { useSubCasteListApi, useSurnameListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { SurnameAddDrawerFormController } from "./SurnameAddDrawerForm"
import { SurnameDeleteDialogController } from "./SurnameDeleteDialogController"
import { SurnameListController } from "./SurnameList"
import { SurnameUpdateDrawerFormController } from "./SurnameUpdateDrawerForm"
import { SurnameRelatedSurnamesDrawer } from "./SurnameList/SurnameRelatedSurnamesDrawer"

export const SurnamesPage: FC = () => {
	const { surnameList, isLoading, fetchSurnameList } = useSurnameListApi()
	const [isSurnameDeleteDialogOpen, setIsSurnameDeleteDialogOpen] = useState(false)
	const surnameAddDrawerDisclosure = useDisclosure()
	const surnameUpdateDrawerDisclosure = useDisclosure()
	const surnameRelatedSurnamesDrawerDisclosure = useDisclosure()

	const selectedSurnameRef = useRef<Surname>()

	const { subCasteList, fetchSubCasteList } = useSubCasteListApi()

	const [selectedSubCaste, setSelectedSubCaste] = useState<SubCaste>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchSurnames = useCallback(async () => {
		return await fetchSurnameList({
			fetch: { subCaste: true, related: true },
			search: searchText,
			subCasteId: selectedSubCaste?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchSurnameList, pagination.currentPage, selectedSubCaste?.id, searchText])

	useEffect(() => {
		fetchSurnames()
	}, [fetchSurnames])

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const updateSurnameRef = useRef<Surname>()
	const deleteSurnameRef = useRef<Surname>()

	const subCasteOptions: {
		label: string
		value?: SubCaste
	}[] = subCasteList.map((subCaste) => ({
		label: subCaste.name.en,
		value: subCaste,
	}))

	subCasteOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text
						fontSize="2xl"
						fontWeight="bold"
						display={{ base: "none", lg: "block" }}
					>
						Surnames
					</Text>
					<Button
						display={{ base: "none", lg: "block" }}
						size="sm"
						onClick={surnameAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Surname
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "100%", lg: "fit-content" }}>
						<InputGroup width={{ base: "full", lg: "fit-content" }}>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Sub Caste */}
					<Box width={{ base: "full", lg: "20%" }} mt={"2"}>
						<InputLabel label="Sub Caste" />
						<ReactSelect
							name="subCasteId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedSubCaste(val?.value)
							}}
							value={subCasteOptions.find(
								(el) => el.value?.id === selectedSubCaste?.id,
							)}
							options={subCasteOptions}
						/>
					</Box>
					<Button
						display={{ base: "block", lg: "none" }}
						size="sm"
						onClick={surnameAddDrawerDisclosure.onOpen}
						colorScheme="blue"
						m={"auto"}
						mt={"2"}
					>
						Add Surname
					</Button>
				</Flex>
			</Box>
			<SurnameListController
				list={surnameList}
				isLoading={isLoading}
				onUpdate={(surname: Surname) => {
					updateSurnameRef.current = surname
					surnameUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(surname: Surname) => {
					deleteSurnameRef.current = surname
					setIsSurnameDeleteDialogOpen(true)
				}}
				onRelatedSurnamesButtonClick={(surname: Surname) => {
					selectedSurnameRef.current = surname
					surnameRelatedSurnamesDrawerDisclosure.onOpen()
				}}
				onAddClick={surnameAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={surnameList.length === 0}
			/>
			{surnameAddDrawerDisclosure.isOpen ? (
				<SurnameAddDrawerFormController
					{...surnameAddDrawerDisclosure}
					onSuccess={() => fetchSurnames()}
				/>
			) : null}
			{selectedSurnameRef.current &&
			surnameRelatedSurnamesDrawerDisclosure.isOpen ? (
				<SurnameRelatedSurnamesDrawer
					{...surnameRelatedSurnamesDrawerDisclosure}
					surname={selectedSurnameRef.current}
				/>
			) : null}
			{updateSurnameRef.current && surnameUpdateDrawerDisclosure.isOpen ? (
				<SurnameUpdateDrawerFormController
					{...surnameUpdateDrawerDisclosure}
					surname={updateSurnameRef.current}
					onSuccess={() => fetchSurnames()}
				/>
			) : null}

			{deleteSurnameRef.current && isSurnameDeleteDialogOpen ? (
				<SurnameDeleteDialogController
					isOpen={isSurnameDeleteDialogOpen}
					setIsOpen={setIsSurnameDeleteDialogOpen}
					surname={deleteSurnameRef.current}
					onSuccess={() => fetchSurnames()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
