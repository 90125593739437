import { MultiLangText } from "src/utils/types"
import { EducationCategory } from "../../entities/educationCategory"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	parentCategoryId?: string
}

export async function educationCategoryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/educationCategory/add/v1"
	const requester = new ApiRequester<RequestDataShape, EducationCategory>(endpoint)
	return await requester.sendRequest(payload, token)
}
