import { FormControl, Stack, Textarea } from "@chakra-ui/react"
import { Formik } from "formik"
import { ComponentProps, FC } from "react"
import { DrawerForm } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { DiscussionReply } from "src/domain/entities/discussionReply"
import { FormikOnSubmit } from "src/utils/types"
import { IDiscussionsReplyUpdateFormFields } from "./IDiscussionsReplyUpdateFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	discussionReply: DiscussionReply
	handleSubmit: FormikOnSubmit<IDiscussionsReplyUpdateFormFields>
	discussionReplyList: DiscussionReply[]
}

export const DiscussionsReplyUpdateDrawerFormView: FC<Props> = ({
	discussionReply,
	handleSubmit,
	discussionReplyList,
	...rest
}) => {
	return (
		<Formik<IDiscussionsReplyUpdateFormFields>
			initialValues={{
				text: discussionReply.text,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange }) => {
				return (
					<DrawerForm
						size="sm"
						headerLabel="Update Discussion Reply"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Discussion Reply Text */}
							<FormControl mt={2}>
								<InputLabel label="Discussion Reply" />
								<Textarea
									name="text"
									value={values.text}
									onChange={handleChange}
									size={"lg"}
									width={"full"}
									height={"50vh"}
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
