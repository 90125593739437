import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { formatDate } from "src/utils/helpers"
import { SubCaste } from "../../../../domain/entities/subCaste"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const SubCasteListView: FC<{
	list: SubCaste[]
	onUpdate: (subCaste: SubCaste) => void
	onDelete: (subCaste: SubCaste) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Code</Th>
				<Th>Name</Th>
				<Th>Name Gujarati</Th>
				<Th>Created At</Th>
				<Th>Updated At</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((subCaste, i) => (
				<Tr key={i}>
					<Td>{subCaste.code}</Td>
					<Td>{subCaste.name.en}</Td>
					<Td>{subCaste.name.gu}</Td>
					<Td>{formatDate(subCaste.createdAt)}</Td>
					<Td>{formatDate(subCaste.updatedAt)}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(subCaste)} />
						<DeleteIconButton onClick={() => onDelete(subCaste)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
