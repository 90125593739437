import { useToast } from "@chakra-ui/react"
import moment from "moment"
import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { profileUpdateApi } from "src/domain/api/profile/profileUpdateApi"
import { Profile } from "src/domain/entities/profile"
import { DomainError } from "src/domain/errors"
import { useAuth, useSurnameListApi } from "src/domain/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IProfileBasicUpdateFormFields } from "./IProfileBasicUpdateFormFields"
import { ProfileBasicUpdateDrawerFormView } from "./ProfileBasicUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof ProfileBasicUpdateDrawerFormView>,
	"handleSubmit" | "surnameList" | "surnameSearchText" | "setSurnameSearchText"
> & {
	onSuccess?: (profileBasic: Profile) => void
}

export const ProfileBasicUpdateDrawerFormController: FC<Props> = ({
	profile,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()
	const toast = useToast()

	const handleSubmit = useCallback<FormikOnSubmit<IProfileBasicUpdateFormFields>>(
		async (values) => {
			try {
				if (!profile) return

				if (values.dateOfBirth) {
					values.dateOfBirth = moment(values.dateOfBirth).unix() * 1000
				}
				if (values.dateOfDeath) {
					values.dateOfDeath = moment(values.dateOfDeath).unix() * 1000
				}

				const updatedState = await profileUpdateApi(
					{ id: profile.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedState)
				rest.onClose()
			} catch (err) {
				if (err instanceof DomainError) {
					toast({
						title: err.name,
						description: err.message,
						status: "error",
						position: "bottom-right",
						isClosable: true,
						duration: 10000,
					})
				}
			}
		},
		[onSuccess, rest, token, profile, toast],
	)
	const { fetchSurnameList, surnameList } = useSurnameListApi()

	const [surnameSearchText, setSurnameSearchText] = useState<string>("")

	useEffect(() => {
		fetchSurnameList({
			search: surnameSearchText,
			pagination: {
				page: 1,
				limit: 20,
			},
			subCasteId: profile?.surname?.subCaste?.id,
		})
	}, [fetchSurnameList, surnameSearchText, profile])

	return (
		<ProfileBasicUpdateDrawerFormView
			profile={profile}
			surnameList={surnameList}
			handleSubmit={handleSubmit}
			surnameSearchText={surnameSearchText}
			setSurnameSearchText={setSurnameSearchText}
			{...rest}
		/>
	)
}
