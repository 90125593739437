import { Profile } from "src/domain/entities/profile"
import { BloodGroup, Gender, MaritalStatus } from "src/utils/enums"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		firstName?: MultiLangText
		gender?: Gender
		dateOfBirth?: number
		isReviewPending?: boolean
		isVerified?: boolean
		dateOfDeath?: number
		verifiedAt?: number
		isAlive?: boolean
		isBornNonPatidar?: boolean
		isMarriedToNonPatidar?: boolean
		surnameId?: string
		nativeVillageId?: string
		currentVillageId?: string
		profileImageId?: string
		reviewedById?: string
		willingToDonateBlood?: boolean
		mobile?: {
			countryCode?: string
			mobileNo?: string
		}
		bloodGroup?: BloodGroup
		maritalStatus?: MaritalStatus
	}
}

export async function profileUpdateApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/profile/update/v1"
	const requester = new ApiRequester<RequestDataShape, Profile>(endpoint)
	return await requester.sendRequest(payload, token)
}
