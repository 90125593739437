import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { matrimonyProfileAddApi } from "src/domain/api/matrimonyProfile/matrimonyProfileAddApi"
import { File } from "src/domain/entities/file"
import { MatrimonyProfile } from "src/domain/entities/matrimonyProfile"
import { useAuth, useVillageListApi } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IMatrimonyProfileAddFormFields } from "./IMatrimonyProfileAddFormFields"
import { MatrimonyProfileAddDrawerFormView } from "./MatrimonyProfileAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof MatrimonyProfileAddDrawerFormView>,
		| "handleSubmit"
		| "villageList"
		| "setVillageSearchText"
		| "propertiesList"
		| "handleUploadImage"
		| "selectedImage"
		| "setSelectedImage"
	> {
	onSuccess: (matrimonyProfile: MatrimonyProfile) => void
}

export const MatrimonyProfileAddDrawerFormController: FC<Props> = ({
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IMatrimonyProfileAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const matrimonyProfile = await matrimonyProfileAddApi(values, token)
				onSuccess(matrimonyProfile)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					aboutMe: message,
				})
			}
		},
		[onSuccess, rest, token],
	)

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)

			return response as File
		},
		[token],
	)

	const { fetchVillageList, villageList } = useVillageListApi()

	const [villageSearchText, setVillageSearchText] = useState("")
	useEffect(() => {
		fetchVillageList({
			search: villageSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {
				talukaDistrict: true,
			},
		})
	}, [fetchVillageList, villageSearchText])

	return (
		<MatrimonyProfileAddDrawerFormView
			handleSubmit={handleSubmit}
			villageList={villageList}
			setVillageSearchText={setVillageSearchText}
			selectedImage={selectedImage}
			setSelectedImage={setSelectedImage}
			handleUploadImage={handleUploadImage}
			{...rest}
		/>
	)
}
