import { ComponentProps, FC, useCallback } from "react"
import { discussionCategoryUpdateApi } from "src/domain/api/discussionCategory/discussionCategoryUpdateApi"
import { DiscussionCategory } from "../../../../domain/entities/discussionCategory"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { DiscussionCategoryUpdateDrawerFormView } from "./DiscussionCategoryUpdateDrawerFormView"
import { IDiscussionCategoryUpdateFormFields } from "./IDiscussionCategoryUpdateFormFields"

type Props = Omit<
	ComponentProps<typeof DiscussionCategoryUpdateDrawerFormView>,
	"handleSubmit"
> & {
	onSuccess?: (discussionCategory: DiscussionCategory) => void
}

export const DiscussionCategoryUpdateDrawerFormController: FC<Props> = ({
	discussionCategory,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IDiscussionCategoryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedDiscussionCategory = await discussionCategoryUpdateApi(
					{ id: discussionCategory.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedDiscussionCategory)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, discussionCategory.id],
	)

	return (
		<DiscussionCategoryUpdateDrawerFormView
			discussionCategory={discussionCategory}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
