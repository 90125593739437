import { useCallback, useState } from "react"
import { unitListApi } from "../api/unit/unitListApi"
import { Unit } from "../entities/unit"
import { useAuth } from "./useAuth"

export function useUnitListApi(): {
	unitList: Unit[]
	isLoading: boolean
	fetchUnitList: (filter?: Parameters<typeof unitListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [unitList, setUnitList] = useState<Unit[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchUnitList = useCallback(
		async (filter: Parameters<typeof unitListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await unitListApi(filter, token)
			setUnitList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { unitList, isLoading, fetchUnitList }
}
