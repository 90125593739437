import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { District } from "src/domain/entities/district"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { Taluka } from "../../../domain/entities/taluka"
import { useDistrictListApi, useTalukaListApi } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { TalukaAddDrawerFormController } from "./TalukaAddDrawerForm"
import { TalukaDeleteDialogController } from "./TalukaDeleteDialogController"
import { TalukaListController } from "./TalukaList"
import { TalukaUpdateDrawerFormController } from "./TalukaUpdateDrawerForm"

export const TalukasPage: FC = () => {
	const { talukaList, isLoading, fetchTalukaList } = useTalukaListApi()
	const [isTalukaDeleteDialogOpen, setIsTalukaDeleteDialogOpen] = useState(false)
	const talukaAddDrawerDisclosure = useDisclosure()
	const talukaUpdateDrawerDisclosure = useDisclosure()

	const { districtList, fetchDistrictList } = useDistrictListApi()

	const [selectedState, setSelectedState] = useState<District>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchTalukas = useCallback(async () => {
		return await fetchTalukaList({
			fetch: { district: true, districtState: true, districtStateCountry: true },
			search: searchText,
			districtId: selectedState?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchTalukaList, pagination.currentPage, selectedState?.id, searchText])

	useEffect(() => {
		fetchTalukas()
		fetchDistrictList()
	}, [fetchTalukas, fetchDistrictList])

	const updateTalukaRef = useRef<Taluka>()
	const deleteTalukaRef = useRef<Taluka>()

	const districtOptions: {
		label: string
		value?: District
	}[] = districtList.map((district) => ({
		label: district.name.en,
		value: district,
	}))

	districtOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Talukas
					</Text>
					<Button
						size="sm"
						onClick={talukaAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Taluka
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* District */}
					<Box width={{ base: "full", lg: "20%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="District" />
						<ReactSelect
							name="districtId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedState(val?.value)
							}}
							value={districtOptions.find(
								(el) => el.value?.id === selectedState?.id,
							)}
							options={districtOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<TalukaListController
				list={talukaList}
				isLoading={isLoading}
				onUpdate={(taluka: Taluka) => {
					updateTalukaRef.current = taluka
					talukaUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(taluka: Taluka) => {
					deleteTalukaRef.current = taluka
					setIsTalukaDeleteDialogOpen(true)
				}}
				onAddClick={talukaAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={talukaList.length === 0}
			/>
			{talukaAddDrawerDisclosure.isOpen ? (
				<TalukaAddDrawerFormController
					{...talukaAddDrawerDisclosure}
					onSuccess={() => fetchTalukas()}
				/>
			) : null}
			{updateTalukaRef.current && talukaUpdateDrawerDisclosure.isOpen ? (
				<TalukaUpdateDrawerFormController
					{...talukaUpdateDrawerDisclosure}
					taluka={updateTalukaRef.current}
					onSuccess={() => fetchTalukas()}
				/>
			) : null}
			{deleteTalukaRef.current && isTalukaDeleteDialogOpen ? (
				<TalukaDeleteDialogController
					isOpen={isTalukaDeleteDialogOpen}
					setIsOpen={setIsTalukaDeleteDialogOpen}
					taluka={deleteTalukaRef.current}
					onSuccess={() => fetchTalukas()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
