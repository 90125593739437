import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { organizationUpdateApi } from "src/domain/api/organization/organizationUpdateApi"
import { File } from "src/domain/entities/file"
import { Organization } from "../../../../domain/entities/organization"
import {
	useAuth,
	useFacilityListApi,
	useSubCasteListApi,
	useVillageListApi,
} from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IOrganizationUpdateFormFields } from "./IOrganizationUpdateFormFields"
import { OrganizationUpdateDrawerFormView } from "./OrganizationUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof OrganizationUpdateDrawerFormView>,
	| "handleSubmit"
	| "villageList"
	| "setVillageSearchText"
	| "handleUploadImage"
	| "selectedImage"
	| "setSelectedImage"
	| "subCasteList"
	| "facilityList"
> & {
	onSuccess?: (organization: Organization) => void
}

export const OrganizationUpdateDrawerFormController: FC<Props> = ({
	organization,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IOrganizationUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedOrganization = await organizationUpdateApi(
					{
						id: organization.id,
						update: values,
					},
					token,
				)
				onSuccess && onSuccess(updatedOrganization)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, organization.id],
	)

	const [selectedImage, setSelectedImage] = useState<File | undefined>(
		organization.featuredImage,
	)

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)

			return response as File
		},
		[token],
	)

	const { fetchSubCasteList, subCasteList } = useSubCasteListApi()

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const { fetchFacilityList, facilityList } = useFacilityListApi()

	useEffect(() => {
		fetchFacilityList()
	}, [fetchFacilityList])

	const { fetchVillageList, villageList } = useVillageListApi()
	const [villageSearchText, setVillageSearchText] = useState(
		organization.village?.name.en ?? "",
	)
	useEffect(() => {
		fetchVillageList({
			search: villageSearchText,
			pagination: { limit: 20, page: 1 },
			fetch: {
				talukaDistrict: true,
			},
		})
	}, [fetchVillageList, villageSearchText])

	return (
		<OrganizationUpdateDrawerFormView
			organization={organization}
			handleSubmit={handleSubmit}
			villageList={villageList}
			setVillageSearchText={setVillageSearchText}
			selectedImage={selectedImage}
			setSelectedImage={setSelectedImage}
			handleUploadImage={handleUploadImage}
			subCasteList={subCasteList}
			facilityList={facilityList}
			{...rest}
		/>
	)
}
