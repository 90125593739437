import { Image, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"
import { DashboardWrapper } from "src/components/wrappers"
import { reviewResetApi } from "src/domain/api/review/reviewResetApi"
import { reviewerRegistrationsUnlinkApi } from "src/domain/api/reviewer/reviewerRegistrationsUnlinkApi"
import { useAuth, useRegistrationListApi } from "src/domain/hooks"
import { useMobileListApi } from "src/domain/hooks/useMobileListApi"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { ProfileDetailsModal } from "../../ProfilesPage/ProfileDetailsModal"
import { SurnameAddDrawerFormController } from "../../SurnamesPage/SurnameAddDrawerForm"
import { AssignReviewerModalController } from "./AssignReviewerModalController"
import { RegistrationBasicUpdateDrawerFormController } from "./RegistrationBasicUpdateDrawerForm"
import { RegistrationDetailsView } from "./RegistrationDetailsView"
import { RegistrationLocationUpdateDrawerFormController } from "./RegistrationLocationUpdateDrawerForm"

export const RegistrationDetailsController: FC = () => {
	const {
		registrationList: [registration],
		isLoading,
		fetchRegistrationList,
	} = useRegistrationListApi()

	// get id from url
	const params = useParams()
	const { token } = useAuth()

	const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] = useState(false)
	const [isIdentityProofModalOpen, setIsIdentityProofModalOpen] = useState(false)

	const registrationBasicUpdateDrawerDisclosure = useDisclosure()
	const registrationLocationUpdateDrawerDisclosure = useDisclosure()
	const surnameAddDrawerDisclosure = useDisclosure()

	const [isProfileDetailsVisible, setIsProfileDetailsVisible] = useState<boolean>(false)

	const fetchRegistrationDetails = useCallback(async () => {
		const id = params["id"]
		if (!id) return

		return await fetchRegistrationList({
			id,
			fetch: {
				surname: true,
				surnameSubCaste: true,
				nativeVillage: true,
				currentVillage: true,
				reviewer: true,
				reviews: true,
				reviewerDetails: true,
				user: true,
				userDetails: true,
			},
		})
	}, [fetchRegistrationList, params])

	const { fetchMobileList, mobileList } = useMobileListApi()

	const [isAssignReviewerModalOpen, setIsAssignReviewerModalOpen] = useState(false)

	const handleAssignReviewer = useCallback(async () => {
		setIsAssignReviewerModalOpen(true)
	}, [])

	const handleUnAssignReviewer = useCallback(async () => {
		await reviewerRegistrationsUnlinkApi(
			{
				registrationIds: [registration.id],
			},
			token,
		)
		fetchRegistrationDetails()
	}, [registration, token, fetchRegistrationDetails])

	const handleResetReview = useCallback(async () => {
		await reviewResetApi(
			{
				registrationIds: [registration.id],
			},
			token,
		)
		fetchRegistrationDetails()
	}, [token, fetchRegistrationDetails, registration])

	useEffect(() => {
		fetchRegistrationDetails()
	}, [fetchRegistrationDetails])

	useEffect(() => {
		if (registration?.user?.id) {
			fetchMobileList({
				userId: registration?.user?.id,
			})
		}
	}, [fetchMobileList, registration?.user?.id])

	return (
		<>
			<DashboardWrapper>
				{isLoading ? (
					<CenteredSpinner />
				) : (
					<RegistrationDetailsView
						registration={registration}
						handleAssignReviewer={handleAssignReviewer}
						handleUnAssignReviewer={handleUnAssignReviewer}
						handleResetReview={handleResetReview}
						onProfilePictureClick={() => setIsProfilePictureModalOpen(true)}
						onIdentityProofClick={() => setIsIdentityProofModalOpen(true)}
						onBasicUpdateClick={
							registrationBasicUpdateDrawerDisclosure.onOpen
						}
						onLocationUpdateClick={
							registrationLocationUpdateDrawerDisclosure.onOpen
						}
						onNewSurnameClick={surnameAddDrawerDisclosure.onOpen}
						handleViewProfileButtonClick={() =>
							setIsProfileDetailsVisible(true)
						}
						mobile={mobileList[0]}
					/>
				)}
			</DashboardWrapper>
			{isAssignReviewerModalOpen ? (
				<AssignReviewerModalController
					isOpen={isAssignReviewerModalOpen}
					setIsOpen={setIsAssignReviewerModalOpen}
					onSuccess={fetchRegistrationDetails}
					registrations={[registration]}
				/>
			) : null}
			{isProfilePictureModalOpen ? (
				<ImageViewModalController
					isOpen={isProfilePictureModalOpen}
					setIsOpen={setIsProfilePictureModalOpen}
					image={
						<Image
							src={registration.identity?.profileImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
			{isIdentityProofModalOpen ? (
				<ImageViewModalController
					isOpen={isIdentityProofModalOpen}
					setIsOpen={setIsIdentityProofModalOpen}
					image={
						<img
							src={registration.identity?.identityProofUrl}
							alt="identity"
						/>
					}
				/>
			) : null}
			{registrationBasicUpdateDrawerDisclosure.isOpen ? (
				<RegistrationBasicUpdateDrawerFormController
					isOpen={registrationBasicUpdateDrawerDisclosure.isOpen}
					onClose={registrationBasicUpdateDrawerDisclosure.onClose}
					registration={registration}
					onSuccess={fetchRegistrationDetails}
				/>
			) : null}
			{registrationLocationUpdateDrawerDisclosure.isOpen ? (
				<RegistrationLocationUpdateDrawerFormController
					isOpen={registrationLocationUpdateDrawerDisclosure.isOpen}
					onClose={registrationLocationUpdateDrawerDisclosure.onClose}
					registration={registration}
					onSuccess={fetchRegistrationDetails}
				/>
			) : null}
			{surnameAddDrawerDisclosure.isOpen ? (
				<SurnameAddDrawerFormController
					isOpen={surnameAddDrawerDisclosure.isOpen}
					onClose={surnameAddDrawerDisclosure.onClose}
					onSuccess={() => {}}
					initialValues={{
						code: "",
						name: {
							en: registration.basic?.customSurname?.text?.en || "",
							gu: registration.basic?.customSurname?.text?.gu || "",
						},
						subCasteId: "",
					}}
				/>
			) : null}
			{isProfileDetailsVisible && registration.user?.profile ? (
				<ProfileDetailsModal
					isOpen={isProfileDetailsVisible}
					setIsOpen={(val) => {
						if (!val) setIsProfileDetailsVisible(false)
					}}
					profileId={registration.user?.profile.id}
					handleProfileClick={() => {}}
				/>
			) : null}
		</>
	)
}
