import { ComponentProps, FC, useCallback } from "react"
import { subCasteAddApi } from "src/domain/api/subCaste/subCasteAddApi"
import { SubCaste } from "../../../../domain/entities/subCaste"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { ISubCasteAddFormFields } from "./ISubCasteAddFormFields"
import { SubCasteAddDrawerFormView } from "./SubCasteAddDrawerFormView"

interface Props
	extends Omit<ComponentProps<typeof SubCasteAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (subCaste: SubCaste) => void
}

export const SubCasteAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISubCasteAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const subCaste = await subCasteAddApi(values, token)
				onSuccess(subCaste)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	return <SubCasteAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
