import { useToast } from "@chakra-ui/react"
import moment from "moment"
import { ComponentProps, FC, useCallback, useState } from "react"
import { ApiRequester } from "src/domain/api/ApiRequester"
import { profileUpdateApi } from "src/domain/api/profile/profileUpdateApi"
import { File } from "src/domain/entities/file"
import { Profile } from "src/domain/entities/profile"
import { DomainError } from "src/domain/errors"
import { useAuth } from "src/domain/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { IProfileImageUpdateFormFields } from "./IProfileImageUpdateFormFields"
import { ProfileImageUpdateDrawerFormView } from "./ProfileImageUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof ProfileImageUpdateDrawerFormView>,
	"handleSubmit" | "handleUploadImage"
> & {
	onSuccess?: (profileImage: Profile) => void
}

export const ProfileImageUpdateDrawerFormController: FC<Props> = ({
	profile,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()
	const toast = useToast()

	const [selectedImage, setSelectedImage] = useState<File>()

	const handleSubmit = useCallback<FormikOnSubmit<IProfileImageUpdateFormFields>>(
		async (values) => {
			try {
				if (!profile) return

				if (values.dateOfBirth) {
					values.dateOfBirth = moment(values.dateOfBirth).unix() * 1000
				}
				if (values.dateOfDeath) {
					values.dateOfDeath = moment(values.dateOfDeath).unix() * 1000
				}

				values.profileImageId = selectedImage?.id

				const updatedState = await profileUpdateApi(
					{ id: profile.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedState)
				rest.onClose()
			} catch (err) {
				if (err instanceof DomainError) {
					toast({
						title: err.name,
						description: err.message,
						status: "error",
						position: "bottom-right",
						isClosable: true,
						duration: 10000,
					})
				}
			}
		},
		[onSuccess, rest, token, profile, toast, selectedImage],
	)

	const handleUploadImage = useCallback(
		async (file: any) => {
			const formData = new FormData()
			formData.append("file", file)

			const response = await new ApiRequester(
				"/file/upload/v1",
				"POST",
			).sendRequest(formData, token)

			setSelectedImage(response as File)
		},
		[token],
	)

	return (
		<ProfileImageUpdateDrawerFormView
			profile={profile}
			handleSubmit={handleSubmit}
			handleUploadImage={handleUploadImage}
			selectedImage={selectedImage}
			{...rest}
		/>
	)
}
