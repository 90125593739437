import { useCallback, useState } from "react"
import { discussionCategoryListApi } from "../api/discussionCategory/discussionCategoryListApi"
import { DiscussionCategory } from "../entities/discussionCategory"
import { useAuth } from "./useAuth"

export function useDiscussionCategoryListApi(): {
	discussionCategoryList: DiscussionCategory[]
	isLoading: boolean
	fetchDiscussionCategoryList: (
		filter?: Parameters<typeof discussionCategoryListApi>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [discussionCategoryList, setDiscussionCategoryList] = useState<
		DiscussionCategory[]
	>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchDiscussionCategoryList = useCallback(
		async (filter: Parameters<typeof discussionCategoryListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await discussionCategoryListApi(filter, token)
			setDiscussionCategoryList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { discussionCategoryList, isLoading, fetchDiscussionCategoryList }
}
