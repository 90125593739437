import { Admin } from "src/domain/entities/admin"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	self?: boolean
	id?: string
	username?: string
	status?: boolean
	pagination?: RequestPagination
}

export async function adminListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/admin/list/v1"
	const requester = new ApiRequester<RequestDataShape, Admin[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
