import { FC, useCallback, useState } from "react"
import { profileDeleteApi } from "src/domain/api/profile/profileDeleteApi"
import { Profile } from "src/domain/entities/profile"
import { useAuth } from "../../../domain/hooks"
import { getErrorMessage } from "../../../utils/helpers"
import DeleteItemDialog from "../../shared/DeleteItemDialog"

interface Props {
	profile: Profile
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const ProfileDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	profile,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleProfileDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await profileDeleteApi({ id: profile.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [profile, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Profile: ${profile.firstName?.en}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleProfileDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
