import { Discussion } from "src/domain/entities/discussion"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	isActive?: boolean
	isReadonly?: boolean
	hasRequestedDeletion?: boolean
	categoryId?: string
	createdById?: string
	sortingFields?: {
		createdAt?: "ASC" | "DESC"
		updatedAt?: "ASC" | "DESC"
		totalAnswers?: "ASC" | "DESC"
	}
	fetch?: {
		category?: boolean
		createdBy?: {
			profile?: boolean
			profileDetails?: boolean
			registration?: boolean
			registrationDetails?: boolean
		}
		allReplies?: boolean
	}
	pagination?: {
		page: number
		limit: number
	}
}

export async function discussionListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/discussion/list/v1"
	const requester = new ApiRequester<RequestDataShape, Discussion[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
