import { useCallback, useState } from "react"
import { matrimonyProfileListApi } from "../api/matrimonyProfile/matrimonyProfileListApi"
import { MatrimonyProfile } from "../entities/matrimonyProfile"
import { useAuth } from "./useAuth"

export function useMatrimonyProfileListApi(): {
	matrimonyProfileList: MatrimonyProfile[]
	isLoading: boolean
	error: null | Error
	fetchMatrimonyProfileList: (
		filter?: Parameters<typeof matrimonyProfileListApi>[0],
	) => Promise<MatrimonyProfile[]>
} {
	const { token } = useAuth()
	const [matrimonyProfileList, setMatrimonyProfileList] = useState<MatrimonyProfile[]>(
		[],
	)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchMatrimonyProfileList = useCallback(
		async (filter: Parameters<typeof matrimonyProfileListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await matrimonyProfileListApi(filter, token)
				setMatrimonyProfileList(data)
				return data
			} catch (error) {
				setError(error as Error)
				return []
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { matrimonyProfileList, isLoading, error, fetchMatrimonyProfileList }
}
