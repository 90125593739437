import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { Unit } from "src/domain/entities/unit"
import { useUnitListApi } from "src/domain/hooks/useUnitListApi"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { UnitAddDrawerFormController } from "./UnitAddDrawerForm"
import { UnitDeleteDialogController } from "./UnitDeleteDialogController"
import { UnitListController } from "./UnitList"
import { UnitUpdateDrawerFormController } from "./UnitUpdateDrawerForm"

export const UnitsPage: FC = () => {
	const { unitList, isLoading, fetchUnitList } = useUnitListApi()
	const [isUnitDeleteDialogOpen, setIsUnitDeleteDialogOpen] = useState(false)
	const unitAddDrawerDisclosure = useDisclosure()
	const unitUpdateDrawerDisclosure = useDisclosure()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const [searchText, setSearchText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchUnits = useCallback(async () => {
		return await fetchUnitList({
			search: searchText,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchUnitList, pagination.currentPage, searchText])

	useEffect(() => {
		fetchUnits()
	}, [fetchUnits])

	const updateUnitRef = useRef<Unit>()
	const deleteUnitRef = useRef<Unit>()

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Units
					</Text>
					<Button
						size="sm"
						onClick={unitAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Unit
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex gridColumnGap={2} align="flex-end">
					<InputGroup width={"fit-content"}>
						<Input
							value={searchText}
							onChange={(e) => {
								pagination.setCurrentPage(1)
								setSearchText(e.target.value)
								getGujaratiSuggestions(e.target.value)
							}}
							type="text"
							placeholder="Search"
						/>
						<InputRightAddon>
							<BsSearch />
						</InputRightAddon>
					</InputGroup>
					{gujaratiSuggestions.map((el, i) => (
						<Tag
							key={i}
							colorScheme={"green"}
							backgroundColor={"green.50"}
							variant="outline"
							_hover={{
								backgroundColor: "green.100",
							}}
							cursor="pointer"
							margin={0.5}
							onClick={() => {
								pagination.setCurrentPage(1)
								setSearchText(el)
								setGujaratiSuggestions([])
							}}
						>
							{el}
						</Tag>
					))}
				</Flex>
			</Box>
			<UnitListController
				list={unitList}
				isLoading={isLoading}
				onUpdate={(unit: Unit) => {
					updateUnitRef.current = unit
					unitUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(unit: Unit) => {
					deleteUnitRef.current = unit
					setIsUnitDeleteDialogOpen(true)
				}}
				onAddClick={unitAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={unitList.length === 0}
			/>
			{unitAddDrawerDisclosure.isOpen ? (
				<UnitAddDrawerFormController
					{...unitAddDrawerDisclosure}
					onSuccess={() => fetchUnitList()}
				/>
			) : null}
			{updateUnitRef.current && unitUpdateDrawerDisclosure.isOpen ? (
				<UnitUpdateDrawerFormController
					{...unitUpdateDrawerDisclosure}
					unit={updateUnitRef.current}
					onSuccess={() => fetchUnitList()}
				/>
			) : null}
			{deleteUnitRef.current && isUnitDeleteDialogOpen ? (
				<UnitDeleteDialogController
					isOpen={isUnitDeleteDialogOpen}
					setIsOpen={setIsUnitDeleteDialogOpen}
					unit={deleteUnitRef.current}
					onSuccess={() => fetchUnitList()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
