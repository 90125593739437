import moment from "moment"
import { ComponentProps, FC, useCallback } from "react"
import { iosBuildAddApi } from "src/domain/api/iosBuild/iosBuildAddApi"
import { IosBuild } from "../../../../domain/entities/iosBuild"
import { useAuth } from "../../../../domain/hooks"
import { FormikOnSubmit } from "../../../../utils/types"
import { IIosBuildAddFormFields } from "./IIosBuildAddFormFields"
import { IosBuildAddDrawerFormView } from "./IosBuildAddDrawerFormView"

interface Props
	extends Omit<ComponentProps<typeof IosBuildAddDrawerFormView>, "handleSubmit"> {
	onSuccess: (iosBuild: IosBuild) => void
}

export const IosBuildAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IIosBuildAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				values.publishDate = moment(values.publishDate).unix() * 1000

				const iosBuild = await iosBuildAddApi(values, token)
				onSuccess(iosBuild)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ version: message })
			}
		},
		[onSuccess, rest, token],
	)

	return <IosBuildAddDrawerFormView handleSubmit={handleSubmit} {...rest} />
}
