import { useCallback, useState } from "react"
import { districtListApi } from "../api/district/districtListApi"
import { District } from "../entities/district"
import { useAuth } from "./useAuth"

export function useDistrictListApi(): {
	districtList: District[]
	isLoading: boolean
	error: null | Error
	fetchDistrictList: (filter?: Parameters<typeof districtListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [districtList, setDistrictList] = useState<District[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchDistrictList = useCallback(
		async (filter: Parameters<typeof districtListApi>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await districtListApi(filter, token)
				setDistrictList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { districtList, isLoading, error, fetchDistrictList }
}
