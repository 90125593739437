import {
	Box,
	Button,
	Flex,
	FormControl,
	Image,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Tag,
	Text,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { BsImage } from "react-icons/bs"
import ReactSelect from "react-select"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SelectOption } from "src/components/shared/ReactSelect"
import { File } from "src/domain/entities/file"
import { Surname } from "src/domain/entities/surname"
import { Village } from "src/domain/entities/village"
import { Gender, MaritalStatus } from "src/utils/enums"
import { IProfileAddFormFields } from "."
import { fetchGujaratiSuggestions, getVillageName } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IProfileAddFormFields>
	villageList: Village[]
	setVillageSearchText: (text: string) => void
	surnameList: Surname[]
	setSurnameSearchText: (text: string) => void
	handleUploadImage: (file: any) => Promise<File>
	selectedImage?: File
	initialValues?: IProfileAddFormFields
	setSelectedImage: (file?: File) => void
}

export const ProfileAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	initialValues,
	villageList,
	setVillageSearchText,
	surnameList,
	setSurnameSearchText,
	handleUploadImage,
	selectedImage,
	setSelectedImage,
	...rest
}) => {
	const [firstNameSuggestions, setFirstNameSuggestions] = useState<string[]>([])
	const [profileImageId, setProfileImageId] = useState<string>("")

	const [isProfileImageUploading, setIsProfileImageUploading] = useState<boolean>(false)

	return (
		<Formik<IProfileAddFormFields>
			initialValues={{
				firstName: {
					en: "",
					gu: "",
				},
				gender: Gender.MALE,
				isAlive: true,
				profileImageId,
				isBornNonPatidar: false,
				isMarriedToNonPatidar: false,
				maritalStatus: MaritalStatus.SINGLE,
				nativeVillageId: "",
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleProfileImageSelected = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					if (!e.target.files?.[0]) return
					setIsProfileImageUploading(true)
					const res = await handleUploadImage(e.target.files?.[0])
					setFieldValue("profileImageId", res.id)
					setProfileImageId(res.id)
					setIsProfileImageUploading(false)
				}

				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("firstName.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setFirstNameSuggestions(suggestions)
				}

				const villageOptions = villageList.map((village) => ({
					value: village.id,
					label: getVillageName(village),
				}))

				const surnameOptions = surnameList.map((surname) => ({
					value: surname.id,
					label:
						surname.name.en +
						" (" +
						surname.name?.en +
						")" +
						" - " +
						surname.subCaste?.name?.en,
				}))

				const maritalStatusOptions = [
					{ value: MaritalStatus.SINGLE, label: "Single" },
					{ value: MaritalStatus.ENGAGED, label: "Engaged" },
					{ value: MaritalStatus.MARRIED, label: "Married" },
					{ value: MaritalStatus.SEPARATED, label: "Separated" },
					{ value: MaritalStatus.DIVORCED, label: "Divorced" },
					{ value: MaritalStatus.WIDOW, label: "Widow" },
				]

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Profile"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gap={2}>
								<FormControl>
									<InputLabel label="First name" />
									<Input
										name="firstName.en"
										placeholder="First name"
										maxLength={50}
										required
										autoFocus
										value={values.firstName.en}
										onChange={handleNameChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="firstName.en"
									/>
								</FormControl>
								<FormControl>
									<InputLabel label="First name (GU)" />
									<Input
										name="firstName.gu"
										placeholder="First name (GU)"
										maxLength={50}
										required
										value={values.firstName.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="firstName.gu"
									/>
								</FormControl>
							</Flex>
							<Box>
								{firstNameSuggestions.map((el, i) => (
									<Tag
										colorScheme={"green"}
										backgroundColor={"green.50"}
										variant="outline"
										_hover={{
											backgroundColor: "green.100",
										}}
										cursor="pointer"
										margin={0.5}
										onClick={() => {
											setFieldValue("firstName.gu", el)
											setFirstNameSuggestions([])
										}}
										key={i}
									>
										{el}
									</Tag>
								))}
							</Box>
							<FormControl flex={2}>
								<InputLabel label="Surname" />
								<ReactSelect
									name="surnameId"
									onChange={(newValue) => {
										setFieldValue(
											"surnameId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(text) => {
										setSurnameSearchText(text)
									}}
									value={surnameOptions.find(
										(el) => el.value === values.surnameId,
									)}
									options={surnameOptions}
									isSearchable
								/>
							</FormControl>
							<FormControl flex={2}>
								<InputLabel label="Native village" />
								<ReactSelect
									name="nativeVillageId"
									onChange={(newValue) => {
										setFieldValue(
											"nativeVillageId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(text) => {
										setVillageSearchText(text)
									}}
									value={villageOptions.find(
										(el) => el.value === values.nativeVillageId,
									)}
									options={villageOptions}
									isSearchable
								/>
							</FormControl>
							{/* Gender */}
							<FormControl flex={1}>
								<InputLabel label="Gender" />
								<RadioGroup
									name="gender"
									value={values.gender}
									onChange={(e) => {
										setFieldValue("gender", e)
									}}
								>
									<Stack direction="row">
										<Radio value={Gender.MALE}>Male</Radio>
										<Radio value={Gender.FEMALE}>Female</Radio>
									</Stack>
								</RadioGroup>
							</FormControl>
							{/* Marital Status */}
							<FormControl flex={2}>
								<InputLabel label="Marital Status" />
								<ReactSelect
									name="maritalStatus"
									onChange={(newValue) => {
										setFieldValue(
											"maritalStatus",
											(newValue as SelectOption).value,
										)
									}}
									value={maritalStatusOptions.find(
										(el) => el.value === values.maritalStatus,
									)}
									options={maritalStatusOptions}
								/>
							</FormControl>
							{/* Is Alive? */}
							<FormControl flex={1}>
								<InputLabel label="Is Alive?" />
								<RadioGroup
									name="isAlive"
									value={values.isAlive.toString()}
									onChange={(e) => {
										setFieldValue("isAlive", e === "true")
									}}
								>
									<Stack direction="row">
										<Radio value={true.toString()}>Yes</Radio>
										<Radio value={false.toString()}>No</Radio>
									</Stack>
								</RadioGroup>
							</FormControl>
							{/* Image file */}
							<FormControl flex={2}>
								<InputLabel label="Featured Image" />
								<Box
									position={"relative"}
									backgroundColor={"gray.100"}
									color={"gray.700"}
									rounded={"lg"}
								>
									{selectedImage ? (
										<Flex
											p={4}
											align={"center"}
											justify={"space-between"}
										>
											<Flex flex={1} align={"center"}>
												<Image
													src={selectedImage?.variants?.[0]}
													alt="Image preview"
													width="30%"
													objectFit="cover"
													rounded="lg"
												/>
												<Text ml={4}>Image Selected!</Text>
											</Flex>
											<Button
												variant="outline"
												colorScheme="red"
												onClick={(e) => {
													e.stopPropagation()
													setSelectedImage(undefined)
												}}
											>
												Remove
											</Button>
										</Flex>
									) : (
										<>
											<Flex p={8}>
												{isProfileImageUploading ? (
													<Box mx={"auto"}>
														<CenteredSpinner />
													</Box>
												) : (
													<>
														<BsImage size={24} />
														<Text ml={2}>
															Pick an Image here...
														</Text>
													</>
												)}
											</Flex>
											<Input
												name="imageFile"
												type="file"
												height="100%"
												width="100%"
												position="absolute"
												top="0"
												left="0"
												opacity="0"
												aria-hidden="true"
												accept="image/*"
												onChange={handleProfileImageSelected}
												cursor="pointer"
											/>
										</>
									)}
								</Box>
							</FormControl>
							{/* Image preview */}
							{selectedImage ? (
								<Box>
									<Image
										src={selectedImage?.variants?.[0]}
										alt="Image preview"
										width="100%"
										height="auto"
										objectFit="cover"
										borderRadius="lg"
									/>
								</Box>
							) : null}
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
