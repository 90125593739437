import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { OccupationCategory } from "src/domain/entities/occupationCategory"
import { IOccupationCategoryAddFormFields } from "."
import { fetchGujaratiSuggestions } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IOccupationCategoryAddFormFields>
	parentOccupationCategoryList: OccupationCategory[]
	initialValues?: IOccupationCategoryAddFormFields
}

export const OccupationCategoryAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	parentOccupationCategoryList,
	initialValues,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IOccupationCategoryAddFormFields>
			initialValues={{
				name: {
					en: "",
					gu: "",
				},
				parentCategoryId: "",
				...initialValues,
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const parentOccupationCategoryOptions: {
					label: string
					value: string | null
				}[] = parentOccupationCategoryList.map((parentOccupationCategory) => ({
					value: parentOccupationCategory.id,
					label:
						parentOccupationCategory.name.en +
						` (${parentOccupationCategory.name.gu})`,
				}))

				parentOccupationCategoryOptions.unshift({
					value: null,
					label: "None",
				})

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Occupation Category"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								{/* ParentOccupationCategory */}
								<FormControl flex={2}>
									<InputLabel label="Parent Occupation Category" />
									<ReactSelect
										name="parentCategoryId"
										onChange={(newValue) => {
											setFieldValue(
												"parentCategoryId",
												(newValue as SelectOption).value,
											)
										}}
										value={parentOccupationCategoryOptions.find(
											(el) => el.value === values.parentCategoryId,
										)}
										options={parentOccupationCategoryOptions}
										isSearchable
									/>
								</FormControl>
							</Flex>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={50}
											required
											autoFocus
											value={values.name.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Name Gu */}
								<FormControl flex={5}>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={50}
										required
										value={values.name.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
