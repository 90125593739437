import { ProductCategory } from "src/domain/entities/productCategory"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	parentCategoryId?: string
	update: {
		parentCategoryId?: string
		name?: MultiLangText
		imageId?: string
	}
}

export async function productCategoryUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/productCategory/update/v1"
	const requester = new ApiRequester<RequestDataShape, ProductCategory>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
