import {
	Box,
	Button,
	Flex,
	FormControl,
	Image,
	Input,
	Stack,
	Tag,
	Text,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { BsImage } from "react-icons/bs"
import ReactSelect from "react-select"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SelectOption } from "src/components/shared/ReactSelect"
import { File } from "src/domain/entities/file"
import { ProductCategory } from "src/domain/entities/productCategory"
import { IProductCategoryAddFormFields } from "."
import { fetchGujaratiSuggestions } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	handleSubmit: FormikOnSubmit<IProductCategoryAddFormFields>
	parentProductCategoryList: ProductCategory[]
	initialValues?: IProductCategoryAddFormFields
	handleUploadImage: (file: any) => Promise<File>
	selectedImage?: File
	setSelectedImage: (file?: File) => void
}

export const ProductCategoryAddDrawerFormView: FC<Props> = ({
	handleSubmit,
	parentProductCategoryList,
	initialValues,
	handleUploadImage,
	selectedImage,
	setSelectedImage,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	const [featuredImageId, setFeaturedImageId] = useState<string>("")

	const [isFeaturedImageUploading, setIsFeaturedImageUploading] =
		useState<boolean>(false)

	return (
		<Formik<IProductCategoryAddFormFields>
			initialValues={{
				name: {
					en: "",
					gu: "",
				},
				parentCategoryId: "",
				featuredImageId,
				...initialValues,
			}}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleFeaturedImageSelected = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					if (!e.target.files?.[0]) return
					setIsFeaturedImageUploading(true)
					const res = await handleUploadImage(e.target.files?.[0])
					setFieldValue("featuredImageId", res.id)
					setFeaturedImageId(res.id)
					setIsFeaturedImageUploading(false)
				}

				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const parentProductCategoryOptions: {
					label: string
					value: string | null
				}[] = parentProductCategoryList.map((parentProductCategory) => ({
					value: parentProductCategory.id,
					label:
						parentProductCategory.name.en +
						` (${parentProductCategory.name.gu})`,
				}))

				parentProductCategoryOptions.unshift({
					value: null,
					label: "None",
				})

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Product Category"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								{/* ParentProductCategory */}
								<FormControl flex={2}>
									<InputLabel label="Parent Product Category" />
									<ReactSelect
										name="parentCategoryId"
										onChange={(newValue) => {
											setFieldValue(
												"parentCategoryId",
												(newValue as SelectOption).value,
											)
										}}
										value={parentProductCategoryOptions.find(
											(el) => el.value === values.parentCategoryId,
										)}
										options={parentProductCategoryOptions}
										isSearchable
									/>
								</FormControl>
							</Flex>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={50}
											required
											autoFocus
											value={values.name.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Name Gu */}
								<FormControl flex={5}>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={50}
										required
										value={values.name.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
							{/* Featured Image */}
							<FormControl flex={2}>
								<InputLabel label="Featured Image" />
								<Box
									position={"relative"}
									backgroundColor={"gray.100"}
									color={"gray.700"}
									rounded={"lg"}
								>
									{selectedImage ? (
										<Flex
											p={4}
											align={"center"}
											justify={"space-between"}
										>
											<Flex flex={1} align={"center"}>
												<Image
													src={selectedImage?.variants?.[0]}
													alt="Image preview"
													width="30%"
													objectFit="cover"
													rounded="lg"
												/>
												<Text ml={4}>Image Selected!</Text>
											</Flex>
											<Button
												variant="outline"
												colorScheme="red"
												onClick={(e) => {
													e.stopPropagation()
													setSelectedImage(undefined)
												}}
											>
												Remove
											</Button>
										</Flex>
									) : (
										<>
											<Flex p={8}>
												{isFeaturedImageUploading ? (
													<Box mx={"auto"}>
														<CenteredSpinner />
													</Box>
												) : (
													<>
														<BsImage size={24} />
														<Text ml={2}>
															Pick an Image here...
														</Text>
													</>
												)}
											</Flex>
											<Input
												name="imageFile"
												type="file"
												height="100%"
												width="100%"
												position="absolute"
												top="0"
												left="0"
												opacity="0"
												aria-hidden="true"
												accept="image/*"
												onChange={handleFeaturedImageSelected}
												cursor="pointer"
											/>
										</>
									)}
								</Box>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
