import { MobileAppBuildStatus } from "src/utils/enums"
import { AndroidBuild } from "../../entities/androidBuild"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		buildNo?: number
		version?: string
		publishDate?: number
		status?: MobileAppBuildStatus
	}
}

export async function androidBuildUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/android/build/update/v1"
	const requester = new ApiRequester<RequestDataShape, AndroidBuild>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
