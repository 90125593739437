import { FC } from "react"
import { Unit } from "src/domain/entities/unit"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { UnitListView } from "./UnitListView"

interface Props {
	list: Unit[]
	isLoading: boolean
	onUpdate: (unit: Unit) => void
	onDelete: (unit: Unit) => void
	onAddClick: () => void
}

export const UnitListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Units found." onAddClick={onAddClick} />
	}

	return <UnitListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
