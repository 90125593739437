import { useCallback, useState } from "react"
import { businessListApi } from "../api/business/businessListApi"
import { Business } from "../entities/business"
import { useAuth } from "./useAuth"

export function useBusinessListApi(): {
	businessList: Business[]
	isLoading: boolean
	fetchBusinessList: (filter?: Parameters<typeof businessListApi>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [businessList, setBusinessList] = useState<Business[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchBusinessList = useCallback(
		async (filter: Parameters<typeof businessListApi>[0] = {}) => {
			setIsLoading(true)
			const data = await businessListApi(filter, token)
			setBusinessList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { businessList, isLoading, fetchBusinessList }
}
