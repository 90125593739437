import { MatrimonyProfile } from "src/domain/entities/matrimonyProfile"
import {
	AnnualIncomeClass,
	BloodGroup,
	Gender,
	MaritalStatus,
	MatrimonyProfilePropertyType,
	OccupationType,
} from "src/utils/enums"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	self?: boolean
	userId?: string
	profileId?: string
	isVerified?: boolean
	educationCategoryIds?: string[]
	occupationCategoryIds?: string[]
	subCasteId?: string
	occupationType?: OccupationType
	maritalStatus?: MaritalStatus[]
	gender?: Gender
	currentlySelectedMatrimonyProfileId?: string
	isShortlisted?: boolean
	hasSentInterest?: boolean
	hasReceivedInterest?: boolean
	propertyType?: MatrimonyProfilePropertyType[]
	incomeClass?: AnnualIncomeClass[]
	bloodGroup?: BloodGroup[]
	nativeGeographicalLocations?: {
		districtIds?: string[]
		talukaIds?: string[]
		villageIds?: string[]
	}
	currentGeographicalLocations?: {
		countryIds?: string[]
		stateIds?: string[]
		districtIds?: string[]
		talukaIds?: string[]
		villageIds?: string[]
	}
	range?: {
		age?: {
			min: number
			max: number
		}
		height?: {
			min: number
			max: number
		}
		weight?: {
			min: number
			max: number
		}
	}
	fetch?: {
		profile?: {
			nativeVillage?: {
				taluka?: boolean
				district?: boolean
				state?: boolean
				country?: boolean
			}
			currentVillage?: {
				taluka?: boolean
				district?: boolean
				state?: boolean
				country?: boolean
			}
			surname?: {
				subCaste?: boolean
			}
			educations?: {
				educationCategory?: boolean
			}
			occupations?: {
				occupationCategory?: boolean
			}
			mobile?: boolean
		}
		featuredImage?: boolean
		allImages?: boolean
		identityProofImage?: boolean
		maintainers?: boolean
		property?: boolean
	}
	sortingFields?: {
		firstName?: {
			orderBy: "ASC" | "DESC"
		}
		age?: {
			orderBy: "ASC" | "DESC"
		}
		height?: {
			orderBy: "ASC" | "DESC"
		}
		weight?: {
			orderBy: "ASC" | "DESC"
		}
	}

	pagination?: RequestPagination
}

export async function matrimonyProfileListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/matrimony/profile/list/v1"
	const requester = new ApiRequester<RequestDataShape, MatrimonyProfile[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
